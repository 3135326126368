import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State, get_notification_list } from '../../../app/rootReducer';
import { AppService } from '../../../app/service/app.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css', '../ai-chat/ai-chat.component.css']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  readonly subs = new Subscription();
  notifications = [];

  constructor(public appService: AppService, private store: Store<State>) { }

  ngOnInit() {
    this.sub_store();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private sub_store() {
    this.subs.add(this.store.select(get_notification_list).subscribe(res => {
      if (res && res.length > 0) {
        this.notifications = res;
      } else {
        this.notifications = [
          {
            id: "",
            ts: "",
            read: false,
            expand: false,
            title: "Online Class",
            content: "IV D - Maths,Kannada,English & EVS(I) - 10:35 am:",
            sender: "BTLCOL", date: new Date(),
            links: [
              {
                href: "https://meet.google.com/ppr-ovkh-kxe",
                text: "https://meet.google.com/ppr-ovkh-kxe"
              }],
            from: "",
            expiry: null,
            status: "",
            type: ""
          },
          {
            id: "",
            ts: "",
            expand: false,
            read: false, title: "Holiday Notice", content: "Classes will be called off on Oct 2 on account of Mahatma Gandhi Jayanti", sender: "BTLCOL", date: new Date(), links: [],
            from: "",
            expiry: null,
            status: "",
            type: ""
          },
          {
            id: "",
            ts: "",
            expand: false,
            read: false, title: "Update Reminder", content: "Greetings from CampusTrack. A new update is available!", sender: "CT", date: new Date(), links: [],
            from: "",
            expiry: null,
            status: "",
            type: ""
          },
          {
            id: "",
            expand: false,
            ts: "",
            read: false, title: "Sept Programme Sheet", content: "Download the September Programme Sheet for KG I", sender: "BTLCOL", date: new Date(), links: [{ href: "", text: "Sept Programme Sheet" }],
            from: "",
            expiry: null,
            status: "",
            type: ""
          },
          {
            id: "",
            expand: false,
            ts: "",
            read: false,
            title: "Topic subscription",
            content: "Subscribe to get notes",
            sender: "BTLCOL",
            date: new Date(),
            links: [],
            from: "@anitha",
            expiry: null,
            status: "Active",
            type: "invitation"
          },
          {
            id: "",
            expand: false,
            ts: "",
            read: false,
            title: "Independance day celebrations",
            content: "Independance day celebrations",
            sender: "BTLCOL",
            date: new Date(),
            links: [],
            from: "@principal",
            expiry: new Date(Date.parse("05-08-2021")),
            status: "Active",
            type: ""
          }
        ];
      }
    }));
  }
}
