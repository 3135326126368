import { Component, OnInit } from '@angular/core';
import { AppService } from '../../service/app.service';
import { PulseService } from '../../service/pulse';

@Component({
  selector: 'app-manual-triggers',
  templateUrl: './manual-triggers.component.html',
  styleUrls: ['./manual-triggers.component.css']
})
export class ManualTriggersComponent implements OnInit {
  message = 'Just a moment...';

  constructor(public appService: AppService, private pulseService: PulseService) { }

  ngOnInit() {
    console.log(this.appService);
    // currentPersona
    setTimeout(() => {
      this.init();
    }, 3000);
  }
  private init() {
    const { title }: any = this.appService.currentPersona;
    if (!title && title !== 'Support') {
      this.message = 'You are not authorized to view this page. Only Support can access this page.';
      return;
    }
    this.message = '';

    this.pulseService.manualTriggerJob({ operation: 'get_all_manual_triggers', data: {} });
  }
  ut_onRun(mt) {
    this.pulseService.manualTriggerJob({ operation: 'run_manual_trigger', data: mt.payload });
  }
}
