import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VxService {
  tree: any = { title: "", nodes: [] };
  editTitle: boolean = false;
  showTitleErr: boolean = false;
  isSidebarOpen: boolean = false;
  vxActionsEventEmitter = new EventEmitter();
  titleCopy: string = "";
  canEdit: boolean = true;
  navItems: any = null;
  showSave: boolean = true;
  eventsSubject: Subject<void> = new Subject<void>();
  selectedNode: any = null;

  constructor() { }

  ut_editTitle() {
    this.vxActionsEventEmitter.emit({ action: 'ut_editTitle' });
  }
  ut_showTreeSummary() {
    this.vxActionsEventEmitter.emit({ action: 'ut_showTreeSummary' });
  }
  ut_updateTitle() {
    this.vxActionsEventEmitter.emit({ action: 'ut_updateTitle' });
  }
  ut_discardTitleEdit() {
    this.vxActionsEventEmitter.emit({ action: 'ut_discardTitleEdit' });
  }
  ut_selectedNode(e) {
    this.closeAllItemNameEditors();
    e.selected = true;
    this.vxActionsEventEmitter.emit({ action: 'ut_selectedNode', e });
  }
  ut_deletedNode(e) {
    this.vxActionsEventEmitter.emit({ action: 'ut_deletedNode', e });
  }
  ut_nodeEdit(e) {
    this.vxActionsEventEmitter.emit({ action: 'ut_nodeEdit', e });
  }
  ut_addNode() {
    this.vxActionsEventEmitter.emit({ action: 'ut_addNode' });
  }
  ut_update(e) {
    this.vxActionsEventEmitter.emit({ action: 'ut_update', e });
  }
  ut_addTopic(e) {
    this.vxActionsEventEmitter.emit({ action: 'ut_addTopic', e });
  }
  ut_appendParentNode() {
    this.tree.nodes.push({ itemId: +new Date(), name: "New Node", expand: false, children: [], isLeaf: false, edit: true });
    this.reInitTreeData();
  }
  closeAllItemNameEditors() {
    this.closeItemNameEditors(this.tree.nodes);
  }
  findParentNodeByItemId(children, parentItemId, itemId) {
    let parentNode = null;
    for (let index = 0; index < children.length; index++) {
      const node = children[index];
      if (node.itemId == itemId) {
        parentNode = children;
        break;
      } else if (node.expand && node.children && node.children.length > 0) {
        parentNode = this.findParentNodeByItemId(node.children, node.itemId, itemId);
        if (parentNode) {
          break;
        }
      }
    }
    return parentNode;
  }
  selectPreviousNode() {
    if (this.selectedNode) {
      const itemId = this.selectedNode.itemId;
      let previousNode = this.findPreviousNodeByItemId(this.tree.nodes, this.tree.itemId, itemId);
      if (previousNode) {
        this.ut_selectedNode(previousNode);
      } else {
      }
    } else {
      // if null, select the first node
      this.selectedNode = this.tree.nodes[0];
      this.ut_selectedNode(this.selectedNode);
    }
  }

  private findPreviousNodeByItemId(children, parentItemId, itemId) {
    let previousNode = null;
    for (let index = 0; index < children.length; index++) {
      const node = children[index];
      if (node.itemId == itemId) {
        const currentNode = node;
        if (index > 0) {
          previousNode = children[index - 1];
        } else {
          // select the parent node
          const parentNode = this.getNodeById(this.tree.nodes, parentItemId);
          if (parentNode) {
            previousNode = parentNode;
          }
        }
        break;
      }
      if (!previousNode) {
        if (node.expand && node.children && node.children.length > 0) {
          previousNode = this.findPreviousNodeByItemId(node.children, node.itemId, itemId);
          if (previousNode) {
            break;
          }
        }
      }
    }
    return previousNode;
  }

  private getNodeById(children, itemId) {
    let node = null;
    for (let index = 0; index < children.length; index++) {
      const item = children[index];
      if (item.itemId == itemId) {
        node = item;
        break;
      }
      if (item.children && item.children.length > 0) {
        node = this.getNodeById(item.children, itemId);
        if (node) {
          break;
        }
      }
    }
    return node;
  }
  selectNextNode() {
    if (this.selectedNode) {
      const itemId = this.selectedNode.itemId;
      let nextNode = this.findNextNodeByItemId(this.tree.nodes, this.tree.itemId, itemId);
      if (nextNode) {
        this.ut_selectedNode(nextNode);
      } else {
      }
    } else {
      // if null, select the first node
      this.selectedNode = this.tree.nodes[0];
      this.ut_selectedNode(this.selectedNode);
    }
  }
  private findNextNodeByItemId(children, parentItemId, itemId) {
    let nextNode = null;
    for (let index = 0; index < children.length; index++) {
      const node = children[index];
      if (node.itemId == itemId) {
        const currentNode = node;
        // check if the current node is expanded
        if (node.expand && node.children && node.children.length > 0) {
          nextNode = node.children[0];
        } else if (index < children.length - 1) {
          // check if the current node is the last node
          nextNode = children[index + 1];
        } else {
          // last node, then select the next node of the parent
          const parentParentItemId = this.getParentNodeIdByChildId(parentItemId);
          const nextParentNode = this.getNextSiblingNode(parentParentItemId);
          nextNode = nextParentNode;
          if (nextNode) {
            break;
          }
        }
        break;
      }
      // if (!nextNode) {
      if (node.expand && node.children && node.children.length > 0) {
        nextNode = this.findNextNodeByItemId(node.children, node.itemId, itemId);
        if (nextNode) {
          break;
        }
      }
    }
    return nextNode;
  }
  private getParentNodeIdByChildId(itemId) {
    return this.findParentNodeIdByChildId(this.tree.nodes, this.tree.itemId, itemId);
  }
  private getNextSiblingNode(itemId) {
    return this.findNextSiblingNode(this.tree.nodes, itemId);
  }
  private findNextSiblingNode(children, itemId) {
    let nextSiblingNode = null;
    for (let index = 0; index < children.length; index++) {
      const node = children[index];
      if (node.itemId == itemId) {
        if (index < children.length - 1) {
          nextSiblingNode = children[index + 1];
        }
        break;
      }
      if (node.expand && node.children && node.children.length > 0) {
        nextSiblingNode = this.findNextSiblingNode(node.children, itemId);
        if (nextSiblingNode) {
          break;
        }
      }
    }
    return nextSiblingNode;
  }

  private findParentNodeIdByChildId(children, parentItemId, itemId) {
    let parentNodeId = null;
    for (let index = 0; index < children.length; index++) {
      const node = children[index];
      if (node.itemId == itemId) {
        parentNodeId = parentItemId;
        break;
      } else if (node.expand && node.children && node.children.length > 0) {
        parentNodeId = this.findParentNodeIdByChildId(node.children, node.itemId, itemId);
        if (parentNodeId) {
          break;
        }
      }
    }
    return parentNodeId;
  }
  collapseCurrentNode() {
    if (this.selectedNode) {
      if (!this.selectedNode.isLeaf) {
        this.selectedNode.expand = false
      }
    }
  }
  expandCurrentNode() {
    if (this.selectedNode) {
      if (!this.selectedNode.isLeaf) {
        this.selectedNode.expand = true
      }
    }
  }
  private closeItemNameEditors(nodes) {
    nodes.forEach(node => {
      if (node.edit) {
        node.edit = false;
      }
      node.type = ''
      node.selected = false;
      if (node.children && node.children.length > 0) {
        this.closeItemNameEditors(node.children);
      }
    });
  }
  reInitTreeData() {
    this.setTreeData(this.tree);
  }
  setTreeData(tree) {
    tree.nodes = this.getUpdatedChildNodes(tree.nodes);
    this.tree = tree;
  }
  getUpdatedChildNodes(nodes) {
    if (!nodes) return [];
    nodes.forEach(node => {
      node.label = node.name;
      if (node.isLeaf) {
        node.icon = 'pi pi-file';
      } else {
        node.expandedIcon = "pi pi-folder-open";
        node.collapsedIcon = "pi pi-folder";
      }
      if (node.children && node.children.length > 0) {
        node.children = this.getUpdatedChildNodes(node.children);
      }
    });
    return nodes;
  }
  renameNodeById(itemId, name) {
    this.renameNode(this.tree.nodes, itemId, name);
  }
  private renameNode(nodes, itemId, name) {
    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index];
      if (node.itemId == itemId) {
        node.name = name;
        node.label = name;
        break;
      } else if (node.children && node.children.length > 0) {
        this.renameNode(node.children, itemId, name);
      }
      if (!node.children) {
        node.children = [];
      }
    }
  }
}
