import { Component, Input, OnInit } from '@angular/core';
import { CTApi } from '../../service/ct-api';
import { PdfViewerService } from './pdf-viewer.service';

declare const pdfjsLib: any;

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfId: string = '';
  showActions: boolean = false;
  searchKey: string = ''

  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private pdfDoc: any;
  pageNum: number = 1;
  pageCount: number = 1;
  scale: number = 1;
  zoomPercentage: number = this.scale * 100;
  isPreviousAvailable: boolean = false;
  isNextAvailable: boolean = false;
  readonly maxScale: number = 2;
  readonly minScale: number = 0.5;
  viewport: any;

  constructor(private ctApi: CTApi, private pdfviewerService: PdfViewerService) { }

  ngOnInit() {
    this.init();
  }
  private init() {
    this.canvas = document.getElementById('pdf-canvas') as HTMLCanvasElement;
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    this.fetchPdf();
  }
  private fetchPdf() {
    // this.pdfId = '4ff83976-3d09-44d9-b79d-6b58d8d568e1';
    if (this.pdfId) {
      if (this.pdfviewerService.pdfIds.hasOwnProperty(this.pdfId)) {
        this.loadPDFUsingArrayBuffer(this.pdfviewerService.pdfIds[this.pdfId]);
        return;
      }
      this.ctApi.getPdfFileContent(this.pdfId).subscribe({
        next: (arrayBuffer) => {
          this.loadPDFUsingArrayBuffer(arrayBuffer);
          this.pdfviewerService.pdfIds[this.pdfId] = arrayBuffer;
        },
        error: (error) => { console.log('error', error); }
      });
    }
  }
  ut_clickableArea(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
  ut_previousPage() {
    this.changePage(-1);
  }
  ut_nextPage() {
    this.changePage(1);
  }
  ut_search() {
    this.search();
  }
  ut_zoomFit() {
    this.zoom(-3);
  }
  ut_zoomIn() {
    this.zoom(0.1);
  }
  ut_zoomOut() {
    this.zoom(-0.1);
  }
  private async loadPDFUsingArrayBuffer(arrayBuffer: ArrayBuffer): Promise<void> {
    this.pdfDoc = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
    this.pageCount = this.pdfDoc.numPages;
    this.showActions = true;
    await this.renderPage();
  }

  private async renderPage(): Promise<void> {
    if (this.pageCount > 1) {
      this.isPreviousAvailable = this.pageNum > 1;
      this.isNextAvailable = this.pageNum < this.pageCount;
    } else {
      this.isPreviousAvailable = false;
      this.isNextAvailable = false;
    }
    const page = await this.pdfDoc.getPage(this.pageNum);
    const viewport = page.getViewport({ scale: this.scale });
    this.viewport = viewport;
    this.canvas.height = viewport.height;
    this.canvas.width = viewport.width;

    const renderContext = {
      canvasContext: this.ctx,
      viewport: viewport
    };

    await page.render(renderContext);
  }

  // Function to highlight text
  highlightText(textContent) {
    var textItems = textContent.items;
    textItems.forEach((textItem) => {
      if (textItem.str.toLowerCase().includes(this.searchKey)) {
        let tx = textItem.transform[4];
        let ty = textItem.transform[5];

        // Convert PDF coordinates to canvas coordinates
        let [x, y] = this.viewport.convertToViewportPoint(tx, ty);
        // const [x, y] = viewport.convertToViewportPoint(textItem.transform[4], textItem.transform[5]);

        // Adjust y-coordinate (PDF coordinates are from bottom-left, canvas is top-left)
        // y = viewport.height - y;

        // Draw highlight rectangle
        this.ctx.fillStyle = 'rgba(255, 255, 0, 0.5)';  // Semi-transparent yellow
        this.ctx.fillRect(x, y - textItem.height, textItem.width, textItem.height);
        // this.ctx.fillRect(x, viewport.height - y, textItem.width * 1.5, textItem.height * 1.5);
      }
    });
  }

  private async changePage(offset: number): Promise<void> {
    this.pageNum += offset;
    if (this.pageNum < 1) this.pageNum = 1;
    if (this.pageNum > this.pageCount) this.pageNum = this.pageCount;
    await this.renderPage();
  }

  private async goToPage(): Promise<void> {
    const pageInput = document.getElementById('page-input') as HTMLInputElement;
    const pageNum = parseInt(pageInput.value);
    if (pageNum >= 1 && pageNum <= this.pageCount) {
      this.pageNum = pageNum;
      await this.renderPage();
    }
  }

  private async search(): Promise<void> {
    this.searchKey = this.searchKey.trim().toLocaleLowerCase();
    if (this.searchKey) {
      for (let i = 1; i <= this.pageCount; i++) {
        const page = await this.pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        const text = textContent.items.map((item: any) => item.str).join(' ');
        if (text.toLowerCase().includes(this.searchKey)) {
          this.pageNum = i;
          await this.renderPage();
          setTimeout(() => {
            this.highlightText(textContent);
          }, 500);
          break;
        }
      }
    }
  }

  private async zoom(scaleDelta: number): Promise<void> {
    this.scale += scaleDelta;
    if (this.scale < this.minScale) this.scale = 0.5;
    if (this.scale > this.maxScale) this.scale = 2;
    this.scale = parseFloat(this.scale.toFixed(1));;
    this.zoomPercentage = this.scale * 100;
    await this.renderPage();
  }

}
