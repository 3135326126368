import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CTApi } from './ct-api';
import { IN_PAGE_SIDEBAR_DATA, MENU_TYPE } from './constants';
import { ActivatedRoute, Router } from '@angular/router';
import { JsonObject } from './app.interfaces';
import { Store } from '@ngrx/store';
import { State } from '../rootReducer';
import { SetIsSidebarOpen, SetNBDisplay, SetSidebarComponentInfo } from '../store/app-action';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private timers = {};
  isLoggedIn: boolean = false;
  public showDexResults: boolean = false;
  // to use in other components; to reduce subscriptions
  public currentPersona = {};
  public dryRunStats: Array<any> = [];
  public publisherEventEmitter = new EventEmitter();
  public publisherPayload: Array<any> = [];
  public inPageSidebarOpen: boolean = false;
  public inPageSidebarKey: string = 'ScheduledPublishes';
  public isAccordionOpen = false;
  public inPageSidebarData = IN_PAGE_SIDEBAR_DATA[this.inPageSidebarKey];
  isSidebarOpen: boolean = false;
  sidebarComponentInfo: JsonObject = {};
  currentNBDisplay = MENU_TYPE.CLASSIC;
  isLoading: boolean = false;
  manualTriggersList: Array<any> =[];

  constructor(private ctApi: CTApi, private router: Router, private activatedRoute: ActivatedRoute, private store: Store<State>) { }

  /**
   * Logger for Debugging in Development Mode
   * @param message 
   * @param optionalParams 
   */
  dLog(message?: any, ...optionalParams: any[]): void {
    if (!environment.production) {
      console.log(message, optionalParams);
    }
  }

  /**
   * 
   * @param key to identify the timer and to prevent clearing other timers
   * @param callback 
   * @param delay default 3000
   */
  debouncerWithKey(key, callback, delay = 3000) {
    clearTimeout(this.timers[key]);
    if (callback) {
      this.timers[key] = setTimeout(() => { callback() }, delay);
    }
  }

  getUserIntros() {
    return this.ctApi.getUserIntros();
  }
  updateUserIntros(intros: { Id: string, Intros: { [a: string]: boolean } }) {
    return this.ctApi.updateUserIntros(intros);
  }
  ut_toggleInPageSidebar() {
    this.inPageSidebarOpen = !this.inPageSidebarOpen;
  }
  ut_toggleAiChat() {
    const component = 'ai-chat';
    this.toggleSidebar(component);
  }
  ut_cycleSidebar() {
    const CycleFlow = [
      { component: 'ai-chat' }, { component: 'dex-tool', context: 'applications', route: '/dex' },
      { component: 'notifications' }, { component: 'publisher-tool' }, { component: 'logs' }];

    if (this.sidebarComponentInfo.context) {
      CycleFlow[1].context = this.sidebarComponentInfo.context;
    }

    if (this.isSidebarOpen) {
      const currentComponentIndex = CycleFlow.findIndex(c => c.component === this.sidebarComponentInfo.component);
      const nextIndex = currentComponentIndex + 1;
      if (nextIndex < CycleFlow.length) {
        if (CycleFlow[nextIndex].route) {
          this.router.navigate([CycleFlow[nextIndex].route]);
        }
        this.changeSidebarComponent(true, CycleFlow[nextIndex].component, CycleFlow[nextIndex].context || '');
      } else {
        this.ut_closeSidebar();
      }
    } else {
      this.changeSidebarComponent(true, 'ai-chat');
    }
  }
  ut_closeSidebar() {
    this.store.dispatch(new SetIsSidebarOpen(false));
  }
  ut_openDexTool(context) {
    this.router.navigate(['/dex']);
    this.changeSidebarComponent(true, 'dex-tool', context);
  }
  ut_toggleDexTool() {
    if (this.sidebarComponentInfo.component === 'dex-tool' && this.isSidebarOpen) {
      this.ut_closeSidebar();
    } else {
      this.router.navigate(['/dex']);
      const context = this.sidebarComponentInfo.context || 'applications'
      this.changeSidebarComponent(true, 'dex-tool', context);
    }
  }
  ut_openPublisherTool() {
    this.changeSidebarComponent(true, 'publisher-tool');
  }
  ut_togglePublisherTool() {
    const component = 'publisher-tool'
    this.toggleSidebar(component);
  }
  ut_toggleLogs() {
    const component = 'logs'
    this.toggleSidebar(component);
  }
  ut_toggleNotifications() {
    const component = 'notifications'
    this.toggleSidebar(component);
  }
  private toggleSidebar(component: string) {
    let isSidebarOpen = !this.isSidebarOpen;
    if (this.sidebarComponentInfo.component === component) {
    } else {
      isSidebarOpen = true;
    }
    this.store.dispatch(new SetIsSidebarOpen(isSidebarOpen));
    this.store.dispatch(new SetSidebarComponentInfo({ component }));
  }
  changeSidebarComponent(isOpen: boolean, component: string = '', context: string = '') {
    this.store.dispatch(new SetIsSidebarOpen(isOpen));
    this.store.dispatch(new SetSidebarComponentInfo({ component, context }));
  }

  ut_toggleMenus() {
    const cycleFlow = ["standard", "classic", "none"];
    const menuType = this.currentNBDisplay.type;
    switch (menuType) {
      case "standard":
        this.store.dispatch(new SetNBDisplay(MENU_TYPE.CLASSIC));
        break;
      case "classic":
        this.store.dispatch(new SetNBDisplay(MENU_TYPE.NONE));
        break;
      case "none":
        this.store.dispatch(new SetNBDisplay(MENU_TYPE.STANDARD));
        break;
      default:
        break;
    }
  }
}
