import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State, get_notifications } from '../../../app/rootReducer';
import { AppService } from '../../../app/service/app.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css', '../ai-chat/ai-chat.component.css']
})
export class LogsComponent implements OnInit, OnDestroy {
  readonly subs = new Subscription();
  notifications = [];

  constructor(public appService: AppService, private store: Store<State>) { }

  ngOnInit() {
    this.sub_store();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private sub_store() {
    this.subs.add(this.store.select(get_notifications).subscribe((res) => {
      this.notifications = res;
    }));
  }
}
