import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { CTApi } from '../app/service/ct-api';
import { Store } from '@ngrx/store';
import * as rootReducer from '../app/rootReducer';
import * as appAction from './store/app-action';
import * as navTabsAction from './navTabs/store/navTabs-action';
import { SetUserIntros } from './store/intro-action';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/Rx';
import { NgxSpinnerService } from 'ngx-spinner';
import { PulseService } from '../app/service/pulse';
import * as _ from 'underscore';
import { AppService } from './service/app.service';
import { IntroJsService } from './service/intro-js.service';
import { ALL_INTROS, MENU_TYPE } from './service/constants';
import { AppStatus } from './service/app.interfaces';
import { DEX_ENABLED_PATHS } from './dex/services/constant';
import { VxService } from './components/vx/vx.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  onBeforeunload(event: Event) {
    this.clearBeforeUnload();
  }
  // Add only the global key events here
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // Ctrl + I: To toggle AI chat
    if (event.ctrlKey) {
      if (event.code === 'KeyI') {
        this.appService.ut_cycleSidebar();
      } else if (event.code === 'KeyM') {
        this.appService.ut_toggleMenus();
      }
    }
  }

  akshadhaa: boolean = false;
  deleteAccountPage: boolean = false;
  mobilePage: boolean = false;
  landingPage: boolean = false;
  svcLandingPage: boolean = false;
  learners: boolean = false;
  svcLearners: boolean = false;
  institutes: boolean = false;
  svcInstitutes: boolean = false;

  token: string;
  userId: string;
  userEmail: string;
  clientId: string;
  currentPersona: any;
  currentPersonaTitle: string;
  public jsonRes: any;
  private readonly sub: Subscription = new Subscription();
  private sub_user_status: Subscription;
  private appStatus: AppStatus = { isInitilized: false, isLoggedIn: false };
  public mobile: boolean = false;
  public selectedNBDisplay: any;
  classicMode: boolean = false;
  navPerms: any[] = [];
  userLinks: any;
  navTreeKey: string = "children";
  canEdit: boolean = false;

  constructor(public ctapi: CTApi, private store: Store<rootReducer.State>, private spinner: NgxSpinnerService,
    private router: Router, public pulseService: PulseService, public appService: AppService,
    private introJsService: IntroJsService, private vxService: VxService) {
    this.appService.isLoading = true;
    this.fetchJsonFile('.');
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.spinner.show();
      }
      if (val instanceof NavigationEnd) {
        this.pathname = window.location.pathname;
        // this.hostName = window.location.hostname;
        this.spinner.hide();
      }
    });
  }

  private fetchJsonFile(path) {
    path = path + '/environment.json';
    fetch(path).then((response) => {
      if (!response.ok) {
        console.log("Error", response.statusText, response.status);
        console.log({ response });
      }
      return response.json();
    }).then(json => {
      try {
        if (json) {
          for (const key in json) {
            if (Object.prototype.hasOwnProperty.call(json, key)) {
              const value = json[key];
              environment[key] = value;
            }
          }
        }
        // this.testResult += `status: ${response.status}, statusText: ${response.statusText}, path:${path}\n`
      } catch (error) {
        console.log({ error });
      }
    }).catch(error => {
      console.log({ error });
    });
  }
  public pathname = window.location.pathname;
  hostName = window.location.hostname;
  ngOnInit() {
    this.appService.isLoading = false;
    this.init_store();
    this.sub_store();
    this.router.events.subscribe((e) => {
      if ((window.screen.width < 500) && this.router.url == '/mobilePrivacyPolicy') { this.mobile = true; } else { this.mobile = false; }
      this.appService.debouncerWithKey("dex-view", () => {
        this.appService.showDexResults = false;
        if (this.router.url.includes('/dex')) {
          this.appService.showDexResults = true;
        }
      }, 1000);
    })
    this.deleteAccountPage = this.pathname == '/deleteaccount' ? true : false;
    this.mobilePage = this.pathname == '/mobile' ? true : false;
    this.landingPage = this.pathname == '/landingpage' ? true : false;
    this.svcLandingPage = this.pathname == '/ctng4/landingpage' ? true : false;
    this.learners = this.pathname == '/learners' ? true : false;
    this.svcLearners = this.pathname == '/ctng4/learners' ? true : false;
    this.institutes = this.pathname == '/institutes' ? true : false;
    this.svcInstitutes = this.pathname == '/ctng4/institutes' ? true : false;
    this.akshadhaa = this.hostName == 'akshadhaa.campustrack.net' ? true : false;
  }

  ngOnDestroy() {
    this.sub_user_status.unsubscribe();
  }
  private clearBeforeUnload() {
    // to clear local storage on refresh
    localStorage.clear();
    // Dispatch the action that you want to be executed before the refresh.
    this.store.dispatch(new appAction.UserChannelJoined({ userChannelJoined: false }));
    this.store.dispatch(new appAction.UpdateAppStatus({ isInitilized: false, isLoggedIn: false }));
  }
  init_store() { }
  sub_store() {
    this.store.select(rootReducer.get_app_status).subscribe(appStatus => {
      this.appStatus = appStatus;
      if (appStatus.isInitilized == false && appStatus.isLoggedIn == true) {
        // important: To execute the codes only once after the user is logged in/refresh
        this.getUserIntros();
        this.store.dispatch(new appAction.UpdateAppStatus({ isInitilized: true, isLoggedIn: true }));
      }
    });
    this.store.select(rootReducer.get_user_links).subscribe(res => {
      if (res && res.userLinks) {
        this.userLinks = res.userLinks;
      }
    });
    this.store.select(rootReducer.get_selected_nb_display).subscribe(res => {
      if (res) {
        this.selectedNBDisplay = res;
        if (this.selectedNBDisplay.type == 'classic') { this.classicMode = true; }
        else { this.classicMode = false; }
      }
    });
    this.store.select(rootReducer.get_reqDate).subscribe(() => {
      this.spinner.show();
    });
    this.store.select(rootReducer.get_notifications).subscribe(() => {
      this.spinner.hide();
    });
    this.store.select(rootReducer.get_accessToken).subscribe(res => {
      this.token = res;
      if (this.token) {
        this.appService.isLoggedIn = true;
        this.store.dispatch(new appAction.UpdateAppStatus({ isInitilized: this.appStatus.isInitilized, isLoggedIn: true }));
      } else {
        this.appService.isLoggedIn = false
      }
    });
    this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
      if (res) {
        this.userId = res.userId;
        this.userEmail = res.userEmail;
        this.clientId = res.currentPersona.clientId;
        this.currentPersona = res.currentPersona;
        this.introJsService.userId = this.userId
        this.appService.currentPersona = res.currentPersona;

        setTimeout(() => {
          let navPerms = res.currentPersona.navPerms;
          this.currentPersonaTitle = res.currentPersona.title;
          if (this.currentPersonaTitle == 'Student') {
            navPerms = navPerms.filter(o => (o.Route == 'home' || o.Route == 'academics'));
          }
          let ctContext = res;
          for (var i = 0; i < navPerms.length; i++) {
            navPerms[i].selected = false;
          }
          this.store.dispatch(new navTabsAction.SetTab({ "tab": navPerms[0] }));
          // this.store.dispatch(new appAction.SetTab({ "tab": navPerms[0] }));
          // navPerms[0].selected = true;
          var that = this;
          this.navPerms = _.map(navPerms, function (nav, indx) {
            let userLinks = [];
            if (that.userLinks && that.userLinks[nav.DisplayText]) {
              if (that.userLinks[nav.DisplayText]['col1'].length > 0)
                userLinks = that.userLinks[nav.DisplayText]['col1'][0]['subGroup'];
            }
            return { name: nav.DisplayText, itemId: indx, isLeaf: false, route: nav.Route, children: userLinks || [], tabIcon: nav.TabIcon, endpoints: nav.Endpoints };
          });
          this.navPerms.forEach(nav => {
            if (nav.children && nav.children.length) {
              nav.children.forEach(child => {
                child.name = child.title;
                child.isLeaf = true;
                child.itemId = child.title;
              });
            }
          });
        }, 500);
      }
      if (this.userId && this.token && this.clientId) {
        this.appService.debouncerWithKey("pulse", () => {
          this.pulseService.setup_ws(this.token, this.userId, this.clientId, this.currentPersona, this.userEmail);
        }, 2000);
      }
    });
    this.sub.add(this.store.select(rootReducer.get_intros).subscribe(res => {
      if (res) {
        this.introJsService.initIntros(res);
      }
    }));
    this.sub.add(this.spinner.spinnerObservable.subscribe(res => {
      if (res && this.token) {
        this.appService.debouncerWithKey("intro", () => {
          if (this.token) { // to show only when logged in
            this.introJsService.startIntro(); // start intro here
          }
        });
      }
    }));
    this.sub.add(this.store.select(rootReducer.get_is_sidebar_open).subscribe((isOpen: boolean) => {
      this.appService.isSidebarOpen = !!isOpen;
      this.reInitLmsTool();
    }));
    let sidebarTimeOutId: any;
    this.sub.add(this.store.select(rootReducer.get_sidebar_component_info).subscribe(res => {
      if (sidebarTimeOutId) {
        clearTimeout(sidebarTimeOutId);
      }
      sidebarTimeOutId = setTimeout(() => {
        this.appService.sidebarComponentInfo = res;
        this.reInitLmsTool();
      }, 400);
    }));
  }
  setNBMode() {
    if (this.classicMode) {
      this.store.dispatch(new appAction.SetNBDisplay(MENU_TYPE.CLASSIC));
    } else {
      this.store.dispatch(new appAction.SetNBDisplay(MENU_TYPE.STANDARD));
    }
  }
  ut_getQuickLinks() {
    this.pulseService.getQuickLinksData();
  }
  private getUserIntros() {
    this.appService.getUserIntros().subscribe(res => {
      if (res && res['data']) {
        ALL_INTROS.forEach(intro => {
          if (!res['data'].hasOwnProperty(intro)) {
            res['data'][intro] = false;
          }
        });
        this.store.dispatch(new SetUserIntros(res['data']));
      }
    });
  }
  lmsToolTimeoutId: any;
  private reInitLmsTool() {
    const { pathname } = window.location;
    if (pathname == '/lms') {
      if (this.appService.isSidebarOpen) {
        // may be different component is open
      } else {
        this.appService.sidebarComponentInfo.component = 'lms-dashboard';
        this.appService.isSidebarOpen = true;
      }
    }
    if (this.lmsToolTimeoutId) {
      clearTimeout(this.lmsToolTimeoutId);
    }
    this.lmsToolTimeoutId = setTimeout(() => {
      if (this.appService.sidebarComponentInfo.component === 'lms-tool' && this.appService.isSidebarOpen) {
        this.vxService.isSidebarOpen = true;
      } else {
        this.vxService.isSidebarOpen = false;
      }
    }, 400);
  }
}
