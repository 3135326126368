import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, HostListener, TemplateRef, OnDestroy } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { AcademicsService as service } from '../../academics/service/service';
import * as _ from 'underscore';
import * as LC from 'literallycanvas';
import { FileUploader } from 'ng2-file-upload';
import 'brace/index';
import 'brace/theme/eclipse';
import 'brace/mode/markdown';
import 'brace/ext/language_tools';
import * as marked from 'marked';
import * as stopWords from '../../../../assets/dummy/stop-words.json';
import { KatexOptions, MarkdownService } from 'ngx-markdown';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as rootReducer from '../../rootReducer';
import { BsModalRef, BsModalService, PopoverDirective, TypeaheadMatch } from 'ngx-bootstrap';
import * as acadAction from '../../academics/store/action';
import { VaultService } from '../../service/vault.service';
import { MessageService } from 'primeng/api';
import { PulseService } from '../../service/pulse';

declare var $: any;

class Video {
  itemId: any;
  type: any;
  url: string = "";
  edit: false;
}

class Model {
  visibility: boolean = false;
  key: string = 'slide';
  value: any;
  recordLinks: Video[] = [];
  driveLinks: Video[] = [];
  streamLinks: Video[] = [];
  recordLink: string;
  driveLink: string;
  streamLink: string;
  addRecordLink: boolean = false;
  addDriveLink: boolean = false;
  addStreamLink: boolean = false;
  comments: any[] = [];
  selectedTopic: any;
  comment: string = "";
  fileId: string = "";
  tags: any[] = [];
  selectedTags: any
  options: {
    wrap: true,
    showLineNumbers: false
  };
  showSizeError: boolean = false;
  uploadedFile: any = {};
  vaultImages: any[] = [];
  // filterOptions: any = {
  //   "context": "QA",
  //   "filterItems": []
  // }
  filterOptionsCopy: any = {
    "context": "QA",
    "filterItems": []
  }
  selectedFilter: string = "";
  searchImgText: string = "";
  searchText: string = ""
  mathContent = ``;
  chemContent = ``;
  expressions = ['${a \\over b}$', '$\\sqrt[3]{x}$'];
  chemExpressions = ['$\\ce{H+}$', '$\\ce{H2O}$', '$\\ce{H2SO4}$', '$SO_4+\\downarrow 2H\\overset{H_2SO_4+2S_4}\\rightarrow{H_2+SO_4}\\uparrow$'];
  drawFileName: string = "";
  imageModalOpen: boolean = false;
  optionsModalOpen: boolean = false;
  initQryStr: string = '?$orderby=createdOn%20desc&$skip=0&$top=1024&$search=media:true,docs:true';
  imageHeight: number = 100;
  imageWidth: number = 250;
  selectedImageSize: string = "default";
  allTags: any;
  currentPersona: any;
  selectedImages: any[] = [];
  addTag: boolean = false;
  newTag: string = "";
  duplicate: boolean = false;
  editTagDuplicate: boolean = false;
  tagCopy: any;
  selectedTag: any;
  filterOptions: any = {
    "sections": [{
      "section": "Tags",
      "filterItems": []
    }]
  }
  table: string = `| Column-1 | Column-2 | Column-3 |
| -------- |:--------:| --------:|
| Row 1    | Row 1    | Row 1    |`;
  getColor(visibility) {
    switch (visibility) {
      case true:
        return '#5cb85c';
      case false:
        return 'white';
      default:
        return 'white';
    }
  };
  getTitle(visibility) {
    switch (visibility) {
      case true:
        return 'Section visible to student';
      case false:
        return 'Section not visible to student';
      default:
        return 'Section not visible to student';
    }
  };
  searchTextFormula: string = "";
  titleFormula: string = "";
  descriptionFormula: string = "";
  formulas: any[] = [];
  searchedResults: any[] = [];
}
const URL = '';

@Component({
  selector: 'vx-editor',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
  providers: [service],
})
export class VxEditorComponent implements OnInit, OnDestroy {
  typeAheadList: any = {
    values: [],
    positionMenu: true,
    autocompleteMode: true,
    noMatchTemplate: '',
    replaceTextSuffix: ','
  };

  userEmail: string;
  lc: any;
  qryStr: any;
  allSelectedImages: any[] = [];
  selectedRows: any[] = [];
  public options: KatexOptions = {
    displayMode: false,
    throwOnError: false,
    errorColor: '#cc0000',
  };
  ts: string = "1";
  toolbarSettings = {
    undo: false,
    redo: false,
    formula: false,
    table: false
  }

  constructor(private store: Store<rootReducer.State>, private el: ElementRef,
    public service: service, private markdownService: MarkdownService, private modalService: BsModalService,
    private vaultService: VaultService, private messageService: MessageService, private pulseService: PulseService) {
    this.uploader = new FileUploader({
      url: URL,
      disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: true,
      formatDataFunction: async (item) => {
        return new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      }
    });
  }
  modalRef: BsModalRef;
  @ViewChild('editor') editor;
  @ViewChild('pop') pop: PopoverDirective;
  @Input() key: string = ""; // component key
  @Input() q: string = "";
  @Input() question: any = {};
  @Input() indx: number = 0;
  @Input() ansIndx: number = 0;
  @Input() qIndx: number = 0;
  @Input() optionIndx: number = 0;
  @Input() isParentQ: boolean = false;
  @Input() isAns: boolean = false;
  @Input() isOption: boolean = false;
  @Input() showMark: boolean = true;
  @Input() setFocus: boolean = false;
  @Input() itemId: number;
  @Input() visibility: boolean = false;
  @Output() updateContent = new EventEmitter<any>();
  @Output() selectedAnswer = new EventEmitter<any>();
  @Output() selectedOption = new EventEmitter<any>();
  @Output() setVisibility = new EventEmitter<any>();
  @Input() showAttributes: boolean = true;
  allTags$: Observable<any>;
  fileSizeInBytes: number;
  uploader: FileUploader;
  public m: Model;
  private readonly subscription = new Subscription();
  ngOnInit() {
    this.init_model();
    this.init_store();
    this.sub_store();
    this.fetchFormulas();
  }
  ngAfterViewInit() {
    this.editor.getEditor().setOptions({
      enableBasicAutocompletion: true,
      enableSnippets: true,
      enableLiveAutocompletion: true,
      wrap: true
    });
    //if (this.setFocus) { this.editor.getEditor().focus() }
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.selectedRows) {
      setTimeout(() => {
        this.refreshTags(changes.selectedRows.currentValue);
      }, 0);
    }
    if (changes && changes.appliedTags) {
      if (changes.appliedTags) { this.selectedRows[0].tags = changes.appliedTags.currentValue }
      setTimeout(() => {
        this.refreshTags(this.selectedRows);
      }, 0);
    }
    if (changes && changes.tagsList) {
      this.m.tags = changes.tagsList.currentValue;
      setTimeout(() => {
        this.refreshTags(this.selectedRows);
      }, 0);
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onPaste(e) {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    var blob = null;
    for (const item of items) {
      if (item.type.indexOf("image") === 0 || item.type.indexOf("text/html") === 0) {
        blob = item.getAsFile();
      }
    }
    var imageFile = blob;
    var acadYear = "";
    if (this.m.currentPersona) { acadYear = new Date(this.m.currentPersona.acadYearFrom).getFullYear().toString().substring(2, 4) + "-" + new Date(this.m.currentPersona.acadYearTo).getFullYear().toString().substring(2, 4); }
    var artefact_meta = {
      'Description': "Image for Assessment question",
      'MetaData': {}
    };
    if (imageFile) { artefact_meta['Title'] = imageFile.name }
    if (imageFile && acadYear) {
      this.service.saveImage(imageFile, artefact_meta, acadYear).subscribe(res => {
        this.undo();
        if (res) {
          var uploadedFile = res;
          if (uploadedFile && uploadedFile['url']) {
            var urlArr = uploadedFile['url'].split('upload');
            if (urlArr && urlArr.length > 0) {
              const url_with_dimensions = this.vaultService.getUrlWithDimensions(urlArr[0], urlArr[1]);
              uploadedFile['url'] = url_with_dimensions;
              this.insertImage(uploadedFile);
            }
          }
        }
      });
    }
  }

  init_model() {
    this.m = new Model();
    let value = "slide";
    this.typeAheadList.values.push(value);
    if (this.key != "coas") {
      this.toolbarSettings = { undo: true, redo: true, formula: true, table: true };
    }
  }
  init_store() {
    this.service.getAllImageTags().subscribe(res => {
      this.m.tags = res['data'];
      this.m.tags.forEach(t => {
        t.edit = false;
        t.applied = false;
      });

      var tagFilter = [];
      this.m.tags.forEach(tg => {
        var tagFilterObj = {};
        tagFilterObj['name'] = tg.name;
        tagFilterObj['checked'] = false;
        tagFilterObj['children'] = [];
        tagFilterObj['fe'] = "tags:" + tg.name;
        tagFilter.push(tagFilterObj);

        let section_tags = this.m.filterOptions.sections.find(sec => sec.section == "Tags");
        if (section_tags) section_tags.filterItems = tagFilter;
      });
    });

    this.m.visibility = this.visibility;
  }
  sub_store() {
    this.store.select(rootReducer.get_state_app).subscribe(state => {
      if (state) {
        this.m.currentPersona = state.currentPersona;
        this.userEmail = state.userEmail;
        var dt = new Date();
        var ts = dt.getFullYear().toString() + (dt.getMonth() + 1).toString() + dt.getDate().toString() + dt.getHours().toString() + dt.getSeconds().toString();
        this.m.drawFileName = this.m.currentPersona.clientShortName + "-" + ts.toString();
      }
    });

    this.store.select(rootReducer.get_selected_topic).subscribe(topic => {
      if (topic && topic.id && topic.fileName && topic.content) {
        this.m.selectedTopic = topic;
        this.m.fileId = topic.id;
      }
    });
    this.subscription.add(this.store.select(rootReducer.get_all_formulas).subscribe(formulas => {
      if (formulas) {
        this.m.formulas = formulas;
      }
    }))
  }


  insertH1() {
    let h1 = "# ";
    let currPos = this.editor.getEditor().getCursorPosition();
    this.editor.getEditor().session.insert({ row: currPos.row, column: 0 }, h1);
    this.editor.getEditor().renderer.scrollCursorIntoView();
    this.editor.getEditor().focus();
  }
  insertH2() {
    // let h2 = "";
    // if(this.q && this.q.length>0){ h2 = "\n## "; }
    // else{ h2 = "## "; }
    let h2 = "## ";
    let currPos = this.editor.getEditor().getCursorPosition();
    this.editor.getEditor().session.insert({ row: currPos.row, column: 0 }, h2);
    this.editor.getEditor().renderer.scrollCursorIntoView();
    this.editor.getEditor().focus();
  }
  insertH3() {
    let h3 = "### ";
    let currPos = this.editor.getEditor().getCursorPosition();
    this.editor.getEditor().session.insert({ row: currPos.row, column: 0 }, h3);
    this.editor.getEditor().renderer.scrollCursorIntoView();
    this.editor.getEditor().focus();
  }
  bold() {
    var selText = this.editor.getEditor().getSelectedText();
    if (selText) {
      this.editor.getEditor().insertSnippet("**${1:$SELECTION}**");
      this.editor.getEditor().renderer.scrollCursorIntoView();
    }
    this.editor.getEditor().focus();
  }
  italic() {
    var selText = this.editor.getEditor().getSelectedText();
    if (selText) {
      this.editor.getEditor().insertSnippet("*${1:$SELECTION}*");
      this.editor.getEditor().renderer.scrollCursorIntoView();
    }
    this.editor.getEditor().focus();
  }
  underline() {
    var selText = this.editor.getEditor().getSelectedText();
    if (selText) {
      this.editor.getEditor().insertSnippet("<u>${1:$SELECTION}</u>");
      this.editor.getEditor().renderer.scrollCursorIntoView();
    }
    this.editor.getEditor().focus();
  }
  undo() {
    this.editor.getEditor().undo();
    this.editor.getEditor().focus();
  }
  redo() {
    this.editor.getEditor().redo();
    this.editor.getEditor().focus();
  }
  copyAnswer() {
    var selText = this.editor.getEditor().getSelectedText();
    var selTextArr = selText.split("\n");
    if (selTextArr && selTextArr.length > 0) {
      selTextArr.forEach(text => {
        if (text) {
          var selTextObj = { selText: "", qIndex: 0, subQIndex: 0 };
          selTextObj.selText = text;
          selTextObj.qIndex = this.qIndx;
          selTextObj.subQIndex = this.indx;
          if (selTextObj.selText) {
            this.selectedAnswer.emit(selTextObj);
          }
        }
      });
      this.editor.getEditor().insertSnippet("");
      this.editor.getEditor().renderer.scrollCursorIntoView();
    }
  }
  copyOption() {
    var selText = this.editor.getEditor().getSelectedText();
    var selTextArr = selText.split("\n");
    if (selTextArr && selTextArr.length > 0) {
      selTextArr.forEach(text => {
        if (text) {
          var selTextObj = { selText: "", qIndex: 0, subQIndex: 0 };
          selTextObj.selText = text;
          selTextObj.qIndex = this.qIndx;
          selTextObj.subQIndex = this.indx;
          if (selTextObj.selText) {
            this.selectedOption.emit(selTextObj);
          }
        }
      });
      this.editor.getEditor().insertSnippet("");
      this.editor.getEditor().renderer.scrollCursorIntoView();
    }
  }
  get fileSizeInKb(): number {
    return this.fileSizeInBytes / 1024;
  }
  get fileSizeInMb(): number {
    return this.fileSizeInBytes / 1048576;
  }
  sizeWithinLimits(): boolean {
    // return this.fileSizeInKb <= 500; // 500 KB
    return this.fileSizeInKb <= 1024; // 1 MB
  }
  imageSizeWithinLimits(): boolean {
    return this.fileSizeInMb <= 20;
  }
  ut_upload(e) {
    this.m.showSizeError = false;
    if (e && e[0].name) {
      var acadYear = "";
      if (this.m.currentPersona) { acadYear = new Date(this.m.currentPersona.acadYearFrom).getFullYear().toString().substring(2, 4) + "-" + new Date(this.m.currentPersona.acadYearTo).getFullYear().toString().substring(2, 4); }
      const arte_meta = {
        'Title': e[0].name,
        'Description': "Image for Assessment question",
        'MetaData': {}
      };
      this.fileSizeInBytes = e[0].size;
      const size_within_limits = this.sizeWithinLimits();
      if (size_within_limits && acadYear != "") {
        if (this.vaultService.isFileFormatSupported(e[0])) {
          this.service.uploadFiles(e, arte_meta, acadYear).subscribe({
            next: res => {
              if (res) {
                this.m.uploadedFile = res;
                if (this.m.uploadedFile && this.m.uploadedFile.url) { this.insertImage(this.m.uploadedFile) }
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File uploaded successfully', life: 10000 });
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error uploading file', sticky: true });
              }
            },
            error: err => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error uploading file', sticky: true });
            }
          });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File format not supported', sticky: true });
        }
      } else { this.m.showSizeError = true; }
    }
  }
  ut_uploadFile() {
    this.m.showSizeError = false;
    const inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#file');
    if (inputEl.files[0] && inputEl.files[0].name) {
      var acadYear = "";
      if (this.m.currentPersona) {
        acadYear = new Date(this.m.currentPersona.acadYearFrom).getFullYear().toString().substring(2, 4) + "-" + new Date(this.m.currentPersona.acadYearTo).getFullYear().toString().substring(2, 4);
      }
      const arte_meta = {
        'Title': inputEl.files[0].name,
        'Description': "Image for Assessment question",
        'MetaData': {}
      };
      if (inputEl.files[0].type != "video/mp4") {
        this.fileSizeInBytes = inputEl.files[0].size;
        const size_within_limits = this.sizeWithinLimits();
        if (size_within_limits && acadYear != "") {
          if (this.vaultService.isFileFormatSupported(inputEl.files[0])) {
            this.service.uploadFiles(inputEl.files, arte_meta, acadYear).subscribe({
              next: res => {
                if (res) {
                  this.m.uploadedFile = res;
                  if (this.m.uploadedFile && this.m.uploadedFile.url) { this.insertImage(this.m.uploadedFile) }
                  this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File uploaded successfully', life: 10000 });
                } else {
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error uploading file', sticky: true });
                }
              },
              error: err => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error uploading file', sticky: true });
              }
            })
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File format not supported', sticky: true });
          }
        } else {
          this.m.showSizeError = true;
        }
      } else {
        this.fileSizeInBytes = inputEl.files[0].size;
        const size_within_limits = this.imageSizeWithinLimits();
        if (size_within_limits && acadYear != "") {
          // ignore file format check for video files
          this.service.uploadFiles(inputEl.files, arte_meta, acadYear).subscribe({
            next: res => {
              if (res) {
                this.m.uploadedFile = res;
                if (this.m.uploadedFile && this.m.uploadedFile.url) { this.insertImage(this.m.uploadedFile) }
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'File uploaded successfully', life: 10000 });
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error uploading file', sticky: true });
              }
            },
            error: err => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error uploading file', sticky: true });
            }
          })
        }
        else { this.m.showSizeError = true; }
      }
    }
  }
  insertVaultImage(img) {
    if (img.selected) {
      this.m.selectedImages.push(img.id);
      var urlArr = img['url'].split('upload/e_make_transparent');
      if (urlArr && urlArr.length > 0) {
        const url_with_dimensions = this.vaultService.getUrlWithDimensions(urlArr[0], urlArr[1]);
        img['url'] = url_with_dimensions;

        this.allSelectedImages.push(img);

        img.tags.forEach(it => {
          let tag = this.m.tags.find(t => t.id == it);
          if (tag != null) tag.applied = true;
        })
      }
    }
    else {
      this.m.selectedImages = this.m.selectedImages.filter(o => o != img.id);
      this.m.tags.forEach(it => {
        it.applied = false;
      });
    }
  }

  ut_insertImgToEditor() {
    this.allSelectedImages.forEach(img => this.insertImage(img));
    this.m.selectedImages = [];
    this.allSelectedImages = [];
  }

  insertImage(img) {
    if (img && img.url) {
      let imgLine = `\n\n![](${img.url})`;
      if (img.url.includes('.pdf')) {
        const pdfFileName = 'PDF ' + img.url.split('.pdf')[0].split('/').pop() + '.pdf';
        imgLine = ''
        // imgLine = `\n\n[${pdfFileName}](${img.url})\n`;
        imgLine += `\n<i class="${img.id}" title="${img.title}">Loading PDF...</i>\n`;
      }
      this.editor.getEditor().session.insert(this.editor.getEditor().getCursorPosition(), imgLine);
      this.editor.getEditor().renderer.scrollCursorIntoView();
    }
    this.editor.getEditor().focus();
  }

  insertVideo(url) {
    var video = `\n\n<div class="video-container"><video controls controlsList="nodownload" width="320">
    <source src="${url}">
  </video></div>`;
    this.editor.getEditor().session.insert(this.editor.getEditor().getCursorPosition(), video);
    this.editor.getEditor().renderer.scrollCursorIntoView();
    this.editor.getEditor().focus();
  }

  ut_getVaultImages(qryString) {
    if (this.m.imageModalOpen) {
      this.service.getVaultImages(qryString).subscribe((res: any) => {
        if (res && res.data && res.data.length > 0) {
          this.m.vaultImages = res.data;
          this.m.vaultImages.forEach(img => {
            img.selected = false;
            var urlArr = img['url'].split('upload');
            if (urlArr && urlArr.length > 0) {
              var url_with_transparent_bg = urlArr[0] + "upload/e_make_transparent" + urlArr[1];
              img['url'] = url_with_transparent_bg;
            }
          });
        }
        else {
          this.m.vaultImages = [];
        }
      });
    }
  }
  onQry(qStr: string) {
    if (qStr) {
      this.qryStr = qStr;
      this.ut_getVaultImages(qStr);
    }
  }
  saveImage() {
    var fn = this.m.drawFileName + ".png";
    var imageBlob = new Blob([this.lc.getImage().toDataURL()], { type: "image/png" });
    var imageFile = new File([imageBlob], fn, { type: "image/png" });
    var acadYear = "";
    if (this.m.currentPersona) { acadYear = new Date(this.m.currentPersona.acadYearFrom).getFullYear().toString().substring(2, 4) + "-" + new Date(this.m.currentPersona.acadYearTo).getFullYear().toString().substring(2, 4); }
    var artefact_meta = {
      'Title': this.m.drawFileName,
      'Description': "Image for Assessment question",
      'MetaData': {}
    };
    if (imageFile && acadYear) {
      this.service.saveImage(imageFile, artefact_meta, acadYear).subscribe(res => {
        if (res) {
          var uploadedFile = res;
          if (uploadedFile && uploadedFile['url']) {
            var urlArr = uploadedFile['url'].split('upload');
            if (urlArr && urlArr.length > 0) {
              const url_with_dimensions = this.vaultService.getUrlWithDimensions(urlArr[0], urlArr[1]);
              uploadedFile['url'] = url_with_dimensions;
              this.insertImage(uploadedFile)
            }
          }
        }
      });
    }
  }
  initLC() {
    if (!this.lc) {
      setTimeout(() => {
        var backgroundImage = new Image()
        backgroundImage.src = '/assets/img/grid.png';
        this.lc = LC.init(this.el.nativeElement.querySelector('.qaDraw'), {
          imageURLPrefix: '/assets/img/lc-img',
          tools: [LC.tools.Pencil, LC.tools.Eraser, LC.tools.Line, LC.tools.Rectangle, LC.tools.Ellipse, LC.tools.Polygon, LC.tools.Text, LC.tools.Pan, LC.tools.SelectShape],
          backgroundShapes: [LC.createShape('Image', { x: 0, y: 0, image: backgroundImage, scale: 1 })]
        });
      }, 100);
    }
  }
  selectExpression(exp) {
    if (exp) { this.m.mathContent = this.m.mathContent + " " + exp; }
  }
  selectChemExpression(exp) {
    if (exp) { this.m.chemContent = this.m.chemContent + " " + exp; }
  }
  insertExpression(id) {
    if (this.m.mathContent && this.m.mathContent.length) {
      var mathLine = " " + this.m.mathContent;
      this.editor.getEditor().session.insert(this.editor.getEditor().getCursorPosition(), mathLine);
      this.m.mathContent = ""
    }
    $(id).modal('hide');
    setTimeout(() => {
      this.editor.getEditor().focus();
    }, 500);
  }
  insertChemExpression(id) {
    if (this.m.chemContent && this.m.chemContent.length) {
      var chemLine = " " + this.m.chemContent;
      this.editor.getEditor().session.insert(this.editor.getEditor().getCursorPosition(), chemLine);
      this.m.chemContent = ""
    }
    $(id).modal('hide');
    setTimeout(() => {
      this.editor.getEditor().focus();
    }, 500);
  }
  getHtmlText(q) {
    if (q) {
      let c = this.markdownService.compile(q);
      c = this.markdownService.renderKatex(c);
      return c;
    }
    else { return ""; }
  }
  initOptionsModal() {
    this.ut_comments();
  }
  initAttributes() {
    this.ut_setAttributes();
  }
  initScripts() { }
  initImgModal(txt) {
    this.m.searchText = txt;
    this.m.filterOptionsCopy = JSON.parse(JSON.stringify(this.m.filterOptions));
    this.ut_getVaultImages(this.m.initQryStr);
  }
  updateImageSize() {
    if (this.m.selectedImageSize == "small") {
      this.m.imageHeight = 75;
      this.m.imageWidth = 90
    }
    else if (this.m.selectedImageSize == "default") {
      this.m.imageHeight = 100;
      this.m.imageWidth = 300;
    }
    else if (this.m.selectedImageSize == "large") {
      this.m.imageHeight = 200;
      this.m.imageWidth = 600;
    }
  }
  qChange(event) {
    if (event) {
      var qObj = { q: {}, index: null, qIndex: null, ansIndex: null, optionIndex: null, isAns: null, isOption: null };
      qObj.q = event;
      qObj.index = this.indx;
      qObj.ansIndex = this.ansIndx;
      qObj.optionIndex = this.optionIndx;
      qObj.isAns = this.isAns;
      qObj.isOption = this.isOption;
      qObj.qIndex = this.qIndx;
      this.updateContent.emit(qObj);
    }
  }
  insertTable() {
    this.editor.getEditor().session.insert(this.editor.getEditor().getCursorPosition(), this.m.table);
    this.editor.getEditor().focus();
  }

  refreshTags(selRows) {
    this.m.tags.forEach(tag => {
      tag.applied = false;
      tag.indeterminate = false;
    });
    if (this.m.tags.length > 0 && selRows && selRows.length == 1) {
      if (selRows[0].tags && selRows[0].tags.length > 0) {
        var somePresent = false;
        var allPresent = false;
        var presentTags = this.m.tags.filter(tg => (selRows[0].tags.map(function (e) { return e.name; }).indexOf(tg.name) >= 0));
        if (presentTags && presentTags.length > 0) {
          presentTags.forEach(pt => {
            pt.indeterminate = false;
            pt.applied = true;
          });
        }
      }
    }
    else if (this.m.tags.length > 0 && selRows && selRows.length > 1) {
      var somePresent = false;
      var allPresent = false;
      this.m.tags.forEach(tag => {
        tag.applied = false;
        tag.indeterminate = false;
        somePresent = this.someFindDeep(selRows, tag.name);
        if (somePresent) {
          tag.indeterminate = true;
          allPresent = selRows.every(row => row.tags.find(tg => tg.name == tag.name));
          if (allPresent) {
            tag.indeterminate = false;
            tag.applied = true;
          }
        }
        else { tag.applied = false; }
      });
    }
  }

  someFindDeep = function (data, tagName) {
    var that = this;
    return data.some(function (e) {
      if (e.name == tagName) return true;
      else if (e.tags) return that.someFindDeep(e.tags, tagName)
    })
  };

  ut_updateTag(tag) {
    if (tag.applied) {
      this.ut_applyTag(tag);
    }
    else if (!tag.applied) {
      this.ut_removeTag(tag);
    }

    let selectedTags = [];
    this.m.tags.forEach(t => {
      if (t.applied) selectedTags.push(t.id);
    });

    let payload = [];

    this.m.selectedImages.forEach(img => {
      let obj = { Id: img, Tags: selectedTags };
      payload.push(obj);
    });

    this.service.updateArtefactTags(payload).subscribe(res => {
      this.ut_getVaultImages(this.qryStr);
    });
  }

  removeTag(tag, img) {
    let payload = [];

    let t = this.m.tags.find(o => o.name == tag.name);
    let updatedTags = img.tags.filter(o => o != t.id);

    img.tagList = img.tagList.filter(o => o.name != tag.name);

    let obj = { Id: img.id, Tags: updatedTags };
    payload.push(obj);
    this.service.updateArtefactTags(payload).subscribe();
  }

  ut_applyTag(tag) {
    var selectedData = this.selectedRows;
    selectedData.forEach(e => {
      if (e.tags && e.tags.length > 0) {
        var tagIncluded = e.tags.find(tg => tg.name == tag.name);
        if (!tagIncluded) { e.tags.push(tag); }
      }
      else if (e.tags && e.tags.length <= 0) { e.tags.push(tag); }
    });
  }

  ut_removeTag(tag) {
    var selectedData = this.selectedRows;
    selectedData.forEach(e => {
      if (e.tags && e.tags.length > 0) {
        var tagIncluded = e.tags.find(tg => tg.name == tag.name);
        if (tagIncluded) {
          e.tags = e.tags.filter(t => t.name != tag.name);
        }
      }
    });
  }

  openAddTag(ev) {
    ev.stopPropagation();
    this.m.addTag = true;
    this.m.duplicate = false;
  }
  cancelAddTag(ev) {
    ev.stopPropagation();
    this.m.duplicate = false;
    this.m.addTag = false;
    this.m.newTag = "";
  }
  addTag(ev) {
    ev.stopPropagation();
    this.m.duplicate = false;
    if (this.m.newTag) {
      var duplicateTag = this.m.tags.find(tg => tg.name == this.m.newTag)
      if (duplicateTag) { this.m.duplicate = true; }
      else {
        this.m.duplicate = false;
        this.m.newTag = this.m.newTag.toLowerCase();
        var newTag = { name: this.m.newTag, predefined: false, meta: {}, status: 1 };
        var newTagObj = JSON.parse(JSON.stringify(newTag));
        this.service.createImageTag(newTagObj).subscribe(res => {
          if (res) {
            var tagObj = res['data'];
            var nt = tagObj;
            nt.applied = false;
            this.m.tags.unshift(nt);
            this.m.newTag = "";
            this.m.addTag = false;
            this.init_store()
          }
        });
      }
    }
  }
  hideTag(ev, tag, indx) {
    ev.stopPropagation();
    this.ut_removeTag(tag)
    this.m.tags.splice(indx, 1);
  }
  enableTag(ev, tag) {
    ev.stopPropagation();
    tag.status = 1;
    this.service.enableImgTag(tag.id).subscribe();
  }
  disableTag(ev, tag) {
    ev.stopPropagation();
    if (tag.applied || tag.indeterminate) { this.ut_removeTag(tag); }
    tag.applied = false;
    tag.indeterminate = false;
    tag.status = 99;
    this.service.disableImgTag(tag.id).subscribe();
  }
  omit_special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 58) || k == 95);
  }
  openEditTag(ev, tag) {
    ev.stopPropagation();
    tag.edit = true;
    this.m.selectedTag = tag;
    this.m.tagCopy = JSON.parse(JSON.stringify(tag));
    this.m.editTagDuplicate = false;
  }
  cancelEditTag(ev, tag) {
    ev.stopPropagation();
    this.m.editTagDuplicate = false;
    tag.edit = false;
    this.m.tagCopy = {};
    this.m.selectedTag = {};
  }
  editTag(ev, tag) {
    ev.stopPropagation();
    var oldTag = JSON.parse(JSON.stringify(this.m.selectedTag));
    this.m.editTagDuplicate = false;
    if (this.m.tagCopy) {
      var duplicateTag = this.m.tags.find(tg => tg.name == this.m.tagCopy.name)
      if (duplicateTag) {
        this.m.editTagDuplicate = true;
      }
      else {
        this.m.editTagDuplicate = false;
        var selTag = this.m.tags.find(tg => tg.name == this.m.selectedTag.name);
        if (selTag) {
          selTag.name = this.m.tagCopy.name;
          selTag.edit = false;
        }
        var tgObj = { oldName: oldTag.name, newName: selTag.name };
        this.service.updateImgTag(selTag).subscribe();
        this.m.tagCopy = {};
      }
    }
  }

  ut_comments() {
    this.m.comments = [];
    if (this.m.selectedTopic.comments != null) {
      this.m.comments = this.m.selectedTopic.comments.filter(o => o.itemId == this.itemId);
      this.m.comments = this.m.comments.reverse();
    }
  }

  ut_setAttributes() {
    if (this.m.selectedTopic.dataAttributes != null) {
      let att = this.m.selectedTopic.dataAttributes.find(o => o.itemId == this.itemId);
      if (att != null) {
        this.m.key = att.attributes[0].key;
        this.m.value = att.attributes[0].value;

        let self = this.m;
        this.m.selectedTopic.content.nodes.forEach(n => {
          n.children.forEach(e => {
            if (e.lxList) {
              let i = e.lxList.find(o => o.itemId == this.itemId);
              if (i != null) {
                let s = '<section' + ' ' + 'data-' + self.key + '=' + self.value + '>';
                e.htmlText = e.htmlText.replace('<section>', s);
              }
            }
          });
        })
      }
    }
  }

  ut_addComment() {
    let html = this.getHtmlText(this.m.comment);

    let cm = { text: this.m.comment, html: html, by: this.userEmail, timeStamp: new Date(), itemId: this.itemId };
    this.m.comments.unshift(cm);

    let payload = { Id: this.m.fileId, Text: this.m.comment, Html: html, By: this.userEmail, ItemId: this.itemId };
    this.service.addComment(payload).subscribe(res => {
      if (res) {
        this.m.selectedTopic.comments = [];
        this.m.selectedTopic.comments = this.m.selectedTopic.comments.sort(function (a, b) {
          return b.timeStamp - a.timeStamp;
        });
        this.store.dispatch(new acadAction.SetSelectedTopic(this.m.selectedTopic));
      }
    });

    this.m.comment = "";
  }

  ut_attributes() {
    let self = this.m;
    this.m.selectedTopic.content.nodes.forEach(n => {
      n.children.forEach(e => {
        if (e.lxList != null) {
          let i = e.lxList.find(o => o.itemId == this.itemId);
          if (i) {
            let s = '<section' + ' ' + 'data-' + self.key + '=' + self.value + '>';
            e.htmlText = e.htmlText.replace('<section>', s);
          }
        }
      });
    })

    let attribute = { Key: this.m.key, Value: this.m.value, ItemId: this.itemId };
    let attribute_list = [];
    attribute_list.push(attribute);

    let payload = { Id: this.m.selectedTopic.id, Attributes: attribute_list };
    this.service.updateDataAttributes(payload).subscribe();
  }

  ut_setVisibility() {
    let obj = { index: this.indx, itemId: this.itemId }
    this.setVisibility.emit(obj)
    this.m.selectedTopic.content.nodes.forEach(n => {
      n.children.forEach(c => {
        let i = c.lxList.find(o => o.itemId == this.itemId);
        if (i != null) {
          if (i.studentVisibility == true) this.m.visibility = true;
          else if (i.studentVisibility == false) this.m.visibility = false;
        }
      })
    })
  }


  ut_videos(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.m.recordLinks = [];
    this.m.driveLinks = [];
    this.m.streamLinks = [];
    if (this.m.selectedTopic.videoLinks != null) {
      let videoLinks = this.m.selectedTopic.videoLinks.filter(o => o.itemId == this.itemId);
      if (videoLinks.length > 0) {
        this.m.recordLinks = videoLinks.filter(o => o.type == 10);
        this.m.driveLinks = videoLinks.filter(o => o.type == 20);
        this.m.streamLinks = videoLinks.filter(o => o.type == 30);

        this.m.recordLinks.forEach(rl => {
          rl.edit = false;
        });

        this.m.driveLinks.forEach(rl => {
          rl.edit = false;
        });

        this.m.streamLinks.forEach(rl => {
          rl.edit = false;
        });
      }
      if (videoLinks.length <= 0) {
        this.m.recordLinks.push({ itemId: this.itemId, type: 10, url: "", edit: false });
        this.m.driveLinks.push({ itemId: this.itemId, type: 10, url: "", edit: false });
        this.m.streamLinks.push({ itemId: this.itemId, type: 10, url: "", edit: false });
      }
    }
  }

  ut_saveVideoLinks() {
    let links = [];
    this.m.recordLinks.forEach(rl => {
      if (rl.url != '') {
        let obj = { ItemId: this.itemId, Type: "record", Url: rl.url.trim() };
        links.push(obj);
      }
    });

    this.m.driveLinks.forEach(rl => {
      if (rl.url != '') {
        let obj = { ItemId: this.itemId, Type: "drive", Url: rl.url.trim() };
        links.push(obj);
      }
    });

    this.m.streamLinks.forEach(rl => {
      if (rl.url != '') {
        let obj = { ItemId: this.itemId, Type: "stream", Url: rl.url.trim() };
        links.push(obj);
      }
    });

    let payload = { Id: this.m.fileId, Links: links };
    if (links.length > 0) {
      this.service.addVideoLink(payload).subscribe(res => {
        let path = "vx/curriculum";
        this.service.getDocsByPath(path, '&$search=&$skip=0&$top=1024').subscribe(res => {
          if (res && res['data']) {
            let files = res['data'];
            if (files && files.length > 0) {
              this.store.dispatch(new acadAction.SetTopics(res['data']));
            }
          }
        });
      });

      let docObj = { id: this.m.fileId, fileName: this.m.selectedTopic.fileName, content: this.m.selectedTopic.res, comments: this.m.selectedTopic.comments, videoLinks: links }
      this.store.dispatch(new acadAction.SetSelectedTopic(docObj));
    }
  }

  removeEdit(rl) {
    rl.edit = false;
  }

  edit(rl) {
    rl.edit = true;
  }

  close() {
    this.pop.hide();
    this.m.key = "";
    this.m.value = "";
  }
  ut_resetSearchBox() {
    this.m.searchTextFormula = "";
  }
  ut_searchFormula() {
    this.m.searchTextFormula = this.m.searchTextFormula.trim();
    const searchText = this.m.searchTextFormula.toLowerCase();
    this.m.searchedResults = []
    if (this.m.searchTextFormula.length > 0) {
      this.m.formulas.forEach(f => {
        if (f.title.includes(searchText) || f.description.includes(searchText)) {
          const searchString = f.title + " - " + f.description;
          this.m.searchedResults.push(Object.assign({}, f, { searchString }));
        }
      });
    }
  }
  ut_typeaheadOnSelect(e: TypeaheadMatch): void {
    const { item } = e;
    this.m.titleFormula = item.title;
    this.m.descriptionFormula = item.description;
    this.m.mathContent = this.prepareFormula(item.data);
  }
  private prepareFormula(data) {
    let { formula } = data;
    if (formula) {
      if (formula[0] != '$') {
        formula = '$' + formula;
      }
      if (formula[formula.length - 1] != '$') {
        formula = formula + '$';
      }
    }
    return formula
  }
  private fetchFormulas() {
    setTimeout(() => {
      if (this.m.formulas && this.m.formulas.length > 0) {
        // ingnore if formulas are already fetched        
      } else {
        this.pulseService.formulaJob({ operation: 'get_all_formulas', data: {} });
      }
    }, 6000);
  }
}