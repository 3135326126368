import { Component, OnInit, TemplateRef, NgZone, ViewChild, ElementRef, AfterViewChecked, HostListener } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import * as _ from 'underscore';
import { timer } from 'rxjs';

import { CTApi } from '../service/ct-api';
import { environment } from '../../environments/environment';

import * as rootReducer from '../rootReducer';
import * as appAction from '../store/app-action';
import * as countdownTimerAction from '../components/countdownTimer/store/action';
import * as googleMeetAction from '../components/google-meet/store/action';
import * as notificationAction from '../notifications/store/action';
import { ClipboardService as clipboardService } from '../service/clipboard-service';
import { PulseService } from '../service/pulse';
import * as Data from './../userLinks.js';

import { AppService } from '../service/app.service';
import { DEX_CONTEXTS } from '../dex/services/constant';
import { MENU_TYPE, QUICKLINKS_FOR_PUBLISH } from '../service/constants';

class Link {
	href: string;
	text: string;
}

class Notification {
	id: string;
	title: string;
	content: string;
	sender: string;
	ts: string;
	read: boolean;
	date: Date;
	links: Link[] = [];
	expand: boolean = false;
	from: string;
	expiry: Date;
	status: string;
	type: string;
}

class Model {
	domain: string = "";
	subDomain: string = "";
	signInClicked: boolean = false;
	notifications: Notification[] = [];
	iconURL: string;
	mentionConfig = {
		mentions: [{
			items: ["sms", "notify", "email", "wf", "run"],
			triggerChar: '/',
			dropUp: true
		},
		{
			items: ["cron", "sub", "tag", "title", "to"],
			triggerChar: '#',
			dropUp: true
		}]
	}
	format(item: any) {
		var formattedStr = "";
		var selStr = "/" + item['label'];
		if (item['label'] == 'sms') {
			var appendStr = "\n-> #to\n-> ";
			formattedStr = selStr + appendStr;
			return formattedStr;
		}
		else if (item['label'] == 'notify') {
			var appendStr = "\n-> #to\n-> ";
			formattedStr = selStr + appendStr;
			return formattedStr;
		}
		else if (item['label'] == 'email') {
			var appendStr = "\n-> #to\n-> #sub\n-> ";
			formattedStr = selStr + appendStr;
			return formattedStr;
		}
		else if (item['label'] == 'wf') {
			var appendStr = "\n-> #title\n-> #cron\n-> #tags\n-> #description ";
			formattedStr = selStr + appendStr;
			return formattedStr;
		}
		else if (item['label'] == 'run') {
			var appendStr = "\n-> #wf ";
			formattedStr = selStr + appendStr;
			return formattedStr;
		}
		else { return item['label']; }
	}
	userName: string;
	showMsg: any = false;
	unreadNotifs: any = false;
	errorNotif: any = false;
	Notifications: any[] = [];
	navbarDisplayOptions: any[] = Object.values(MENU_TYPE);
	currentNBDisplay: any = { name: "Standard", type: "standard" };
	clipboard: any[] = [];
	showClipboard: boolean = false;
	newNotification: boolean = false;
	unreadNotifications: number = 0;
	disableMobileSubmit: boolean = true;
	disableSubmit: boolean = true;
	releaseVersion: string;
	releaseDate: string;
	showErrMsg: boolean = false;
	errorMsg: string;
	recoveryType: string = "email";
	recoveryEmail: string;
	recoveryMobile: string;
	showRecoveryErr: boolean;
	emailEntered: string;
	mobileEntered: string;
	showTimer: boolean = false;
	timerStopped: boolean;
	timer: any;
	showOTPExpired: boolean = false;
	public userLoggedIn: any = false;
	public email: any = '';
	public password: any = '';
	public showInvalidCred: any = false;
	public token: any = '';
	public ctContext: any;
	public userReg: any = { Name: '', Email: '', Password: '' };
	public signUpSuccess: boolean = false;
	public signUpError: boolean = false;
	public passwordReset: boolean = false;
	public resetPassword: any = { Email: '' };
	public changePassword: any = { CurrentPassword: '', NewPassword: '', ConfirmPassword: '' };
	public passwordMismatch: boolean = false;
	public personaChanging: boolean = false;
	todayDate = new Date();
	currentYear = this.todayDate.getFullYear();
	currentMonth = this.todayDate.getMonth() + 1;
	currentDate = this.todayDate.getDate();
	currentDateString = this.currentYear + '-' + ('0' + this.currentMonth).slice(-2) + '-' + ('0' + this.currentDate).slice(-2);
	ctDashboardHeaders: string;
	// userLinks: any = {
	// 	"Academics":
	// 	{
	// 		"col1": [
	// 			{
	// 				"id": "a1",
	// 				"groupType": "Report",
	// 				"title": "Attendance / Calendar / Timetable",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Absentees report",
	// 						"description": "view the report for absentees for a particular day",
	// 						"endPoint": "/academics/absenteesReport"
	// 					},
	// 					{
	// 						"title": "Holiday list",
	// 						"description": "view the holiday list for the academic year",
	// 						"endPoint": "/academics/holidayListReport"
	// 					},
	// 					{
	// 						"title": "Today's events",
	// 						"description": "view the events for today",
	// 						"endPoint": "/academics/todaysEventsReport"
	// 					},
	// 					{
	// 						"title": "Upcoming events",
	// 						"description": "view the upcoming events",
	// 						"endPoint": "/academics/upcomingEventsReport"
	// 					}
	// 				]
	// 			},
	// 			{
	// 				"id": "a2",
	// 				"groupType": "Report",
	// 				"title": "Management Reports",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Consolidated Marks",
	// 						"description": "view course / sectionwise consolidated report for final exam",
	// 						"endPoint": "/academics/consolidatedMarksReport"
	// 					},
	// 					{
	// 						"title": "Rank List",
	// 						"description": "view course-wise top 10 rank holders for final exam",
	// 						"endPoint": "/academics/rankListReport"
	// 					},
	// 					{
	// 						"title": "Result At A Glance",
	// 						"description": "view course-wise result at a glance for final exam",
	// 						"endPoint": "/academics/resultAtAGlanceManagementReport"
	// 					},
	// 					{
	// 						"title": "Top Scorers In Each Subject",
	// 						"description": "view course-wise top scorers in each subject",
	// 						"endPoint": "/academics/topScorersReport"
	// 					},
	// 					{
	// 						"title": "First Ten Toppers",
	// 						"description": "view course-wise first ten toppers",
	// 						"endPoint": "/academics/firstTenToppersReport"
	// 					},
	// 					{
	// 						"title": "A+ In All Subjects",
	// 						"description": "view course-wise students scoring A+ in all subjects throughout the year",
	// 						"endPoint": "/academics/aPlusInAllSubjectsReport"
	// 					}
	// 				]
	// 			}
	// 		],
	// 		"col2": [
	// 			{
	// 				"id": "b1",
	// 				"groupType": "Report",
	// 				"title": "Exams (Ensure exams are locked before running these reports)",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Attendance report",
	// 						"description": "view the attendance for all the exams",
	// 						"endPoint": "/academics/examAttendanceReport"
	// 					},
	// 					{
	// 						"title": "Coursewise Result Analysis",
	// 						"description": "view examwise / coursewise Coursewise Result Analysis report",
	// 						"endPoint": "/academics/coursewiseResultAnalysisReport"
	// 					},
	// 					{
	// 						"title": "Consolidated Report",
	// 						"description": "view exam consolidated report",
	// 						"endPoint": "/academics/consolidatedReport"
	// 					},
	// 					{
	// 						"title": "Exams list",
	// 						"description": "view the exams list",
	// 						"endPoint": "/academics/examListReport"
	// 					},
	// 					{
	// 						"title": "Examwise Failures",
	// 						"description": "view examwise / coursewise failures report",
	// 						"endPoint": "/academics/examwiseFailuresReport"
	// 					},
	// 					{
	// 						"title": "Failures Report",
	// 						"description": "view the failures report",
	// 						"endPoint": "/academics/failuresReport"
	// 					},
	// 					{
	// 						"title": "Full Marks",
	// 						"description": "view examwise / coursewise report of students who have secured full marks in each subject",
	// 						"endPoint": "/academics/fullMarksReport"
	// 					},
	// 					{
	// 						"title": "Classwise / Gradewise Statistics",
	// 						"description": "view examwise / coursewise / gradewise statistics",
	// 						"endPoint": "/academics/gradewiseStatisticsReport"
	// 					},
	// 					{
	// 						"title": "Hall Ticket",
	// 						"description": "view students hall ticket for an exam",
	// 						"endPoint": "/academics/hallTicket"
	// 					},
	// 					{
	// 						"title": "No Due Certificate",
	// 						"description": "view student no due certificate",
	// 						"endPoint": "/academics/noDueCertificate"
	// 					},
	// 					{
	// 						"title": "Result Analysis",
	// 						"description": "view examwise / coursewise result analysis",
	// 						"endPoint": "/academics/resultAnalysisReport"
	// 					},
	// 					{
	// 						"title": "Result At A Glance",
	// 						"description": "view examwise / coursewise result at a glance",
	// 						"endPoint": "/academics/resultAtAGlanceReport"
	// 					},
	// 					{
	// 						"title": "Subject Average",
	// 						"description": "view examwise / coursewise subject average",
	// 						"endPoint": "/academics/subjectAverageReport"
	// 					},
	// 					{
	// 						"title": "Student performance",
	// 						"description": "view the report for student performance for all the exams",
	// 						"endPoint": "/academics/studentPerformanceReport"
	// 					}
	// 				]
	// 			}
	// 		],
	// 		"col3": [
	// 			{
	// 				"id": "c1",
	// 				"groupType": "ActionLink",
	// 				"title": "Action Links",
	// 				"subGroup": [
	// 					{
	// 						"title": "Attendance",
	// 						"apis": ["get/acad/attendance/absentees"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Take Attendance",
	// 								"description": "take attendance",
	// 								"endPoint": "/academics/attendance",
	// 								"Icon": "fa fa-plus",
	// 								"api": "get/acad/attendance/absentees"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Calendar",
	// 						"apis": ["get/acad/calendar"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/Edit event",
	// 								"description": "",
	// 								"endPoint": "/academics/calendar/manage",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/acad/calendar"
	// 							},
	// 							{
	// 								"title": "Add event",
	// 								"description": "",
	// 								"endPoint": "/academics/calendar/events",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/acad/calendar"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Exam",
	// 						"apis": ["get/acad/exam"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Manage",
	// 								"description": "Search / Edit",
	// 								"endPoint": "/academics/exam",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/acad/exam"
	// 							},
	// 							{
	// 								"title": "Evaluation",
	// 								"description": "Evaluation",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-plus"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Timetable",
	// 						"apis": ["get/acad/course"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Manage",
	// 								"description": "Add / Edit",
	// 								"endPoint": "/academics/timetable",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/acad/course"
	// 							},
	// 							{
	// 								"title": "Add",
	// 								"description": "Add",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-plus"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "SMS",
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": ""
	// 							}
	// 						]
	// 					}
	// 				]
	// 			}
	// 		]
	// 	},
	// 	"Admin":
	// 	{
	// 		"col1": [
	// 			{
	// 				"id": "a1",
	// 				"groupType": "Report",
	// 				"title": "Course",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Courses Offered",
	// 						"description": "view the course list for academic year",
	// 						"endPoint": "/admin/courseListReport"
	// 					}
	// 				]
	// 			},
	// 			{
	// 				"id": "a2",
	// 				"groupType": "Report",
	// 				"title": "Institute",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Calendar",
	// 						"description": "view calendar report for an institute",
	// 						"endPoint": "/admin/calendarReport"
	// 					},
	// 					{
	// 						"title": "Summary report",
	// 						"description": "view summary report for an institute",
	// 						"endPoint": "/admin/summaryReport"
	// 					},
	// 					{
	// 						"title": "Upcoming events",
	// 						"description": "view upcoming events for an institute",
	// 						"endPoint": "/admin/upcomingEventsAdminReport"
	// 					}
	// 				]
	// 			},
	// 			{
	// 				"id": "a3",
	// 				"groupType": "Report",
	// 				"title": "Receipt",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Consolidated Report",
	// 						"description": "Receipts consolidated report",
	// 						"endPoint": "/admin/receiptConsolidatedReport"
	// 					}
	// 				]
	// 			},
	// 			{
	// 				"id": "a4",
	// 				"groupType": "Report",
	// 				"title": "Organization",
	// 				"groupLinks": []
	// 			}

	// 		],
	// 		"col2": [
	// 			{
	// 				"id": "b1",
	// 				"groupType": "Report",
	// 				"title": "Security",
	// 				"groupLinks": [
	// 					{
	// 						"title": "User access report",
	// 						"description": "view the report for org user access",
	// 						"endPoint": "/admin/usersReport"
	// 					}
	// 				]
	// 			},
	// 			{
	// 				"id": "b2",
	// 				"groupType": "Report",
	// 				"title": "HR",
	// 				"groupLinks": [
	// 					{
	// 						"title": "HR report (WIP)",
	// 						"description": "view list of all HR",
	// 						"endPoint": "/admin/hrReport"
	// 					},
	// 					{
	// 						"title": "Subjectwise breakup (WIP)",
	// 						"description": "view report for subject wise HR breakup",
	// 						"endPoint": "/admin/subjectwiseBreakupReport"
	// 					}
	// 				]
	// 			},
	// 			{
	// 				"id": "b3",
	// 				"groupType": "Report",
	// 				"title": "Student",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Students Coursewise Breakup",
	// 						"description": "view report for course wise students breakup",
	// 						"endPoint": "/admin/coursewiseBreakupReport"
	// 					},
	// 					{
	// 						"title": "Student Details",
	// 						"description": "view report for section wise student details",
	// 						"endPoint": "/admin/studentDetailsReport"
	// 					},
	// 					{
	// 						"title": "Students Sectionwise Breakup",
	// 						"description": "view report for section wise student details breakup",
	// 						"endPoint": "/admin/sectionwiseBreakupReport"
	// 					},
	// 					{
	// 						"title": "Students Basic Sectionwise Breakup",
	// 						"description": "view report for section wise student details breakup",
	// 						"endPoint": "/admin/basicSectionwiseBreakupReport"
	// 					},
	// 					{
	// 						"title": "Students Parent Details",
	// 						"description": "view report for section wise student parent details ",
	// 						"endPoint": "/admin/sectionwiseParentDetailsReport"
	// 					},
	// 					{
	// 						"title": "New Admissions Report",
	// 						"description": "view report for new admissions",
	// 						"endPoint": "/admin/newAdmissionsReport"
	// 					}
	// 				]
	// 			}
	// 		],
	// 		"col3": [
	// 			{
	// 				"id": "c1",
	// 				"groupType": "ActionLink",
	// 				"title": "Action Links",
	// 				"subGroup": [
	// 					{
	// 						"title": "Course",
	// 						"apis": ["post/acad/course", "get/acad/course"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search courses",
	// 								"description": "",
	// 								"endPoint": "/admin/course/manage",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/acad/course"
	// 							},
	// 							{
	// 								"title": "Add Course",
	// 								"description": "",
	// 								"endPoint": "/admin/course/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/acad/course"
	// 							}
	// 						]
	// 					},
	// 					// {
	// 					// 	"title": "Data",
	// 					// 	"apis": ["get/arte/artefact/dataTemplates"],
	// 					// 	"subgroupLinks": [
	// 					// 		{
	// 					// 			"title": "Data Templates",
	// 					// 			"description": "",
	// 					// 			"endPoint": "/admin/data/manage/studentEdit",
	// 					// 			"Icon": "fa fa-search",
	// 					// 			"api": "get/arte/artefact/dataTemplates"
	// 					// 		}
	// 					// 	]
	// 					// },
	// 					// {
	// 					// 	"title": "HR",
	// 					// 	"apis": ["post/staff", "get/staff"],
	// 					// 	"subgroupLinks": [
	// 					// 		{
	// 					// 			"title": "Search/Edit HR",
	// 					// 			"description": "",
	// 					// 			"endPoint": "/admin/staff/manage",
	// 					// 			"Icon": "fa fa-search",
	// 					// 			"api": "get/staff"
	// 					// 		},
	// 					// 		{
	// 					// 			"title": "Add HR",
	// 					// 			"description": "",
	// 					// 			"endPoint": "/admin/staff/add",
	// 					// 			"Icon": "fa fa-plus",
	// 					// 			"api": "post/staff"
	// 					// 		}
	// 					// 	]
	// 					// },
	// 					{
	// 						"title": "Institute",
	// 						"apis": ["post/auth/institute", "get/auth/institute"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/Edit Insts",
	// 								"description": "",
	// 								"endPoint": "/admin/inst",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/auth/institute"
	// 							},
	// 							{
	// 								"title": "Add Inst",
	// 								"description": "",
	// 								"endPoint": "/admin/inst/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/auth/institute"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Org",
	// 						"apis": ["post/auth/org", "get/auth/org"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/Edit Orgs",
	// 								"description": "",
	// 								"endPoint": "/admin/org",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/auth/org"
	// 							},
	// 							{
	// 								"title": "Add Org",
	// 								"description": "",
	// 								"endPoint": "/admin/org/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/auth/org"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Student",
	// 						"apis": ["get/stu/student", "post/stu/student", "get/stu/student/absenteesRecipientCount"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/Edit student",
	// 								"description": "",
	// 								"endPoint": "/admin/student/manage",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/stu/student"
	// 							},
	// 							{
	// 								"title": "Add Student",
	// 								"description": "",
	// 								"endPoint": "/admin/student/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/stu/student"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Report",
	// 						"apis": ["get/arte/artefact/reports", "post/arte/artefact/upsert"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/Edit report",
	// 								"description": "",
	// 								"endPoint": "/admin/report/manage",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/arte/artefact/reports"
	// 							},
	// 							{
	// 								"title": "Add Report",
	// 								"description": "",
	// 								"endPoint": "/admin/report/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/arte/artefact/upsert"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Widgets",
	// 						"apis": ["get/arte/artefact", "post/arte/artefact/upsert", "post/acad/artefact/StudentAttendanceTemplate"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search widgets",
	// 								"description": "",
	// 								"endPoint": "/admin/widget/manage",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/arte/artefact"
	// 							},
	// 							{
	// 								"title": "Add widget",
	// 								"description": "",
	// 								"endPoint": "/admin/widget/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/arte/artefact/upsert"
	// 							}
	// 						]
	// 					}
	// 				]
	// 			}
	// 		]
	// 	},
	// 	"Admissions": {
	// 		"col1": [],
	// 		"col2": [],
	// 		"col3": [{
	// 			"id": "c1",
	// 			"groupType": "ActionLink",
	// 			"title": "Action Links",
	// 			"subGroup": [
	// 				{
	// 					"title": "Seat Matrix",
	// 					"apis": ["get/admission"],
	// 					"subgroupLinks": [
	// 						{
	// 							"title": "Seat Matrix",
	// 							"description": "view seat matrix",
	// 							"endPoint": "/admissions/seatMatrix/manage",
	// 							"Icon": "fa fa-search",
	// 							"api": "get/admission"
	// 						},
	// 						{
	// 							"title": "Seat Matrix",
	// 							"description": "add seat matrix",
	// 							"endPoint": "/admissions/seatMatrix/add",
	// 							"Icon": "fa fa-plus",
	// 							"api": "get/admission"
	// 						}
	// 					]
	// 				},
	// 				{
	// 					"title": "Applications",
	// 					"apis": ["get/admission"],
	// 					"subgroupLinks": [
	// 						{
	// 							"title": "Search / View Applications",
	// 							"description": "view applications",
	// 							"endPoint": "/admissions/applications/manage",
	// 							"Icon": "fa fa-search",
	// 							"api": "get/admission"
	// 						}
	// 					]
	// 				}, {
	// 					"title": "Shortlists",
	// 					"apis": ["get/admission"],
	// 					"subgroupLinks": [
	// 						{
	// 							"title": "Search / View Shortlisted Applications",
	// 							"description": "view shortlisted applications",
	// 							"endPoint": "/admissions/shortlists/manage",
	// 							"Icon": "fa fa-search",
	// 							"api": "get/admission"
	// 						}
	// 					]
	// 				}
	// 				// 	,
	// 				// {
	// 				// 	"title": "Applicant Manage",
	// 				// 		"apis": ["get/admission/applicant"],
	// 				// 		"subgroupLinks": [
	// 				// 			{
	// 				// 				"title": "Applicant Manage",
	// 				// 				"description": "view shortlisted applications",
	// 				// 				"endPoint": "/admissions/applicantManage",
	// 				// 				"Icon": "fa fa-search",
	// 				// 				"api": "get/admission/applicant"
	// 				// 			}
	// 				// 		]
	// 				// }
	// 			]
	// 		}]
	// 	},
	// 	"Assets": {
	// 		"col1": [
	// 			{
	// 				"id": "a1",
	// 				"groupType": "Report",
	// 				"title": "Assets",
	// 				"groupLinks":
	// 					[
	// 						{
	// 							"title": "Location wise assets report",
	// 							"description": "view location wise reports for assets",
	// 							"endPoint": "/assets/locationwiseReport"
	// 						}
	// 					]
	// 			}
	// 		],
	// 		"col2": [],
	// 		"col3": [
	// 			{
	// 				"id": "c1",
	// 				"groupType": "ActionLink",
	// 				"title": "Action Links",
	// 				"subGroup":
	// 					[
	// 						{
	// 							"title": "Asset",
	// 							"apis": ["post/asset", "get/asset"],
	// 							"subgroupLinks":
	// 								[
	// 									{
	// 										"title": "Search / Edit asset",
	// 										"description": "",
	// 										"endPoint": "/assets/manage",
	// 										"Icon": "fa fa-search",
	// 										"api": "get/asset"
	// 									},
	// 									{
	// 										"title": "Add asset",
	// 										"description": "",
	// 										"endPoint": "/assets/add",
	// 										"Icon": "fa fa-plus",
	// 										"api": "post/asset"
	// 									}
	// 								]
	// 						},
	// 						{
	// 							"title": "Checklist",
	// 							"apis": [],
	// 							"subgroupLinks": [
	// 								{
	// 									"title": "Search/Edit checklist",
	// 									"description": "",
	// 									"endPoint": "/checklist/manage",
	// 									"Icon": "fa fa-search",
	// 									"api": ""
	// 								},
	// 								{
	// 									"title": "Add checklist",
	// 									"description": "",
	// 									"endPoint": "/checklist/add",
	// 									"Icon": "fa fa-plus",
	// 									"api": ""
	// 								}
	// 							]
	// 						}
	// 					]
	// 			}
	// 		]
	// 	},

	// 	"Connect": {
	// 		"col1": [
	// 			{
	// 				"id": "a1",
	// 				"groupType": "Report",
	// 				"title": "Connect",
	// 				"groupLinks": [
	// 					{
	// 						"title": "SMS usage report",
	// 						"description": "view the report for SMS usage",
	// 						"endPoint": "/connect/smsUsageReport"
	// 					}
	// 				]
	// 			}
	// 		],
	// 		"col2": [
	// 			{
	// 				"id": "b1",
	// 				"groupType": "Report",
	// 				"title": "Exception Reports",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Invalid / Blocked emails report",
	// 						"description": "view the report for invalid Email IDs",
	// 						"endPoint": "/connect/invalidEmailsReport"
	// 					},
	// 					{
	// 						"title": "Invalid / Blocked mobiles report",
	// 						"description": "view the report for invalid mobile numbers",
	// 						"endPoint": "/connect/invalidMobilesReport"
	// 					}
	// 				]
	// 			}],
	// 		"col3": [
	// 			{
	// 				"id": "c1",
	// 				"groupType": "ActionLink",
	// 				"title": "Action Links",
	// 				"api": ["post/connect/lookup"],
	// 				"subGroup": [
	// 					{
	// 						"title": "Contact",
	// 						"apis": "",
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Lookup Contacts",
	// 								"description": "view the contacts",
	// 								"endPoint": "/connect/contactLookup",
	// 								"Icon": "fa fa-search",
	// 								"api": "post/connect/lookup"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Email",
	// 						"apis": ["post/connect/email", "get/connect/email"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/ View sent Emails",
	// 								"description": "search/ view sent emails",
	// 								"endPoint": "/connect/manageEmail",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/connect/email"
	// 							},
	// 							{
	// 								"title": "Send new Email",
	// 								"description": "send new email",
	// 								"endPoint": "/connect/newEmail",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/connect/email"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Notification",
	// 						"apis": ["post/connect/sms", "get/connect/sms"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search Notification",
	// 								"description": "search / view Notifications",
	// 								"endPoint": "/connect/notifications",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/connect/sms"
	// 							},
	// 							{
	// 								"title": "Add Notification",
	// 								"description": "search / view Notifications",
	// 								"endPoint": "/connect/notifications",
	// 								"Icon": "",
	// 								"api": ""
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Meeting",
	// 						"apis": "",
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/ View Meetings",
	// 								"description": "search/ view meetings",
	// 								"endPoint": "/connect/msTeams/meetings",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/connect/email"
	// 							},
	// 							{
	// 								"title": "Schedule Meeting",
	// 								"description": "schedule meeting",
	// 								"endPoint": "/connect/msTeams/scheduleMeeting",
	// 								"Icon": "fa fa-clock-o",
	// 								"api": "post/connect/email"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "SMS",
	// 						"apis": ["post/connect/sms", "get/connect/sms"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/ View sent SMS",
	// 								"description": "search/ view sent SMS",
	// 								"endPoint": "/connect/manageSMS",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/connect/sms"
	// 							},
	// 							{
	// 								"title": "Send new SMS",
	// 								"description": "send new SMS",
	// 								"endPoint": "/connect/newSMS",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/connect/sms"
	// 							},
	// 							{
	// 								"title": "Manage SMS Templates",
	// 								"description": "Manage SMS Templates",
	// 								"endPoint": "/connect/manageSMSTemplate",
	// 								"Icon": "fa fa-edit",
	// 								"Style": "text-align: center;",
	// 								"api": "get/connect/SMSTemplate"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Subscriptions",
	// 						"apis": ["get/acad/lms/questions"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/ View Subscriptions",
	// 								"description": "search/ view subscriptions",
	// 								"endPoint": "/connect/manageSubscriptions",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/acad/lms/questions"
	// 							},
	// 							{
	// 								"title": "",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "",
	// 								"api": ""
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Publish-Topic",
	// 						"apis": ["get/acad/lms/questions"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/ View Topic",
	// 								"description": "search/ view topic",
	// 								"endPoint": "/connect/manageTopics",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/acad/lms/questions"
	// 							},
	// 							{
	// 								"title": "Add new Topic",
	// 								"description": "add new topic",
	// 								"endPoint": "/connect/addTopic",
	// 								"Icon": "",
	// 								"api": "post/acad/lms/questions"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Website",
	// 						"apis": "",
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/View TFTD",
	// 								"description": "search/view TFTD",
	// 								"endPoint": "/connect/website",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/connect/website"
	// 							}
	// 						]
	// 					}]
	// 			}]
	// 	},
	// 	"Fees": {
	// 		"col1": [
	// 			{
	// 				"id": "a1",
	// 				"groupType": "Report",
	// 				"title": "Fees Setup",
	// 				"groupLinks": [
	// 					// {
	// 					// 	"title": "Concession (WIP)",
	// 					// 	"description": "view the Concession for the academic year",
	// 					// 	"endPoint": "/fees/feesConcessionReport"
	// 					// },
	// 					// {
	// 					// 	"title": "Fee Breakups",
	// 					// 	"description": "view the Fee Breakups for the academic year",
	// 					// 	"endPoint": "/fees/feesBreakupsReport"
	// 					// },
	// 					{
	// 						"title": "Fees Structure",
	// 						"description": "view the fee structure for the academic year",
	// 						"endPoint": "/fees/feesStructureReport"
	// 					},
	// 					{
	// 						"title": "Fees Expected",
	// 						"description": "view the fees expected for the academic year",
	// 						"endPoint": "/fees/feesExpectedReport"
	// 					}
	// 					// {
	// 					// 	"title": "Fees Time Line",
	// 					// 	"description": "view the Fees Time Line for the academic year",
	// 					// 	"endPoint": "/fees/feesTimeLineReport"
	// 					// }
	// 				]
	// 			},
	// 			{
	// 				"id": "a2",
	// 				"groupType": "Report",
	// 				"title": "Fees Exception Reports",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Defaulters List",
	// 						"description": "view the defaulters list for the academic year",
	// 						"endPoint": "/fees/feesDefaultersListReport"
	// 					}
	// 				]
	// 			}
	// 		],
	// 		"col2": [{
	// 			"id": "b1",
	// 			"groupType": "Report",
	// 			"title": "Fees Transaction",
	// 			"groupLinks": [
	// 				{
	// 					"title": "Adjustment Balance",
	// 					"description": "view the adjustment balance",
	// 					"endPoint": "/fees/adjustmentBalanceReport"
	// 				},
	// 				{
	// 					"title": "Adjustment Transaction",
	// 					"description": "view the adjustment transactions",
	// 					"endPoint": "/fees/adjustmentTransactionReport"
	// 				},
	// 				{
	// 					"title": "Advance Balance",
	// 					"description": "view the advance balance as on March 31st",
	// 					"endPoint": "/fees/advanceBalanceReport"
	// 				},
	// 				{
	// 					"title": "Fee Collection Datewise / Monthwise",
	// 					"description": "view the datewise / monthwise fee collection",
	// 					"endPoint": "/fees/feesCollectedReport"
	// 				},
	// 				{
	// 					"title": "Fees Paid List",
	// 					"description": "view the fees paid by students for the academic year",
	// 					"endPoint": "/fees/feesPaidReport"
	// 				},
	// 				// {
	// 				// 	"title": "Fees Expected",
	// 				// 	"description": "view the fees expected for the academic year",
	// 				// 	"endPoint": "/fees/feesExpectedReport"
	// 				// },
	// 				// {
	// 				// 	"title": "Fine Collected (WIP)",
	// 				// 	"description": "view the fine collected for the academic year",
	// 				// 	"endPoint": "/fees/feesFineCollectedReport"
	// 				// },
	// 				// {
	// 				// 	"title": "Late Payments (WIP)",
	// 				// 	"description": "view the late payments for the academic year",
	// 				// 	"endPoint": "/fees/feesLatePaymentsReport"
	// 				// },
	// 				{
	// 					"title": "Receipt Transaction",
	// 					"description": "view the datewise receipt transaction",
	// 					"endPoint": "/fees/feesReceiptTransactionReport"
	// 				},
	// 				{
	// 					"title": "Student Transaction",
	// 					"description": "view the transactions for a student",
	// 					"endPoint": "/fees/studentTransactionReport"
	// 				},
	// 				{
	// 					"title": "Transaction History",
	// 					"description": "view fees transaction history of the institute",
	// 					"endPoint": "/fees/transactionHistoryReport"
	// 				}
	// 			]
	// 		}],
	// 		"col3": [
	// 			{
	// 				"id": "c1",
	// 				"groupType": "ActionLink",
	// 				"title": "Action Links",
	// 				"subGroup": [
	// 					{
	// 						"title": "Fees",
	// 						"apis": ["post/fee", "get/fee"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/Edit Fee",
	// 								"description": "",
	// 								"endPoint": "/fees/manage",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/fee"
	// 							},
	// 							{
	// 								"title": "Add fee",
	// 								"description": "",
	// 								"endPoint": "/fees/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/fee"
	// 							}
	// 						]
	// 					}, {
	// 						"title": "Transact",
	// 						"apis": ["post/fee/transact", "get/fee/transact"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/View transaction",
	// 								"description": "",
	// 								"endPoint": "/fees/transactions",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/fee/transact"
	// 							},
	// 							{
	// 								"title": "New transaction",
	// 								"description": "",
	// 								"endPoint": "/fees/transactions/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/fee/transact"
	// 							}
	// 						]
	// 					}]
	// 			}, {
	// 				"id": "c2",
	// 				"groupType": "ActionLink",
	// 				"title": "Notify",
	// 				"subGroup": [
	// 					{
	// 						"title": "Defaulters List",
	// 						"apis": [],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-mobile fa-lg",
	// 								"api": ""
	// 							},
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-envelope-o fa-lg",
	// 								"api": ""
	// 							}, {
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-bell-o fa-lg",
	// 								"api": ""
	// 							}
	// 						]
	// 					}]
	// 			}]
	// 	},
	// 	"LMS": {
	// 		"col1": [
	// 		],
	// 		"col2": [
	// 		],
	// 		"col3": [
	// 			{
	// 				"id": "c1",
	// 				"groupType": "ActionLink",
	// 				"title": "LMS",
	// 				"subGroup": [
	// 					{
	// 						"title": "Content",
	// 						"apis": ["get/acad/lms/questions"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Manage/Edit Content",
	// 								"description": "",
	// 								"endPoint": "/lms/curriculum",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/acad/lms/questions"
	// 							},
	// 							{
	// 								"title": "Add Content",
	// 								"description": "",
	// 								"endPoint": "/lms/curriculum/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "get/acad/lms/question"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "QA",
	// 						"apis": ["get/acad/lms/questions"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Manage/Edit Questions",
	// 								"description": "",
	// 								"endPoint": "/lms/qa",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/acad/lms/questions"
	// 							},
	// 							{
	// 								"title": "Add Question",
	// 								"description": "",
	// 								"endPoint": "/lms/qa/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "get/acad/lms/question"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Assessment Setup",
	// 						"apis": ["get/acad/assessment"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Manage/Edit Assessment",
	// 								"description": "",
	// 								"endPoint": "/lms/assessment/manage",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/acad/assessment"
	// 							},
	// 							{
	// 								"title": "Add Assessment",
	// 								"description": "",
	// 								"endPoint": "/lms/assessment/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "get/acad/assessment"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Assessments",
	// 						"apis": ["get/acad/assessment"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Take Assessment",
	// 								"description": "",
	// 								"endPoint": "/lms/assessment/taking",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/acad/assessment"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Study",
	// 						"apis": ["get/acad/assessment"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Study",
	// 								"description": "",
	// 								"endPoint": "/lms/curriculum/study",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/acad/assessment"
	// 							}
	// 						]
	// 					}
	// 				]
	// 			}
	// 		]
	// 	},
	// 	"Vault": {
	// 		"col1": [
	// 		],
	// 		"col2": [
	// 		],
	// 		"col3": [
	// 			{
	// 				"id": "c1",
	// 				"groupType": "ActionLink",
	// 				"title": "Vault",
	// 				"subGroup": [
	// 					{
	// 						"title": "Artefact",
	// 						"apis": ["get/arte/artefact", "post/arte/artefact/upsert", "post/acad/artefact/StudentAttendanceTemplate"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search artefacts",
	// 								"description": "",
	// 								"endPoint": "/vault/artefact/manage",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/arte/artefact"
	// 							},
	// 							{
	// 								"title": "Upload artefact",
	// 								"description": "",
	// 								"endPoint": "/vault/artefact/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/arte/artefact/upsert"
	// 							},
	// 							{
	// 								"title": "Download published",
	// 								"description": "",
	// 								"endPoint": "/vault/artefact/download",
	// 								"Icon": "fa fa-download",
	// 								"api": "post/acad/artefact/StudentAttendanceTemplate"
	// 							}
	// 						]
	// 					}
	// 				]
	// 			}
	// 		]
	// 	},
	// 	"HR": {
	// 		"col1": [
	// 		],
	// 		"col2": [
	// 		],
	// 		"col3": [
	// 			{
	// 				"id": "c1",
	// 				"groupType": "ActionLink",
	// 				"title": "Vault",
	// 				"subGroup": [
	// 					{
	// 						"title": "HR",
	// 						"apis": ["post/staff", "get/staff"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/Edit HR",
	// 								"description": "",
	// 								"endPoint": "/hr/staff/manage",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/staff"
	// 							},
	// 							{
	// 								"title": "Add HR",
	// 								"description": "",
	// 								"endPoint": "/hr/staff/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/staff"
	// 							}
	// 						]
	// 					}
	// 				]
	// 			}
	// 		]
	// 	},
	// 	"Library": {
	// 		"col1": [
	// 			{
	// 				"id": "a1",
	// 				"groupType": "Report",
	// 				"title": "Procurement (WIP)",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Vendor management ",
	// 						"description": "manage vendors",
	// 						"endPoint": "/library/vendorManagement",
	// 						"WIP": true
	// 					},
	// 					{
	// 						"title": "Subscription management",
	// 						"description": "manage subscriptions",
	// 						"endPoint": "/library/subscriptionManagement",
	// 						"WIP": true
	// 					}
	// 				]
	// 			},
	// 			{
	// 				"id": "a2",
	// 				"groupType": "Report",
	// 				"title": "Catalog",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Print barcodes",
	// 						"description": "print barcodes for materials",
	// 						"endPoint": "/library/barcode",
	// 						"WIP": false
	// 					},
	// 					{
	// 						"title": "Summary report",
	// 						"description": "view the summary report for library",
	// 						"endPoint": "/library/summaryReport",
	// 						"WIP": false
	// 					},
	// 					{
	// 						"title": "Members report",
	// 						"description": "view the report of library members",
	// 						"endPoint": "/library/membersReport",
	// 						"WIP": true
	// 					},
	// 					{
	// 						"title": "Reference materials report",
	// 						"description": "view the report for reference materials",
	// 						"endPoint": "/library/referenceMaterialsReport",
	// 						"WIP": true
	// 					}
	// 				]
	// 			},
	// 			{
	// 				"id": "a3",
	// 				"groupType": "Report",
	// 				"title": "House-keeping",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Materials under maintenance report",
	// 						"description": "view the report for materials under maintenance",
	// 						"endPoint": "/library/maintenanceReport",
	// 						"WIP": false
	// 					},
	// 					{
	// 						"title": "Materials due for maintenance",
	// 						"description": "view the report for materials which are due for maintenance",
	// 						"endPoint": "/library/maintenanceDueReport",
	// 						"WIP": true
	// 					},
	// 					{
	// 						"title": "Last monitored report",
	// 						"description": "view the last monitored report for materials",
	// 						"endPoint": "/library/lastMonitoredReport",
	// 						"WIP": true
	// 					},
	// 					{
	// 						"title": "Lost materials report",
	// 						"description": "view the report for materials which are lost",
	// 						"endPoint": "/library/lostMaterialsReport",
	// 						"WIP": true
	// 					}
	// 				]
	// 			}

	// 		],
	// 		"col2": [
	// 			{
	// 				"id": "b1",
	// 				"groupType": "Report",
	// 				"title": "Circulation",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Materials under circulation report",
	// 						"description": "view the report for materials in circulation",
	// 						"endPoint": "/library/circulationReport",
	// 						"WIP": false
	// 					},
	// 					{
	// 						"title": "Over-due report",
	// 						"description": "view the report for materials in circulation which are over-due",
	// 						"endPoint": "/library/overDueReport",
	// 						"WIP": true
	// 					}
	// 				]
	// 			},
	// 			{
	// 				"id": "b2",
	// 				"groupType": "Report",
	// 				"title": "Reports",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Age analysis",
	// 						"description": "view the report for age of the materials",
	// 						"endPoint": "/library/ageAnalysisReport",
	// 						"WIP": true
	// 					},
	// 					{
	// 						"title": "Valuation report",
	// 						"description": "view the report for materials Valuation",
	// 						"endPoint": "/library/valuationReport",
	// 						"WIP": true
	// 					},
	// 					{
	// 						"title": "Late fees report",
	// 						"description": "view the report for late payment of library fees",
	// 						"endPoint": "/library/lateFeesReport",
	// 						"WIP": true
	// 					},
	// 					{
	// 						"title": "Books without ISBN",
	// 						"description": "view the report for books without ISBN",
	// 						"endPoint": "/library/booksWithoutISBNReport",
	// 						"WIP": false
	// 					},
	// 					{
	// 						"title": "Books without synopsis",
	// 						"description": "view the report for books without synopsis",
	// 						"endPoint": "/library/booksWithoutSynopsisReport",
	// 						"WIP": true
	// 					},
	// 					{
	// 						"title": "Books without cover photo",
	// 						"description": "view the report for books without cover photo",
	// 						"endPoint": "/library/booksWithoutCoverPhotoReport",
	// 						"WIP": true
	// 					},
	// 					{
	// 						"title": "Unverified stock",
	// 						"description": "view the report of unverified materials",
	// 						"endPoint": "/library/unverifiedStockReport",
	// 						"WIP": false
	// 					}
	// 				]
	// 			}
	// 		],
	// 		"col3": [
	// 			{
	// 				"id": "c1",
	// 				"groupType": "ActionLink",
	// 				"title": "Action Links",
	// 				"subGroup": [
	// 					{
	// 						"title": "Classification",
	// 						"apis": [],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-barcode fa-lg",
	// 								"api": ""
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Catalog",
	// 						"apis": ["post/library/material/add", "get/library/material"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search/Edit library material",
	// 								"description": "",
	// 								"endPoint": "/library/manage",
	// 								"Icon": "fa fa-search",
	// 								"api": "get/library/material"
	// 							},
	// 							{
	// 								"title": "Add library material",
	// 								"description": "",
	// 								"endPoint": "/library/add",
	// 								"Icon": "fa fa-plus",
	// 								"api": "post/library/material/add"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Circulation",
	// 						"apis": ["post/library/material/issue", "post/library/material/return"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Issue/Return",
	// 								"description": "",
	// 								"endPoint": "/library/circulation",
	// 								"Icon": "fa fa-retweet fa-lg",
	// 								"api": "post/library/material/issue"
	// 							},
	// 							{
	// 								"title": "SettMigration in Processings",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-cog fa-fw fa-lg",
	// 								"api": ""
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "House-keeping",
	// 						"apis": [],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-wrench fa-lg",
	// 								"api": ""
	// 							},
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-trash fa-lg",
	// 								"api": ""
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Stock Verification",
	// 						"apis": ["post/library/material/verifyStock"],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Verify Stock",
	// 								"description": "",
	// 								"endPoint": "/library/stockVerification",
	// 								"Icon": "fa fa-search",
	// 								"api": "post/library/material/verifyStock"
	// 							}
	// 						]
	// 					}
	// 				]
	// 			},
	// 			{
	// 				"groupType": "ActionLink",
	// 				"title": "Notify",
	// 				"groupLinks": [],
	// 				"subGroup": [
	// 					{
	// 						"title": "Defaulters",
	// 						"apis": [],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-mobile fa-lg",
	// 								"api": ""
	// 							},
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-envelope-o fa-lg",
	// 								"api": ""
	// 							},
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-bell-o fa-lg",
	// 								"api": ""
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Reminders",
	// 						"apis": [],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-mobile fa-lg",
	// 								"api": ""
	// 							},
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-envelope-o fa-lg",
	// 								"api": ""
	// 							},
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-bell-o fa-lg",
	// 								"api": ""
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Over Due",
	// 						"apis": [],
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-mobile fa-lg",
	// 								"api": ""
	// 							},
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-envelope-o fa-lg",
	// 								"api": ""
	// 							},
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-bell-o fa-lg",
	// 								"api": ""
	// 							}
	// 						]
	// 					}
	// 				]
	// 			}
	// 		]
	// 	},
	// 	"RC": {
	// 		"col1": [
	// 			{
	// 				"id": "a1",
	// 				"groupType": "Report",
	// 				"title": "Reports",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Admission List (WIP)",
	// 						"description": "view report for admission list for academic year",
	// 						"endPoint": "/regulatoryCompliance/admissionReport"
	// 					},
	// 					{
	// 						"title": "Department report",
	// 						"description": "view report for total marks and grade secured by each student for academic year",
	// 						"endPoint": "/regulatoryCompliance/departmentReport"
	// 					},
	// 				]
	// 			},
	// 			{
	// 				"id": "a2",
	// 				"groupType": "Report",
	// 				"title": "NIRF Reports",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Annual capital expenditure",
	// 						"description": "view report for annual capital expenditure",
	// 						"endPoint": "/regulatoryCompliance/capitalExpenditure"
	// 					},
	// 					{
	// 						"title": "Annual operational expenditure",
	// 						"description": "view report for annual operational expenditure",
	// 						"endPoint": "/regulatoryCompliance/operationalExpenditure"
	// 					},
	// 					{
	// 						"title": "Faculty details",
	// 						"description": "view report for faculty details",
	// 						"endPoint": "/regulatoryCompliance/facultyDetails"
	// 					},
	// 					{
	// 						"title": "Financial resources",
	// 						"description": "view report for utilised amount for the capital",
	// 						"endPoint": "/regulatoryCompliance/financialResources"
	// 					},
	// 					{
	// 						"title": "PCS facilities: ",
	// 						"description": "view report for sanctioned or approved intake",
	// 						"endPoint": "/regulatoryCompliance/pcsFacilities"
	// 					},
	// 					{
	// 						"title": "Placement and higher studies",
	// 						"description": "view report for details about placement and higher studies",
	// 						"endPoint": "/regulatoryCompliance/placement"
	// 					},
	// 					{
	// 						"title": "Sanctioned (Approved) intake",
	// 						"description": "view report for sanctioned or approved intake",
	// 						"endPoint": "/regulatoryCompliance/sanctionedIntake"
	// 					},
	// 					{
	// 						"title": "Student strength",
	// 						"description": "view report for total of actual student strength",
	// 						"endPoint": "/regulatoryCompliance/studentStrength"
	// 					}
	// 				]
	// 			}
	// 		],
	// 		"col2": [],
	// 		"col3": [
	// 			{
	// 				"id": "c1",
	// 				"groupType": "ActionLink",
	// 				"title": "Action Links",
	// 				"subGroup": [
	// 					{
	// 						"title": "TC Book",
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-search"
	// 							}
	// 						]
	// 					},
	// 					{
	// 						"title": "Fee Receipts",
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Migration in Process",
	// 								"description": "",
	// 								"endPoint": "",
	// 								"Icon": "fa fa-search"
	// 							}
	// 						]
	// 					}
	// 				]
	// 			}]
	// 	},
	// 	"Receipts": {
	// 		"col1": [
	// 			{
	// 				"id": "a1",
	// 				"groupType": "Report",
	// 				"title": "Receipts",
	// 				"groupLinks": [
	// 					{
	// 						"title": "Detailed report",
	// 						"description": "view the details report of receipts within the academic year",
	// 						"endPoint": "/receipts/detailedReport"
	// 					},
	// 					{
	// 						"title": "Revenue sharing report",
	// 						"description": "view the report for revenue sharing within the academic year",
	// 						"endPoint": "/receipts/revenueSharingReport"
	// 					},
	// 					{
	// 						"title": "Summary report",
	// 						"description": "view the summary report for receipts within the academic year",
	// 						"endPoint": "/receipts/receiptsSummaryReport"
	// 					}
	// 				]
	// 			}
	// 		],
	// 		"col2": [],
	// 		"col3": [
	// 			{
	// 				"id": "c1",
	// 				"groupType": "ActionLink",
	// 				"title": "Action Links",
	// 				"subGroup": [
	// 					{
	// 						"title": "Receipts",
	// 						"subgroupLinks": [
	// 							{
	// 								"title": "Search / Edit Receipts",
	// 								"description": "Search / Edit Receipts",
	// 								"endPoint": "/receipts/manage",
	// 								"Icon": "fa fa-search"
	// 							},
	// 							{
	// 								"title": "Add / Create Receipt",
	// 								"description": "Add / Create Receipt",
	// 								"endPoint": "/receipts/add",
	// 								"Icon": "fa fa-plus"
	// 							}
	// 						]
	// 					}
	// 				]
	// 			}]
	// 	}
	// }
	userLinks: any;
	readonly dexPages = Object.values(DEX_CONTEXTS);
	readonly quickLinksForPublish = QUICKLINKS_FOR_PUBLISH;
}

declare const gapi: any;
declare var $: any;

@Component({
	selector: 'header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
	providers: [clipboardService]
})

export class HeaderComponent implements OnInit, AfterViewChecked {
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		this.closePopovers();
		event.stopPropagation();
		event.preventDefault();
	}

	@ViewChild('pop') pop: PopoverDirective;
	@ViewChild('chatBot') chatBot: PopoverDirective;
	@ViewChild('notify') notification: PopoverDirective;
	@ViewChild('releaseInfoBtn') releaseInfo: PopoverDirective;

	@ViewChild("registrationWarningModal") private contentRef: TemplateRef<any>;
	@ViewChild("template") private contentRef1: TemplateRef<any>;
	@ViewChild("ctDashToken") private ctDashToken: TemplateRef<any>;
	@ViewChild('chatMsgs') scrollFrame: ElementRef;

	classicMode: boolean = false;

	public auth2: any;
	public m: Model;
	public mobile: boolean = false;
	public loginForm: FormGroup;
	loginControl = new FormControl();
	private sub_user_status: Subscription;
	private sub_selected_persona: Subscription;
	private sub_clipboard: Subscription;
	subs: Subscription;
	modalRef: BsModalRef;
	innerModalRef: BsModalRef;
	counter: number;
	countDown: Subscription;
	tick: number = 1000;
	toggle: string = "false";
	html: string = `<ul style="padding-left:240px;">
	<li *ngFor="let m of m.Notifications"><span
			style="opacity:0.6;font-size:0.9em;">{{m.time | date:'hh:mm:ss'}}</span><span
			style="padding-left:10px;">{{m.message}}</span></li>
	</ul>`;
	eventsSubscribedByTopics = [];
	topicsMap = {};
	readonly ctLogoUrl: string = 'assets/img/ct-logo-white.svg';

	constructor(
		public ctapi: CTApi,
		private store: Store<rootReducer.State>,
		private modalService: BsModalService,
		private _ngZone: NgZone,
		private router: Router,
		public pulseService: PulseService,
		public clipboardService: clipboardService,
		public http: Http,
		public appService: AppService
	) { }
	ngOnInit() {
		this.init_model();
		this.init_store();
		this.sub_store();
		gapi.load('auth2', () => { this.auth2 = gapi.auth2.init({ client_id: '47075676153-u72dnkjhvod0vahrgts92tp54qnctd6i.apps.googleusercontent.com' }); });
		if (performance.navigation.type == 0 || performance.navigation.type == 1) {
			if (sessionStorage.accessToken && sessionStorage.ctContext) {
				let accessToken = sessionStorage.accessToken;
				let ctContext = JSON.parse(sessionStorage.ctContext);
				this.m.ctContext = ctContext;
				this.store.dispatch(new appAction.SetAccessToken({ "accessToken": accessToken }));
				this.store.dispatch(new appAction.SetAccessCode());
				if (ctContext.notification) this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": true, "ctContext": ctContext }));
				else this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": false, "ctContext": ctContext }));
			}
		}
		this.router.events.subscribe((res) => {
			if ((window.screen.width < 500) && this.router.url == '/mobilePrivacyPolicy') { this.mobile = true; }
			else { this.mobile = false; }
		})
	}

	ngAfterViewChecked() {
		this.scrollToBottom();
	}


	scrollToBottom(): void {
		try {
			this.scrollFrame.nativeElement.scrollTop = this.scrollFrame.nativeElement.scrollHeight;
		} catch (err) { }
	}

	ngOnDestroy() {
		this.sub_user_status.unsubscribe();
		this.sub_selected_persona.unsubscribe();
		this.sub_clipboard.unsubscribe();
		this.subs.unsubscribe();
	}
	init_model() {
		this.m = new Model();
		this.m.iconURL = environment.ICON_IMAGE_URL;

		this.ctapi.getProtocols().subscribe((res: any) => {
			var resp = JSON.parse(res._body);
		});

		this.m.userLinks = Data.userLinks;
		this.m.quickLinksForPublish.forEach((link) => {
			if (link.click) {
				link.click = (modalRef) => {
					this.openInnerModal(modalRef);
				}
			}
		});

		this.getSubdomain();
	}
	init_store() {
		this.m.releaseVersion = environment.RELEASE_VERSION;
		this.m.releaseDate = environment.RELEASE_DATE;
		this.store.dispatch(new notificationAction.GetUnreadNotifications(''));
		this.pulseService.getCalendarEvents();
	}
	sub_store() {
		this.store.select(rootReducer.get_notifications).subscribe(res => {
			if (res && res.length > 0) {
				this.m.Notifications = res;
				this.m.unreadNotifs = true;
				if (this.m.Notifications[0].status == 'success') {
					this.m.errorNotif = false;
					this.m.unreadNotifs = true;
				} else {
					this.m.unreadNotifs = false;
					this.m.errorNotif = true;
				}
			}
		});
		this.store.select(rootReducer.get_selected_nb_display).subscribe(res => {
			if (res) {
				this.m.currentNBDisplay = res;
				this.appService.currentNBDisplay = res;
			}
		}
		);
		this.sub_user_status = this.store.select(rootReducer.get_user_status).subscribe(
			res => {
				this._ngZone.run(() => {
					this.m.userLoggedIn = res || false;
				});
			},
			err => console.log(err)
		);
		this.sub_selected_persona = this.store.select(rootReducer.get_selected_persona).subscribe(
			res => {
				let res_is_null = res ? Object.keys(res).length === 0 && res.constructor === Object : true;
				if (!res_is_null && this.m.ctContext) {
					if (res.id != this.m.ctContext.currentPersona.id) {
						if (res.clientShortName == 'BTLCOL' || res.clientShortName == 'PPECPS') this.router.navigate(['/cards']);
						else this.router.navigate(['/home']);
						this.m.personaChanging = true;
						this.ctapi.setCurrentPersona(this.m.ctContext.userId, res.id)
							.subscribe(data => {
								if (data) {
									let access_token = data.headers.get('Authorization');
									let ct_context = data.headers.get('CT-Context');
									let ct_context_json = JSON.parse(ct_context);
									ct_context_json.currentPersona.Routes = ["home", "home/userProfile", "admin", "admin/org", "admin/inst", "academics", "assets", "fees", "fees/manage", "fees/transactions", "library", "library/circulation", "library/barcode", "connect", "receipts", "regulatoryCompliance"];
									ct_context_json.currentPersona.navPerms = this.getNavPerms(ct_context_json.currentPersona.Routes);
									this.m.ctContext = ct_context_json;
									this.store.dispatch(new appAction.SetAccessToken({ "accessToken": access_token }));
									this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": true, "ctContext": ct_context_json }));
									this.m.personaChanging = false;
									// ct_context_json.scope.forEach((sc: string) => {
									// 	if (sc && sc.includes("stu/student") && this.m.ctContext.currentPersona.title != 'Student')
									// 		this.ctapi.stuCache().subscribe(() => { });
									// });
									ct_context_json.scope.forEach((sc: string) => {
										if (sc && sc.includes("acad/course"))
											this.ctapi.courseCache().subscribe(() => { });
									});
									ct_context_json.scope.forEach((sc: string) => {
										if (sc && sc.includes("staff"))
											this.ctapi.staffCache().subscribe(() => { });
									});
									ct_context_json.scope.forEach((sc: string) => {
										if (sc && sc.includes("auth/user")) {
											let postData: any = ["sms_dlr_chart", "sms_dlr_json", "sms_dlr_exception"];
											if (ct_context_json.currentPersona.title != 'Student') this.ctapi.userDashboard(postData).subscribe(() => { });
										}
									});
									this.store.dispatch(new googleMeetAction.SetMeetings({ "meetings": [] }));
								}
							},
								err => console.log(err))
					}
				}
			},
			err => console.log(err)
		);

		this.store.select(rootReducer.timer_stopped).subscribe(res => {
			if (res) this.m.timerStopped = res;
		})

		this.subs = this.store.select(rootReducer.get_otp_timer).subscribe(
			res => {
				if (res) {
					this.counter = 180;
					let timestamp = Date.now();
					var seconds = (new Date().getTime() - new Date(timestamp).getTime()) / 1000;
					this.counter = this.counter - seconds;
					this.countDown = timer(0, this.tick).subscribe(() => {
						if (this.counter >= 1) { --this.counter }
						else if (this.counter === 0) {
							this.m.showOTPExpired = true;
						}
					});
				}
				else if (!res) {
					this.counter = 180;
				}
			}
		);
		this.sub_clipboard = this.store.select(rootReducer.get_clipboard).subscribe(res => {
			if (res && res.items && res.items.length) {
				this.m.clipboard = res.items;
			}
		}, err => { console.log("error:", err) });
		this.store.select(rootReducer.get_unread_notifications).subscribe(res => {
			this.m.unreadNotifications = res;
		});
		this.store.select(rootReducer.get_state_app).subscribe(res => {
			if (res) {
				this.m.userName = res.userFirstName + ' ' + res.userLastName;
				this.m.ctContext = res;
			}
		});

		this.store.select(rootReducer.get_notification_list).subscribe(res => {
			if (res && res.length > 0) {
				this.m.notifications = res;
			} else {
				this.m.notifications = [
					{
						id: "",
						ts: "",
						read: false,
						expand: false,
						title: "Online Class",
						content: "IV D - Maths,Kannada,English & EVS(I) - 10:35 am:",
						sender: "BTLCOL", date: new Date(),
						links: [
							{
								href: "https://meet.google.com/ppr-ovkh-kxe",
								text: "https://meet.google.com/ppr-ovkh-kxe"
							}],
						from: "",
						expiry: null,
						status: "",
						type: ""
					},
					{
						id: "",
						ts: "",
						expand: false,
						read: false, title: "Holiday Notice", content: "Classes will be called off on Oct 2 on account of Mahatma Gandhi Jayanti", sender: "BTLCOL", date: new Date(), links: [],
						from: "",
						expiry: null,
						status: "",
						type: ""
					},
					{
						id: "",
						ts: "",
						expand: false,
						read: false, title: "Update Reminder", content: "Greetings from CampusTrack. A new update is available!", sender: "CT", date: new Date(), links: [],
						from: "",
						expiry: null,
						status: "",
						type: ""
					},
					{
						id: "",
						expand: false,
						ts: "",
						read: false, title: "Sept Programme Sheet", content: "Download the September Programme Sheet for KG I", sender: "BTLCOL", date: new Date(), links: [{ href: "", text: "Sept Programme Sheet" }],
						from: "",
						expiry: null,
						status: "",
						type: ""
					},
					{
						id: "",
						expand: false,
						ts: "",
						read: false,
						title: "Topic subscription",
						content: "Subscribe to get notes",
						sender: "BTLCOL",
						date: new Date(),
						links: [],
						from: "@anitha",
						expiry: null,
						status: "Active",
						type: "invitation"
					},
					{
						id: "",
						expand: false,
						ts: "",
						read: false,
						title: "Independance day celebrations",
						content: "Independance day celebrations",
						sender: "BTLCOL",
						date: new Date(),
						links: [],
						from: "@principal",
						expiry: new Date(Date.parse("05-08-2021")),
						status: "Active",
						type: ""
					}
				];
			}
		});

		this.pulseService.calendarEvents.subscribe(res => {
			this.eventsSubscribedByTopics = res.events;
			let events = [];
			this.eventsSubscribedByTopics.forEach((event) => {
				events.push({
					title: event.event_title,
					date: new Date(event.event_date),
					sender: "BTLCOL",
					content: this.topicsMap[event.topic_id] ? `${event.event_type} | ${event.date} | Topic: ${this.topicsMap[event.topic_id]}` : `${event.event_type} | ${event.date}`
				});
			});
			this.m.notifications = events;
		});
		this.store.select(rootReducer.get_topic_list).subscribe(res => {
			if (res) {
				res.forEach((topic) => {
					this.topicsMap[topic.id] = topic.name;
				});
			}
		});

		this.store.select(rootReducer.set_sign_in_page).subscribe(res => {
			if (res) this.m.signInClicked = true;
		})

		this.store.select(rootReducer.reset_password_email_sent).subscribe(res => {
			if (res) this.m.passwordReset = true;
		})
	}

	getSubdomain() {
		let hostName = window.location.hostname;
		if (hostName) {
			let hostNameArr = hostName.split('.');
			this.m.subDomain = hostNameArr[0];
			if (hostName.startsWith("localhost")) this.m.domain = "localhost";
		}
	}

	backToHomePage() {
		this.router.navigate(['/home']);
		this.m.signInClicked = false;
	}

	gLogin() {
		this.m.showInvalidCred = false;
		this.ctapi.authenticate(this.m.email, this.m.password, this.m.token)
			.subscribe(data => {
				if (data.headers.get('Authorization')) {
					this.modalRef.hide();
					let access_token = data.headers.get('Authorization');
					let ct_context = data.headers.get('CT-Context');
					let ct_context_json = JSON.parse(ct_context);
					// let dataCopy = JSON.parse(JSON.stringify(navData));
					// ct_context_json.currentPersona.navPerms = dataCopy.navPerms;
					// ct_context_json.currentPersona.Routes = ["home", "home/userProfile", "admin", "admin/org", "admin/inst", "academics", "assets", "fees", "fees/manage", "fees/transactions", "library", "library/circulation", "library/barcode", "connect", "receipts", "regulatoryCompliance"];
					// ct_context_json.currentPersona.navPerms = this.getNavPerms(ct_context_json.currentPersona.Routes);
					this.m.ctContext = ct_context_json;
					sessionStorage.accessToken = access_token;
					sessionStorage.ctContext = JSON.stringify(ct_context_json);
					this.store.dispatch(new appAction.SetAccessToken({ "accessToken": access_token }));
					this.store.dispatch(new appAction.SetAccessCode());
					if (ct_context_json.notification) this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": true, "ctContext": ct_context_json }));
					else this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": false, "ctContext": ct_context_json }));
					this.store.dispatch(new appAction.SetUserLinks({ "userLinks": this.m.userLinks }));
					// ct_context_json.scope.forEach((sc: string) => {
					// 	if (sc && sc.includes("stu/student") && this.m.ctContext.currentPersona.title != 'Student')
					// 		this.ctapi.stuCache().subscribe(() => { });
					// });
					ct_context_json.scope.forEach((sc: string) => {
						if (sc && sc.includes("acad/course"))
							this.ctapi.courseCache().subscribe(() => { });
					});
					ct_context_json.scope.forEach((sc: string) => {
						if (sc && sc.includes("staff"))
							this.ctapi.staffCache().subscribe(() => { });
					});
					if (['BTLCOL', 'PPECPS'].includes(ct_context_json.currentPersona.clientShortName) || this.m.ctContext.currentPersona.title == 'Applicant') {
						this.router.navigate(['/cards']);
					} else {
						this.router.navigate(['/home']);
					}
				}
			},
				err => { this.m.showInvalidCred = true; })
	}
	login(changePasswordTemplate) {
		this.m.showInvalidCred = false;
		this.ctapi.authenticate(this.m.email, this.m.password, this.m.token)
			.subscribe(data => {
				if (data.headers.get('Authorization')) {
					this.modalRef.hide();
					let access_token = data.headers.get('Authorization');
					let ct_context = data.headers.get('CT-Context');
					let ct_context_json = JSON.parse(ct_context);
					// if(!ct_context_json.currentPersona.NamedScope){ ct_context_json.currentPersona.NamedScope = ["ManageInst","ManagePersonas","Transact"]; }
					// ct_context_json.currentPersona.Routes = ["home", "home/userProfile", "admin", "admin/org", "admin/inst", "academics", "assets", "fees", "fees/manage", "fees/transactions", "library", "library/circulation", "library/barcode", "connect", "receipts", "regulatoryCompliance"];
					// ct_context_json.currentPersona.navPerms = this.getNavPerms(ct_context_json.currentPersona.Routes);
					// let dataCopy = JSON.parse(JSON.stringify(navData));
					// ct_context_json.currentPersona.navPerms = dataCopy.navPerms;
					this.m.ctContext = ct_context_json;
					if (this.m.ctContext.forceChangePassword) {
						this.openModal(changePasswordTemplate);
					}
					sessionStorage.accessToken = access_token;
					sessionStorage.ctContext = JSON.stringify(ct_context_json);
					this.store.dispatch(new appAction.SetAccessToken({ "accessToken": access_token }));
					this.store.dispatch(new appAction.SetAccessCode());
					if (ct_context_json.notification) this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": true, "ctContext": ct_context_json }));
					else this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": false, "ctContext": ct_context_json }));
					this.store.dispatch(new appAction.SetUserLinks({ "userLinks": this.m.userLinks }));
					// ct_context_json.scope.forEach((sc: string) => {
					// 	if (sc && sc.includes("stu/student") && this.m.ctContext.currentPersona.title != 'Student')
					// 		this.ctapi.stuCache().subscribe(() => { });
					// });
					ct_context_json.scope.forEach((sc: string) => {
						if (sc && sc.includes("acad/course"))
							this.ctapi.courseCache().subscribe(() => { });
					});
					ct_context_json.scope.forEach((sc: string) => {
						if (sc && sc.includes("staff"))
							this.ctapi.staffCache().subscribe(() => { });
					});
					if (ct_context_json.currentPersona.clientShortName == 'BTLCOL' || this.m.ctContext.currentPersona.clientShortName == 'PPECPS' || this.m.ctContext.currentPersona.title == 'Applicant') this.router.navigate(['/cards']);
					else this.router.navigate(['/home']);
				}
			},
				err => { this.m.showInvalidCred = true; })
	}
	getPascalCase(str) {
		let result = str.replace(/([A-Z])/g, " $1");
		let pascalStr = result.charAt(0).toUpperCase() + result.slice(1);
		return pascalStr;
	}
	getNavPerms(routes) {
		let sidebar = [];
		routes.forEach(v => {
			var routeArr = v.split('/');
			var so = _.find(sidebar, function (x) { return x.Route == routeArr[0] });
			console.log(so);

			if (so && routeArr[1]) {
				let sObj = { Route: "", DisplayText: "" };
				sObj.Route = v;
				sObj.DisplayText = this.getPascalCase(routeArr[1]);
				so.Sidebar.push(sObj);
			}
			else if (!so) {
				let spObj = { Route: "", DisplayText: "", Sidebar: [] };
				spObj.Route = routeArr[0];
				spObj.DisplayText = this.getPascalCase(routeArr[0]);
				let scObj = { Route: "", DisplayText: "" };
				scObj.Route = routeArr[0];;
				scObj.DisplayText = "Dashboard";
				spObj.Sidebar.push(scObj);
				if (routeArr[1]) {
					let scObj = { Route: "", DisplayText: "" };
					scObj.Route = v;
					scObj.DisplayText = this.getPascalCase(routeArr[1]);
					spObj.Sidebar.push(scObj);
				}
				sidebar.push(spObj);
			}
		});
		return sidebar;
	}

	validateEmail(email) {
		if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) return this.m.disableSubmit = false;
		else this.m.disableSubmit = true;
	}

	validateMobile(mobile) {
		if (mobile.length <= 10) this.m.disableMobileSubmit = false;
		else return this.m.disableMobileSubmit = true;
	}

	logout() {
		let path = this.router.url;
		if (path == '/home') {
			this.ctapi.logout(this.m.ctContext.userEmail).subscribe();
			this.pulseService.disconnectSocket();
			// sessionStorage.accessToken = "";
			// sessionStorage.ctContext = null;
			sessionStorage.clear();
			this.store.dispatch(new appAction.Logout(''));
		} else {
			this.router.navigate([{ outlets: { primary: 'home', sidebar: null } }]).then((res) => {
				if (res) {
					this.ctapi.logout(this.m.ctContext.userEmail).subscribe();
					this.pulseService.disconnectSocket();
					// sessionStorage.accessToken = "";
					// sessionStorage.ctContext = null;
					sessionStorage.clear();
					this.store.dispatch(new appAction.Logout(''));
				}
			});
		}
		this.m.signInClicked = false;
	}

	signIn() {
		this.m.signInClicked = true;
		this.router.navigate(['/home/signin']);
	}

	openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false, class: 'modal-lg' });
	}
	openInnerModal(template: TemplateRef<any>) {
		if (this.innerModalRef) {
			this.innerModalRef.hide();
		}
		this.innerModalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false, class: 'modal-lg' });
	}

	openResetPasswordModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false });
		this.m.timerStopped = false;
		this.m.showTimer = false;
		this.m.recoveryEmail = null;
		this.m.recoveryMobile = null;
	}
	googleInit() {
		let idToken = "";
		this.auth2.signIn()
			.then((d) => {
				for (const property in d) {
					if (typeof d[property] === 'object') {
						Object.keys(d[property]).forEach(key => {
							if (key == "id_token") { idToken = d[property][key] }
						});
					}
				}
				if (idToken) {
					this.m.token = idToken;
					this.gLogin();
				}
			},
				() => { });
	}
	createAccount() {
		if (this.m.userReg.Email.includes("@campustrack.net")) {
			this.modalRef.hide();
			this.openWarningModal();
		}
		else {
			this.ctapi.register(this.m.userReg)
				.subscribe(data => {
					if (data) {
						this.modalRef.hide();
						this.m.signUpSuccess = true;
						this.m.signUpError = false;
						this.m.userReg = { Name: '', Email: '', Password: '' };
					}
				},
					() => {
						this.m.signUpSuccess = false;
						this.m.signUpError = true;
					})
		}
	}
	resetPassword() {
		if (this.m.showErrMsg) this.m.showErrMsg = false;
		if (this.m.resetPassword.Email.includes("@campustrack.net")) {
			this.ctapi.getRecoveryInfo(this.m.resetPassword.Email)
				.subscribe(res => {
					if (res) {
						let resp = JSON.parse(res.body)
						let data = resp.data;
						this.m.recoveryEmail = data.recoveryEmail;
						this.m.recoveryMobile = data.recoveryMobile;
					}
				}, err => {
					this.m.showRecoveryErr = true;
				});
		}
		else {
			this.ctapi.resetPassword(this.m.resetPassword.Email)
				.subscribe(data => {
					if (data) {
						this.modalRef.hide();
						this.m.passwordReset = true;
						this.m.resetPassword = { Email: '' };
					}
				},
					err => {
						this.m.passwordReset = false;
					})
		}
	}
	changePassword() {
		this.m.passwordMismatch = false;
		if (this.m.changePassword.NewPassword == this.m.changePassword.ConfirmPassword) {
			this.ctapi.changePassword(this.m.ctContext.userId, this.m.changePassword.CurrentPassword, this.m.changePassword.NewPassword)
				.subscribe(data => {
					if (data) {
						this.modalRef.hide();
						this.m.ctContext.forceChangePassword = false;
						this.m.passwordMismatch = false;
						this.m.changePassword = { CurrentPassword: '', NewPassword: '', ConfirmPassword: '' };
					}
				},
					() => {
						this.m.passwordReset = false;
					})
		}
		else {
			this.m.passwordMismatch = true;
			this.m.changePassword = { CurrentPassword: '', NewPassword: '', ConfirmPassword: '' };
		}
	}

	ut_viewBrochure() {
		var strWindowFeatures = "resizable=yes,scrollbars=yes,status=yes";
	}
	ut_trial() {
		this.router.navigate(['/home/signup']);
	}

	cancelChangePassword() {
		this.logout();
		this.modalRef.hide();
	}

	openWarningModal() {
		this.modalRef = this.modalService.show(this.contentRef);
	}

	ut_setRecoveryType(recoveryType) {
		this.m.recoveryType = recoveryType;
	}

	ut_closeResetModal() {
		this.modalRef.hide();
		this.m.recoveryType = "email";
		this.m.showRecoveryErr = false;
		this.m.showErrMsg = false;
		this.m.errorMsg = '';
	}

	ut_submitEmail(email) {
		if (this.m.timerStopped) this.store.dispatch(new countdownTimerAction.TimerStopped({ 'timerStopped': false }))
		let payload = { VirtualId: this.m.resetPassword.Email, RecoveryEmail: email, RecoveryMobile: "" };
		this.ctapi.resetPasswordVirtual(payload).subscribe(res => {
			if (res) {
				this.modalRef.hide();
				this.openLoginModal();
			}
		},
			err => {
				let error = JSON.parse(err.error);
				this.m.showErrMsg = true;
				this.m.errorMsg = error.error;
			}
		);
	}

	ut_submitMobile(mobile) {
		if (this.m.timerStopped) this.store.dispatch(new countdownTimerAction.TimerStopped({ 'timerStopped': false }))
		let payload = { VirtualId: this.m.resetPassword.Email, RecoveryEmail: "", RecoveryMobile: mobile };
		this.ctapi.resetPasswordVirtual(payload).subscribe();
		this.modalRef.hide();
		this.openLoginModal();
	}

	openLoginModal() {
		this.modalRef = this.modalService.show(this.contentRef1);
		this.m.showTimer = true;
		// this.start_timer();
		this.store.dispatch(new appAction.SetOTPTimer({ "showTimer": true }));
	}

	ut_closeLoginModal() {
		this.modalRef.hide();
		//this.m.showTimer = false;
		//this.m.timerStopped = false;
		if (this.m.showOTPExpired) this.store.dispatch(new appAction.SetOTPTimer({ "showTimer": false }));
	}

	ut_getDashboardToken() {
		this.ctapi.getDashboardToken().subscribe(res => {
			let headers = {
				"AccessCode": environment.CT_DASHBOARD_ACCESS_CODE,
				"Authorization": res.headers.get('Authorization')
			};
			this.m.ctDashboardHeaders = JSON.stringify(headers);
			this.modalRef = this.modalService.show(this.ctDashToken);
		});
	}

	ut_copyHeaders() {
		var copy_text = this.m.ctDashboardHeaders;
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = copy_text;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		// setTimeout(() => {
		//     this.modalRef.hide();
		// }, 2000);
	}

	ut_notifications() {
		this.router.navigate(['/connect/notifications']);
	}
	showClipboard() {
		this.m.showMsg = false;
		this.m.showClipboard = !this.m.showClipboard;
	}
	showRss() {
		this.m.showClipboard = false;
		this.m.showMsg = !this.m.showMsg;
		this.m.unreadNotifs = false;
		this.m.errorNotif = false;

		if (this.toggle == "false") this.toggle = "true";
		else this.toggle = "false";
	}
	removeItem(item, indx) {
		if (item && item.id) {
			this.m.clipboard.splice(indx, 1);
			this.clipboardService.remove(item.id);
			if (item.selected) {
				this.clipboardService.unselect(item);
			}
		}
	}
	setSelItem(item) {
		if (item.selected) {
			this.m.clipboard.forEach(i => {
				if (i.selected) {
					i.selected = false;
					this.clipboardService.unselect(i);
				}
			});
			item.selected = true;
			this.clipboardService.select(item);
		}
		else {
			this.clipboardService.unselect(item);
		}
	}

	display() {
		this.m.currentNBDisplay.hideNavBar = true;
		this.m.currentNBDisplay.type = 'classic';
		this.store.dispatch(new appAction.SetNBDisplay(this.m.currentNBDisplay));
	}

	setNBMode() {
		this.classicMode = !this.classicMode;
		if (this.classicMode) {
			this.store.dispatch(new appAction.SetNBDisplay(MENU_TYPE.CLASSIC));
		} else {
			this.store.dispatch(new appAction.SetNBDisplay(MENU_TYPE.STANDARD));
		}
	}

	setSelectedNBDisplay() {
		this.m.currentNBDisplay.hideNavBar = !this.m.currentNBDisplay.hideNavBar;
		this.store.dispatch(new appAction.SetNBDisplay(this.m.currentNBDisplay));
	}
	mouseEnter() {
		this.m.showMsg = true;
		this.m.unreadNotifs = false;
		this.m.errorNotif = false;
	}
	mouseLeave() {
		this.m.showMsg = false;
	}
	closeNotifs() {
		this.m.showMsg = false;
	}

	closeBot() {
		this.chatBot.hide();
	}

	closeNotify() {
		this.notification.hide();
	}
	ut_closeReleaseInfo() {
		this.releaseInfo.hide();
	}

	closePopovers() {
		if (this.pop) {
			this.pop.hide();
		}
		if (this.chatBot) {
			this.chatBot.hide();
		}
		if (this.notification) {
			this.notification.hide();
		}
	}

	closeOtherPopover(selectedOption) {
		if (selectedOption == "logsPopover") {
			this.chatBot.hide();
			this.notification.hide();
		}
		else if (selectedOption == "botPopover") {
			this.pop.hide();
			this.notification.hide();
		}
		else if (selectedOption == "notifyPopover") {
			this.pop.hide();
			this.chatBot.hide();
		}
	}

	omit_special_char_handle(event) {
		var k;
		k = event.charCode;
		return (k == 46 || k == 64 || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 58) || k == 95);
	}

	ut_markAsRead(item) { }
}
