import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { VxService } from '../vx/vx.service';

@Component({
  selector: 'app-branched-tree',
  templateUrl: './branched-tree.component.html',
  styleUrls: ['./branched-tree.component.css']
})
export class BranchedTreeComponent implements OnInit, OnDestroy {
  // two-way data binding
  @Input() key: 'nodes' | 'children' = 'children';
  @Input() treeData: any;
  @Output() treeDataChange = new EventEmitter();

  // When treeData changes in the TreeComponent, call this function
  onTreeDataChange(updatedTreeData: any) {
    this.treeData = updatedTreeData;
    this.treeDataChange.emit(this.treeData);
  }
  itemNameCopy: string = "";

  constructor(private vxService: VxService) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
  ut_editItemName(item, e) {
    // prevent the click event from bubbling up to the parent
    if (e) {
      e.stopPropagation();
    }


    if (this.vxService.canEdit) {
      this.itemNameCopy = item.name;
      this.vxService.closeAllItemNameEditors();
      item.edit = true;
      if (item.children && item.children.length > 0) {
        item.expand = !item.expand;
      }
      // this.itemEdit.emit(item);
    }
  }
  ut_setSelectedItem(item) {
    this.vxService.closeAllItemNameEditors();
    item.selected = true;
    this.toggleExpand(item);
    this.vxService.ut_selectedNode(item);
  }
  toggleExpand(item) {
    if (!item.isLeaf) {
      item.expand = !item.expand;
    }
  }
  ut_finishEditItemName(item) {
    if (this.itemNameCopy) {
      item.name = this.itemNameCopy;
      this.itemNameCopy = "";
      item.edit = false;
    }
  }
  ut_undoEditItemName(item) {
    this.itemNameCopy = "";
    item.edit = false;
  }

  ut_addLeaf(item) {
    let leaf = { itemId: +new Date(), ngxDraggableName: "['node']", name: "New Leaf", expand: false, children: [], isLeaf: true, summary: { text: "", preview: false, htmlText: "", plainText: "" }, lxList: [{ id: "", title: "", content: { text: "", htmlText: "", plainText: "" }, preview: true, itemId: +new Date() }] };
    item.children.push(leaf);
    if (!item.expand) {
      item.expand = true
    }
    this.ut_editItemName(leaf, null);
  }
  ut_addNode(item) {
    let node = { name: "New Node", expand: false, children: [], isLeaf: false, itemId: +new Date(), ngxDraggableName: "['node','tree']" };
    item.children.push(node);
    if (!item.expand) { item.expand = true }
    this.ut_editItemName(node, null);
  }
  ut_addPeerNode(item, index, e) {
    let node = { name: "New Node", expand: false, children: [], isLeaf: false, itemId: +new Date(), ngxDraggableName: "['node','tree']" };
    this.treeData[this.key].splice(index + 1, 0, node);
    this.ut_editItemName(node, null);
  }
  ut_removeItem(items, index) {
    items.splice(index, 1);
  }
}
