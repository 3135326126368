import { Component, OnInit } from '@angular/core';
import { DexService } from './services/dex.service';
import { State, get_dex_queries, get_sidebar_component_info, user_channel_joined } from '../rootReducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { clone } from 'ramda';
import { AppService } from '../service/app.service';
import { DEX_CONTEXTS, DEX_ENABLED_PATHS, DEX_FILTER_OPTIONS, ICONS } from './services/constant';
import { DragulaService } from 'ng2-dragula';
import { ActivatedRoute, Router } from '@angular/router';

// Follow: https://github.com/swiftekin/ctux/blob/master/specs/data-explorer.md

class Model {
  savedQueries: Array<any> = [];
  appliedQueryId: string = "";
  isQueryOpen: boolean = false;
  // isQueryOpen: boolean = true;
  isPublishModalOpen: boolean = false;
  readonly dragulaModelName = "dex-query-expression";
}

@Component({
  selector: 'app-dex',
  templateUrl: './dex.component.html',
  styleUrls: ['./dex.component.css']
})
export class DexComponent implements OnInit {
  readonly ICONS = ICONS;
  readonly subs = new Subscription();
  dexSelection = [];
  dexFilter = [];
  dexGroupBy = [];
  dexOrderBy = [];
  dexFilters: Array<{ name: string, value: string, options: Array<string> }> = [];
  allDexQueries = [];
  readonly m: Model = new Model();
  popoverContext = {
    Filter: {
      data: `<i class="custom-icons ${ICONS.Filter}"></i> <b>Filter:</b>\n &nbsp; status=Active`
    },
    // GroupBy: {
    //   data: `<i class="custom-icons ${ICONS.GroupBy}"></i> <b>GroupBy:</b>\n &nbsp; course, section`
    // },
    Group: {
      data: `<i class="custom-icons ${ICONS.Group}"></i> <b>Group:</b>\n &nbsp; section, SUM(amount)`
    },
    Sort: {
      data: `<i class="custom-icons ${ICONS.Sort}"></i> <b>Sort:</b>\n &nbsp; course:asc, section:desc\n` +
        ` &nbsp; ---\n &nbsp; course, section:desc`
    },
    Select: {
      data: `<i class="custom-icons ${ICONS.Select}"></i> <b>Select:</b>\n &nbsp; name, course, section\n` +
        ` &nbsp; ---\n &nbsp; first_name AS name, course\n` +
        ` &nbsp; ---\n &nbsp; first_name name, course`
    },
    Limit: {
      data: `<i class="custom-icons ${ICONS.Limit}"></i> <b>Limit:</b>\n &nbsp; 20`
    },
    Skip: {
      data: `<i class="custom-icons ${ICONS.Skip}"></i> <b>Skip:</b>\n &nbsp; 0`
    }
  }

  constructor(public dexService: DexService, private store: Store<State>,
    private appService: AppService, private dragulaService: DragulaService,
    private router: Router) { }

  ngOnInit() {
    this.sub_store();
    this.subs.add(this.dragulaService.dropModel(this.m.dragulaModelName).subscribe((changes) => {
      // To Run after dropping the item
      this.refreshQuery();
    }));
    this.subs.add(this.store.select(get_sidebar_component_info).subscribe(res => {
      this.appService.debouncerWithKey("context", () => { this.init(); }, 1000);
    }));
    this.getQueries();
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  private sub_store() {
    this.subs.add(this.store.select(get_dex_queries).subscribe((res) => {
      this.allDexQueries = res;
      this.init();
    }));
    this.subs.add(this.store.select(user_channel_joined).subscribe(isUserChannelJoined => {
      if (isUserChannelJoined) {
        this.getQueries();
      }
    }));
  }
  private init() {
    let dexPath = DEX_ENABLED_PATHS.find(path => location.href.includes(path));
    // const param = this.activatedRoute.snapshot.queryParams['context'] || "";
    const existingContext = this.appService.sidebarComponentInfo.context || "";
    if (dexPath) {
      const context = DEX_CONTEXTS[dexPath];
      this.m.savedQueries = this.allDexQueries.filter(o => o.context.component === context.component && o.context.tab === context.tab);
      this.appService.showDexResults = true;
    } else if (existingContext) {
      const context = Object.values(DEX_CONTEXTS).find(o => o.context === existingContext);
      this.m.savedQueries = this.allDexQueries.filter(o => o.context.component === context.component && o.context.tab === context.tab);
      this.appService.showDexResults = true;
    } else {
      this.m.savedQueries = this.allDexQueries;
    }
    this.initQueries();
  }
  private initQueries() {
    if (this.m.savedQueries.length > 0) {
      this.m.savedQueries.sort((a, b) => a.title.localeCompare(b.title));
      this.m.savedQueries = this.moveSelectAllToFront(this.m.savedQueries);
      this.m.appliedQueryId = this.m.savedQueries[0].id || "";
      this.dexService.jsonQuery = clone(this.m.savedQueries[0].query);
      this.dexService.selectedQueryData = clone(this.m.savedQueries[0]);
      this.initNewDex();
      this.dexService.getDexData();
    }
  }

  private initNewDex() {
    const inputFields = this.dexService.queryExpressionUiBuilder(this.dexService.jsonQuery.qe);
    this.dexFilters = inputFields;
  }

  ut_onChanging(e: any) {
    this.refreshQuery();
  }
  private refreshQuery() {
    this.appService.debouncerWithKey("dex", () => {
      // this.dexService.selectedQueryData = this.getCurrentDexQuery;
      const queryExpressions = this.dexService.queryExpressionBuilder(this.dexFilters);
      this.dexService.jsonQuery.qe = queryExpressions;
      this.dexService.getDexData();
      this.dexService.isEditMode = true;
      this.updateCustomQuery();
    }, 2000);
  }
  ut_useCustomQuery(e: any) {
    this.updateCustomQuery();
  }
  private updateCustomQuery() {
    if (this.dexService.selectedQueryData.id != "custom") {
      const customQueryIndex = this.m.savedQueries.findIndex(o => o.id === "custom");
      const customQueryData = clone(this.dexService.selectedQueryData);
      customQueryData.id = "custom";
      customQueryData.title = (DEX_CONTEXTS[customQueryData.from].label || "") + " (Custom)";
      if (customQueryIndex > -1) {
        this.m.savedQueries[customQueryIndex] = customQueryData;
        this.dexService.selectedQueryData = clone(this.m.savedQueries[customQueryIndex]);
      } else {
        this.m.savedQueries.push(customQueryData);
        this.dexService.selectedQueryData = clone(this.m.savedQueries[this.m.savedQueries.length - 1]);
      }
      this.m.appliedQueryId = this.dexService.selectedQueryData.id;
    }
  }
  ut_removeDexFilter(df: any, index: number) {
    df.value = "";
    this.dexService.getDexData();
    this.dexFilters.splice(index, 1);
  }
  ut_addDexFilter(index: number) {
    this.dexFilters.splice(index + 1, 0, { name: "", value: "", options: DEX_FILTER_OPTIONS });
  }

  ut_applyQuery() {
    this.dexService.getDexData();
  }
  ut_getQueries() {
    this.getQueries();
  }
  private getQueries() {
    this.dexService.getQueries();
  }
  private validateValue(value) {
    if (value === "") {
      return "";
    }
    let num = Number(value);
    value = Number.isNaN(num) ? value : num;
    return value !== "" && value !== undefined && value !== null ? value : ""
  }
  ut_changeQuery() {
    const appliedQuery = this.m.savedQueries.find(o => o.id === this.m.appliedQueryId);
    this.dexService.selectedQueryData = appliedQuery;
    this.dexService.jsonQuery = this.dexService.selectedQueryData.query;
    this.initNewDex();
    this.dexService.getDexData();
    this.dexService.isEditMode = true;
  }
  ut_editQuery(queryData: any) {
    this.dexService.selectedQueryData = queryData;
    // this.dexService.jsonQuery = this.dexService.selectedQuery;
    this.initNewDex();
  }
  ut_deleteQuery(id: string) {
    this.dexService.deleteQuery();
  }
  ut_toggleQuery() {
    this.m.isQueryOpen = !this.m.isQueryOpen;
  }
  private moveSelectAllToFront(arr: Array<{ title: string }>) {
    const index = arr.findIndex(o => o.title.includes("Select All"));
    if (index > -1) {
      const item = arr.splice(index, 1)[0];
      arr.unshift(item);
    }
    return arr;
  }
  private get getCurrentDexQuery() {
    return this.m.savedQueries.find(o => o.id === this.m.appliedQueryId);
  }
  ut_openPublishTo() {
    this.m.isPublishModalOpen = !this.m.isPublishModalOpen;
  }

  ut_reset() {
    this.dexService.resetQuery();
    this.router.navigate(['/dex']);
  }
  ut_refresh() {
    this.ut_changeQuery();
    this.router.navigate(['/dex']);
  }
}
