import { Component, OnInit } from '@angular/core';
import { AppService } from '../../service/app.service';
import { QUICKLINKS_FOR_PUBLISH } from '../../service/constants';

class Model {
  readonly quickLinksForPublish = QUICKLINKS_FOR_PUBLISH;
}

@Component({
  selector: 'app-publisher-tool',
  templateUrl: './publisher-tool.component.html',
  styleUrls: ['./publisher-tool.component.css']
})
export class PublisherToolComponent implements OnInit {
  readonly m: Model = new Model();

  constructor(public appService: AppService) { }

  ngOnInit() {
  }
  ut_toggleAccordion() {
    this.appService.isAccordionOpen = !this.appService.isAccordionOpen;
  }

}
