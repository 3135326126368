import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VaultService {
  // readonly AllowedFileFormats
  readonly AllowedImageFormats: Array<string> = ['jpg', 'jpeg', 'png', 'bmp'];
  readonly AllowedFileFormats: Array<string> = ['pdf'];
  imageHeight: number = 100;
  imageWidth: number = 250;

  constructor() { }
  // url_with_dimensions
  getUrlWithDimensions(url: string, query: string) {
    if (url.includes(".pdf")) {
      // if the url is a pdf, no need to add dimensions
      if (query && query != "undefined") {
        return url + query;
      }
      return url;
    }
    let urlWithDimensions = url + `upload/c_scale,h_${this.imageHeight},q_90,w_${this.imageWidth},e_make_transparent`
    if (query) {
      urlWithDimensions += query;
    }
    return urlWithDimensions;
    // urlArr[0] + "upload/c_scale,h_" + this.m.imageHeight.toString() + ",q_90,w_" + this.m.imageWidth.toString() + ",e_make_transparent" + urlArr[1];
  }
  isFileFormatSupported(file: File) {
    const AllowedFormats = this.AllowedFileFormats.concat(this.AllowedImageFormats);
    return AllowedFormats.includes(file.name.split('.').pop());
  }
}
