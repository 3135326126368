import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  testResult: string = '';

  constructor() { }

  ngOnInit() {
    this.runTests();
  }
  runTests() {
    this.test1();
    this.test2();
    this.test3();
  }
  test1() {
    this.fetchJsonFile('');
  }
  test2() {
    this.fetchJsonFile('..');
  }
  test3() {
    this.fetchJsonFile('../..');
  }
  private fetchJsonFile(path) {
    path = path + '/environment.uat.json';
    fetch(path).then(response => {
      try {
        // const res = response.json();
        // console.log({ res });
        console.log({ response });
        this.testResult += `status: ${response.status}, statusText: ${response.statusText}, path:${path}\n`
      } catch (error) {
        console.log({ response });
      }
    }).catch(error => {
      console.log({ error });
    });
  }
}
