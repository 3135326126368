import { ApiInterceptor } from './api-interceptor';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { componentFactoryName } from '@angular/compiler';
import { CTApi } from './service/ct-api';
import { DropdownModule } from 'ngx-dropdown';

import { EffectsModule } from '@ngrx/effects';
import { HeaderEffects } from './header/header.effects';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgModule, ErrorHandler } from '@angular/core';
import { RecentActivitiesComponent } from './service/recent-activities';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { RouterModule, Routes } from '@angular/router';
import { StoreModule, ActionReducer } from '@ngrx/store';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { ContextMenuModule } from 'ngx-contextmenu';

import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { CtMenuComponent } from './ctMenu/ctMenu.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyPolicyComponent } from './legal/privacyPolicy/privacyPolicy.component';
import { TermsAndConditionComponent } from './legal/tos/termsAndCondition/termsAndCondition.component';
import { HeaderComponent } from './header/header.component';
import { TrialComponent } from './home/trial/component';
import { SignInComponent } from './home/signIn/component';
import { SignInNewComponent } from './home/signIn-new/component';
import { SignInGenComponent } from './home/signin-gen/component';
import { HomeComponent } from './home/home.component';
import { AcademicYearComponent } from './home/acadYear/component';
import { CalendarComponent } from './home/calendar/component';
import { HomeCourseComponent } from './home/course/component';
import { MobilePrivacyPolicyComponent } from './mobilePrivacyPolicy/mobilePrivacyPolicy.component';

import { DeleteAccountComponent } from './deleteAccount/component';
import { MobilePageComponent } from './mobile/component';

import { NavTabsComponent } from './navTabs/navTabs.component';
import { NavTreeComponent } from './components/nav-tree/component';

import { PersonaComponent } from './persona/persona.component';
import { UserProfileComponent } from './userProfile/component';
import { UserPreferencesComponent } from './userPreferences/component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaymentComponent } from './payment/component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { MarkedOptionsFactory } from '../app/service/marked-options-factory';
import { OnlineApplicationComponent } from './online-application/component';

import { reducers, metaReducers } from './rootReducer';
import { DeactivateGuard } from './service/deactivate-guard';

// Academics
import { AcademicsModule } from './academics/academics.module';
// Asset
import { AssetModule } from './asset/asset.module';
// Checklist
import { ChecklistModule } from './checklist/checklist.module';
// Connect
import { ConnectModule } from './connect/connect.module';

//Admin
import { AdminModule } from './admin/admin.module';

// Admissions
import { AdmissionsModule } from './admissions/admissions.module';


//Library
import { LibraryModule } from './library/library.module';

//LMS
import { LMSModule } from './lms/lms.module';

//Fees
import { FeesModule } from './fees/fees.module';

//HR
import { HRModule } from './hr/hr.module';

// Receipts
import { ReceiptsModule } from './receipts/receipts.module';

// Regualtory Compliance
import { RCModule } from './rc/rc.module';

// Timetable Planner
import { TimetablePlannerModule } from './timetablePlanner/timetablePlanner.module';

// Vault
import { VaultModule } from './vault/vault.module';

// Cards
import { CardsModule } from './cards/cards.module';

// Applications
import { ApplicationsModule } from './applications/applications.module';

import { SharedModule } from './shared.module';
import { environment } from '../environments/environment';
import { AppEffects } from './store/app-effects';
import { SignUpComponent } from './home/signUp/component';
import { UserUpgradeStudentComponent } from './userUpgrade/student/component';
import { UserUpgradeTeacherComponent } from './userUpgrade/teacher/component';
import { SetupInstituteComponent } from './setupInstitute/component';
import { ChartDashboardComponent } from './components/chart-dashboard/chart-dashboard.component';
import { CreateDynamicComponentService } from './components/chart-dashboard/service/create-dynamic-component.service';
import { GoogleVegaliteModule } from './components/chart-dashboard/gv.module';
import { ChartDashboardEffects } from './components/chart-dashboard/service/chart-dashboard-effects';
import { ApiService } from './components/chart-dashboard/service/api-service';
import { UserUpgradeComponent } from './userUpgrade/component';
import { IntroJsService } from './service/intro-js.service';
import { DexModule } from './dex/dex.module';
import { AuthGuard } from './service/auth.guard';

// PrimeNg Modules
import { TreeModule } from 'primeng/tree';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { LandingPageComponent } from './lp/component';
import { LearnersComponent } from './lp/learners/component';
import { LpInstitutesComponent } from './lp/institutes/component';
import { ScheduledPublishesComponent } from './components/scheduled-publishes/scheduled-publishes.component';
import { AiChatComponent } from './components/ai-chat/ai-chat.component';
import { PublisherToolComponent } from './components/publisher-tool/publisher-tool.component';
import { LogsComponent } from './components/logs/logs.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { LmsToolComponent } from './components/lms-tool/lms-tool.component';
import { TableOfContentComponent } from './components/table-of-content/table-of-content.component';
import { BranchedTreeComponent } from './components/branched-tree/branched-tree.component';
import { TestComponent } from './components/test/test.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MessageService } from 'primeng/api';
import { LmsDashboardComponent } from './components/lms-dashboard/lms-dashboard.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ManualTriggersComponent } from './components/manual-triggers/manual-triggers.component';

const appRoutes: Routes = [
  { path: 'about', component: AboutComponent },
  { path: 'mobilePrivacyPolicy', component: MobilePrivacyPolicyComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'home', component: HomeComponent },
  { path: 'personas', component: PersonaComponent },
  { path: 'home/userProfile', component: UserProfileComponent },
  { path: 'home/userUpgrade/student', component: UserUpgradeStudentComponent },
  { path: 'home/userUpgrade/teacher', component: UserUpgradeTeacherComponent },
  { path: 'home/setupInstitute', component: SetupInstituteComponent },
  { path: 'academics', loadChildren: 'app/academics/academics.module#AcademicsModule' },
  { path: 'home/userProfile', component: UserProfileComponent },
  { path: 'academics', loadChildren: 'app/academics/academics.module#AcademicsModule' },
  { path: 'assets', loadChildren: 'app/asset/asset.module#AssetModule' },
  { path: 'home/userProfile', component: UserProfileComponent },
  { path: 'home/userPreferences', component: UserPreferencesComponent },
  { path: 'home/course', component: HomeCourseComponent },
  { path: 'admin', loadChildren: 'app/admin/admin.module#AdminModule', canActivate: [AuthGuard] },
  { path: 'assets', loadChildren: 'app/asset/asset.module#AssetModule' },
  { path: 'checklist', loadChildren: 'app/checklist/checklist.module#ChecklistModule' },
  { path: 'connect', loadChildren: 'app/connect/connect.module#ConnectModule' },
  { path: 'fees', loadChildren: 'app/fees/fees.module#FeesModule', canActivate: [AuthGuard] },
  { path: 'home/userProfile', component: UserProfileComponent },
  { path: 'library', loadChildren: 'app/library/library.module#LibraryModule' },
  { path: 'receipts', loadChildren: 'app/receipts/receipts.module#ReceiptsModule' },
  { path: 'regulatoryCompliance', loadChildren: 'app/rc/rc.module#RCModule' },
  { path: 'home/trial', component: TrialComponent },
  { path: 'home/signin', component: SignInComponent },
  { path: 'home/signup', component: SignUpComponent },
  { path: 'ttPlanner', loadChildren: 'app/timetablePlanner/timetablePlanner.module#TimetablePlannerModule' },
  { path: 'tos', component: TermsAndConditionComponent },
  { path: 'privacyPolicy', component: PrivacyPolicyComponent },
  { path: 'home/payment', component: PaymentComponent },
  { path: 'admissions', loadChildren: 'app/admissions/admissions.module#AdmissionsModule', canActivate: [AuthGuard] },
  { path: 'lms', loadChildren: 'app/lms/lms.module#LMSModule', canActivate: [AuthGuard] },
  { path: 'vault', loadChildren: 'app/vault/vault.module#VaultModule' },
  { path: 'hr', loadChildren: 'app/hr/hr.module#HRModule' },
  { path: 'accounting', loadChildren: 'app/accounting/accounting.module#AccountingModule' },
  { path: 'cards', loadChildren: 'app/cards/cards.module#CardsModule', canActivate: [AuthGuard] },
  { path: 'onlineApplication', component: OnlineApplicationComponent },
  { path: 'applications', loadChildren: 'app/applications/applications.module#ApplicationsModule' },
  { path: 'landingpage', component: LandingPageComponent },
  { path: 'learners', component: LearnersComponent },
  { path: 'deleteaccount', component: DeleteAccountComponent },
  { path: 'mobile', component: MobilePageComponent },
  { path: 'institutes', component: LpInstitutesComponent },
  { path: 'test', component: TestComponent },

  { path: 'chartboard', component: ChartDashboardComponent },
  { path: 'dex', loadChildren: './dex/dex.module#DexModule', canActivate: [AuthGuard] },
  { path: 'manual-triggers', component: ManualTriggersComponent, canActivate: [AuthGuard] },

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

const initialState: any = {
  title: '',
  accessCode: '',
  accessToken: '',
  ctContext: null,
  userInfo: '',
  userLoggedIn: false,
  personas: null,
  userDetails: null,
  recentActivities: [],
  notifications: [],
  userLinks: null,
  reqDate: null,
  tab: {},
  wfResponse: null,
  userData: null,
  smsBalance: null,
  emailBalance: null
}


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    // StoreModule.forRoot(reducers, { metaReducers: [logout] }),
    StoreModule.forRoot(reducers, { metaReducers }),
    RouterModule.forRoot(appRoutes),
    DropdownModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSpinnerModule,
    RecaptchaModule,
    ContextMenuModule,
    GoogleVegaliteModule,
    EffectsModule.forRoot([HeaderEffects, AppEffects, ChartDashboardEffects]),
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: MarkedOptionsFactory,
      },
    }),
    DexModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
    }),
    TypeaheadModule.forRoot(),
    TreeModule,
    InputTextModule,
    ToastModule,
    DialogModule,
    OverlayPanelModule
  ],
  declarations: [
    AboutComponent,
    MobilePrivacyPolicyComponent,
    AppComponent,
    ContactComponent,
    CtMenuComponent,
    DeleteAccountComponent,
    MobilePageComponent,
    FaqComponent,
    HeaderComponent,
    HomeComponent,
    NavTabsComponent,
    NavTreeComponent,
    PersonaComponent,
    UserProfileComponent,
    UserUpgradeComponent,
    TrialComponent,
    SignInComponent,
    SignInNewComponent,
    SignInGenComponent,
    SignUpComponent,
    AcademicYearComponent,
    CalendarComponent,
    HomeCourseComponent,
    TermsAndConditionComponent,
    PrivacyPolicyComponent,
    PaymentComponent,
    UserPreferencesComponent,
    UserUpgradeStudentComponent,
    UserUpgradeTeacherComponent,
    SetupInstituteComponent,
    OnlineApplicationComponent,
    LandingPageComponent,
    LearnersComponent,
    LpInstitutesComponent,
    ScheduledPublishesComponent,
    PublisherToolComponent,
    AiChatComponent,
    LogsComponent,
    NotificationsComponent,
    BranchedTreeComponent,
    TableOfContentComponent,
    LmsToolComponent,
    TestComponent,
    LoaderComponent,
    LmsDashboardComponent,
    PageNotFoundComponent,
    ManualTriggersComponent
  ],
  providers: [
    CTApi,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.RECAPTCHA_SITE_KEY } as RecaptchaSettings,// local
      // useValue: { siteKey: '6LelfHUUAAAAAAudjkpixnVNdwCKQV5veuW30Zi5' } as RecaptchaSettings,// local
      // useValue: { siteKey: '6LcyfnUUAAAAAC3fOBfvrg7IQDpmCvAqDhOVcLJn' } as RecaptchaSettings, // others
    }, RecentActivitiesComponent, DeactivateGuard, CreateDynamicComponentService, ApiService,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
