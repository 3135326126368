import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, get_dex_data } from '../../../app/rootReducer';
import { DexService } from '../services/dex.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dex-view',
  templateUrl: './dex-view.component.html',
  styleUrls: ['./dex-view.component.css']
})
export class DexViewComponent implements OnInit {
  readonly subs = new Subscription();
  tableHeaders: Array<string> = [];
  tableRows: Array<any> = [];

  constructor(private store: Store<State>, public dexService: DexService) { }

  ngOnInit() {
    this.sub_store();
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  private sub_store() {
    this.subs.add(this.store.select(get_dex_data).subscribe((res) => {
      if (res && res.reason && res.reason.message) {
        this.dexService.dexErrorMessage = res.reason.message;
      } else {
        this.dexService.dexErrorMessage = "";
      }
      if (res.data.length > 0) {
        this.tableBuilder(res.data);
        this.dexService.tableHeaders = this.tableHeaders;
        this.dexService.tableRows = this.tableRows;
      } else {
        this.tableRows = [];
      }
    }));
  }
  private tableBuilder(data: Array<any>) {
    this.tableHeaders = ["#"];
    const orderedKeys = [];
    this.getLastSelectedColumn.forEach(col => {
      orderedKeys.push(this.getColumnAlias(col));
    });
    this.tableHeaders = this.tableHeaders.concat(orderedKeys);
    this.tableRows = data.map(datum => (orderedKeys.map((item) => datum[item])));
  }
  private getColumnAlias(col: string) {
    let words = col.split(/\s+/);
    return words[words.length - 1];
  }
  private get getLastSelectedColumn() {
    const selects = this.dexService.jsonQuery.qe.filter((o: any) => o.hasOwnProperty("select"));
    if (selects.length > 0) {
      return (selects[selects.length - 1].select || []).map(c => {
        if (c.includes(" AS ")) {
          c = c.split(" AS ")[1];
        } else if (c.includes(" ")) {
          c = c.split(" ")[1];
        }
        return c
      });
    } else {
      return [];
    }
  }
}
