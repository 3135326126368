import { EventEmitter, Injectable } from '@angular/core';
import { Socket } from "phoenix_js";
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as rootReducer from '../rootReducer';
import { CTApi } from '../service/ct-api';
import * as appAction from '../store/app-action';
import * as connectAction from '../connect/store/action';
import * as notificationAction from '../notifications/store/action';
import * as instAction from '../inst/store/action';
import * as pulseAction from '../pulse/action';
import * as timetablePlannerAction from '../timetablePlanner/store/action';
import * as googleMeetAction from '../components/google-meet/store/action';
import * as widgetAction from '../widgets/store/action';
import * as cardsAction from './../cards/store/action';
import { BehaviorSubject } from 'rxjs';
import { SetNotificationForWidget } from '../accounting/store/action';
import { UpdateDashboard, UpdateDexData, UpdateDexQueries } from '../home/store/action';
import { TIME_PERIOD, WIDGET_FILTERS, WIDGET_NAME } from './constants';
import { AppService } from './app.service';
import { JsonObject, KafkaMessage } from './app.interfaces';

class Wf {
    wf_id: string;
    job_id: string;
    title: string;
    status: string;
    summary: any;
    data: {};
}

@Injectable({
    providedIn: 'root'
})
export class PulseService {
    socket = new Socket;
    currentCountSource = new BehaviorSubject<number>(0);
    currentCount$ = this.currentCountSource.asObservable();
    currentPersonaId: string = "";
    token: string = '';
    clientId: any;
    userEmail: string;
    instChannel: any;
    userChannel: any;
    // Tentative, move this to ngrx
    calendarEvents = new EventEmitter();
    constructor(
        public ctapi: CTApi,
        private store: Store<rootReducer.State>,
        private appService: AppService
    ) { }

    setup_ws(token, userId, clientId, currentPersona, userEmail) {
        this.clientId = clientId;
        this.userEmail = userEmail;
        this.token = token;
        this.currentPersonaId = currentPersona.id;
        if (environment.ENABLE_WS) {
            if (this.socket) {
                // disconnect the socket if it is already connected
                this.disconnectSocket();
            }
            // Older Approach
            // this.socket = new Socket(`${environment.PHX_URL}`, { params: { uc: `${userId},${userEmail},${clientId},${currentPersona.clientShortName}` } });
            // New Approach
            const uc = [userId, userEmail, clientId, currentPersona.clientShortName, currentPersona.id, currentPersona.title].join(',');
            this.socket = new Socket(`${environment.PHX_URL}`, { params: { uc } });
            this.socket.connect();

            if (!environment.production) {
                this.socket.onError(() => console.log("there was an error with the connection!"))
                this.socket.onClose(() => console.log("the connection dropped"))
            }

            this.instChannel = this.socket.channel("inst:" + clientId, { token: "" });
            let chan_prefix_inst = "Channel::Inst"
            this.attachChannelHandlersInst(this.instChannel)
            this.instChannel.join()
                .receive("ok", resp => {
                    if (currentPersona.title != 'Student') {
                        this.ctapi.getUserInitData().subscribe();
                    }
                })
                .receive("error", resp => { console.log(chan_prefix_inst, "Join Error", resp) })
                .receive("timeout", () => console.log(chan_prefix_inst, "Join Timeout"));
            this.instChannel.onError(e => { console.log(chan_prefix_inst, "Error", e) });
            this.instChannel.onClose(e => { console.log(chan_prefix_inst, "Close", e) });

            this.userChannel = this.socket.channel("room:" + userId, { token: "" });
            let chan_prefix_user = "Channel::User"
            this.userChannel.join()
                .receive("ok", resp => {
                    if (currentPersona.title != 'Student') {
                        let postData: any = ["sms_dlr_chart", "sms_dlr_json", "sms_dlr_exception"];
                        this.ctapi.userDashboard(postData).subscribe(() => { });
                    }
                    this.store.dispatch(new appAction.UserChannelJoined({ 'userChannelJoined': true }));
                })
                .receive("error", resp => { console.log(chan_prefix_user, "Join Error", resp) })
                .receive("timeout", () => console.log(chan_prefix_user, "Join Timeout"));
            this.userChannel.onError(e => {
                console.log(chan_prefix_user, "Error", e)
                this.store.dispatch(new appAction.UserChannelJoined({ 'userChannelJoined': false }));
            });
            this.userChannel.onClose(e => {
                this.store.dispatch(new appAction.UserChannelJoined({ 'userChannelJoined': false }));
            });
            this.attachChannelHandlersUser(this.userChannel)
            // Personal Channel: Persona specific channel            
            // this.personalChannel = this.socket.channel("room:" + userId + "persona_id:" + currentPersona.id, { token: "" });
            // this.personalChannel.join().receive("ok", resp => {
            //     this.appService.dLog("Joined personal channel successfully", resp);
            // }).receive("error", resp => {
            //     console.log("Unable to join personal channel", resp);
            // }).receive("timeout", () => console.error("Join Timeout for personal channel"));
            // this.personalChannelHandler(this.personalChannel);
        }
    }

    disconnectSocket() {
        this.socket.disconnect();
    }

    attachChannelHandlersInst(chan) {
        chan.on("sms_balance_update", (res) => {
            if (res) {
                this.store.dispatch(new connectAction.SetSmsBalance({ "smsBalance": res }));
            }
        });

        chan.on("inst_setup", (res) => {
            if (res) {
                this.store.dispatch(new pulseAction.SetInstSetupWidget({ "instituteData": res.payload }));
            }
        });

        chan.on("course_setup", (res) => {
            if (res) {
                this.store.dispatch(new pulseAction.SetAcademicsSetupWidget({ "academicsData": res.payload }));
            }
        });

        chan.on("student_enrollment_setup", (res) => {
            if (res) {
                this.store.dispatch(new pulseAction.SetStudentEnrollmentSetupWidget({ "studentEnrollmentData": res.payload }));
            }
        });

        chan.on("connect_setup", (res) => {
            if (res) {
                this.store.dispatch(new pulseAction.SetConnectSetupWidget({ "connectData": res.payload }));
            }
        });

        chan.on("calendar_setup", (res) => {
            if (res) {
                this.store.dispatch(new pulseAction.SetCalendarSetupWidget({ "calendarData": res.payload }));
            }
        });

        chan.on("user_access", (res) => {
            if (res) {
                this.store.dispatch(new pulseAction.SetUserAccessWidget({ "userAccessData": res.payload }));
            }
        });
        chan.on("chat_options", (res) => {
            if (res) {
                this.store.dispatch(new appAction.SetChatOptions({ "chatOptions": res }));
            }
        });
    }

    attachChannelHandlersUser(chan) {
        chan.on("user_data_update", (res) => {
            if (res) {
                // console.log("Got message", res);
                this.store.dispatch(new appAction.SetUserUpdatedData({ "userData": res }));
            }
        });

        chan.on("job_status_update", (wf: Wf) => {
            // console.log("Got message", wf);
            this.store.dispatch(new appAction.Pulse({ "wfResponse": wf }));
        });

        chan.on("sms_status_update", (res) => {
            this.store.dispatch(new connectAction.SetSmsDeliveryStatus({ "smsDeliveryStatus": res.sms_status }));
        });

        chan.on("sms_delivery_data", (res) => {
            console.log('joined')
            this.store.dispatch(new connectAction.SetSmsDeliveryData({ "smsDeliveryData": res.sms_dlr }));
        });

        chan.on("user_dashboard_data", (res) => {
            if (res) {
                this.store.dispatch(new connectAction.SetSmsDlrChartData({ "smsDlrChartData": res }));
            }
        });

        chan.on("wf_definitions", (res) => {
            var wf_defs = JSON.parse(res.payload.ok);
            this.store.dispatch(new instAction.SetWfDef({ "wfDef": wf_defs.workflows }));
        });

        chan.on("wf_executions", (res) => {
            var wf_execs = JSON.parse(res.payload.ok);
            this.store.dispatch(new instAction.SetWfExec({ "wfExec": wf_execs.wf_executions }));
        });

        chan.on("timetable_list", (res) => {
            this.store.dispatch(new timetablePlannerAction.SetTTModel(
                {
                    "csv": res.tt_csv,
                    "currentComputedCount": res.current_computed_count,
                    "ttId": res.tt_id,
                    "ttPoolCSV": res.tt_pool
                }
            )
            );
        });

        chan.on("current_computed_count_updated", (res) => {
            this.currentCountSource.next(res.current_computed_count);
        });

        chan.on("meetings", (res) => {
            if (res && res.meeting_list.length > 0) this.store.dispatch(new googleMeetAction.SetMeetings({ "meetings": res.meeting_list }));
        });
        /**
         * Notifications
         */
        chan.on("notification_event_data", (res) => {
            console.log(res);
            if (res && res.notifications.length > 0) {
                const ns: Array<{
                    data: {
                        action: string,
                        amount: string,
                        course: string,
                        section: string,
                        stu_id: string,
                        stu_name: string,
                        user_id: string
                    },
                    htmlText: string,
                    id: string,
                    inserted_at: string,
                    status: number,
                    text: string
                }> = res.notifications;
                const notifications = ns.map((n) => {
                    return {
                        ...n,
                        date: new Date(n.inserted_at),
                        links: [],
                        expand: false,
                        from: "",
                        expiry: null,
                        status: "",
                        type: "",
                        title: "Notice",
                        content: n.text,
                        sender: "",
                        read: n.status == 2,
                        ts: new Date(n.inserted_at).toLocaleString(),
                    }
                });
                this.store.dispatch(new notificationAction.SetNotifications({ notifications }));
            }
        });
        /**
         * Notifications for widget
         */
        chan.on("notifications_for_widget", (res) => {
            // console.log(res);
            if (res && res.notifications_list.length > 0) {
                this.store.dispatch(new SetNotificationForWidget(res.notifications_list));
            }
        });
        /**
         * Data for widgets
         */
        chan.on("widgets_data", (res) => {
            this.appService.dLog("widgets_data", res);
            if (res && res.length > 0) {
                this.store.dispatch(new UpdateDashboard(res));
            }
        });

        /**
         * Data for cards
         */
        chan.on("cards_data", (data) => {
            this.appService.dLog("cards_data", data);
            if (data && data.cards && data.persona_id == this.currentPersonaId) {
                const cards = data.cards;
                if (cards && Object.values(cards).length > 0) {
                    for (const key in cards) {
                        const value = cards[key];
                        switch (key) {
                            case "fee_defaulter":
                                // Need to comment this line, now card data is coming from Dex Query
                                // this.store.dispatch(new cardsAction.SetFeeDefaulters({ feeDefaulters: value }));
                                break;
                            case "quick_links":
                                var quickLinks = value.slice(0, 3);
                                this.store.dispatch(new cardsAction.SetQuickLinks({ quickLinks }));
                                break;
                            case "fees_payable":
                                // To fetch the data for the fees payable for the current month
                                const feePayable = value;
                                this.store.dispatch(new cardsAction.SetFeesPayable(feePayable));
                                break;
                            default:
                                console.error("Unknown card type", key);
                                break;
                        }
                    }
                }
            }
        });
        /**
         * Data for Upcoming Events
         */
        chan.on("upcoming_events_data", (data) => {
            this.appService.dLog("upcoming_events_data", data);
            if (data) {
                if (typeof data === 'object' && !Array.isArray(data) && data.persona_id == this.currentPersonaId) {
                    this.store.dispatch(new cardsAction.UpsertUpcomingEvents({ [data.for]: data.events }));
                }
                this.store.dispatch(new cardsAction.SetUpcomingEvents({ "upcomingEvents": data }))
            };
        });

        chan.on("calendar_events_data", (data) => {
            this.appService.dLog("calendar_events_data", data);
            if (data) {
                if (typeof data === 'object' && !Array.isArray(data) && data.persona_id == this.currentPersonaId) {
                    console.log(data);
                    this.calendarEvents.emit(data);
                }
            }
        });
        chan.on("topics_event_data", (data) => {
            this.appService.dLog("topics_event_data", data);
            if (data) {
                if (typeof data === 'object' && !Array.isArray(data) && data.persona_id == this.currentPersonaId) {
                    console.log(data);
                }
            }
        });
        chan.on("dry_run_event_data", (data) => {
            this.appService.dLog("dry_run_event_data", data);
            if (data) {
                console.log(data);
                if (data && data.persona_id) {
                    delete data.persona_id;
                }
                this.appService.dryRunStats.push(data);
            }
        });

        // Data for Explorer
        chan.on("dex_data_recd", (res) => {
            this.appService.dLog("dex_data_recd", res);
            if (res && res.persona_id == this.currentPersonaId && res.data) {
                switch (res.func) {
                    case "get_queries":
                        this.store.dispatch(new UpdateDexQueries(res.data));
                        break;
                    default:
                        if (res.reqInfo) {
                            switch (res.reqInfo.cardName) {
                                case 'fee_defaulters':
                                    this.store.dispatch(new cardsAction.SetFeeDefaulters({ feeDefaulters: res }));
                                    break;

                                default:
                                    break;
                            }
                        } else {
                            let dexData = res;
                            delete dexData.persona_id;
                            this.store.dispatch(new UpdateDexData(dexData));
                        }
                        break;
                }
            }
        });
        // Data for pubsub events
        chan.on("pubsub_event_data", (res) => {
            this.appService.dLog("pubsub_event_data", res);
            if (res && res.persona_id == this.currentPersonaId && res.data) {
                switch (res.action) {
                    case "get_topics_stats":
                        const topicsStatsMaped = {};
                        res.data.forEach((t) => {
                            topicsStatsMaped[t.topic] = t;
                        });
                        this.store.dispatch(new appAction.SetTopicsStats(res.data));
                        this.store.dispatch(new appAction.SetTopicsStatsMaped(topicsStatsMaped));
                        break;
                    case "get_scheduled_publishes":
                        console.log(res.data);
                        this.store.dispatch(new appAction.SetScheduledPublishes(res.data));
                        break;
                    default:
                        console.log("Unknown action");
                        break;
                }
            }
        });

        chan.on("bot_message", (res) => {
            if (res) {
                if (res.payload && res.payload.message) {
                    const aiChat = {
                        user: "AI",
                        message: res.payload.message,
                        ts: res.payload.ts || new Date().getTime(),
                        error: res.payload.error || false
                    };
                    this.store.dispatch(new appAction.AppendAIChat(aiChat));
                }
            }
        });

        chan.on("widget_sample_data", (res) => {
            if (res) this.store.dispatch(new widgetAction.SetSampleData({ "sampleData": res }));
        });

        chan.on("pub_sub_reply", (res) => {
            if (res.publishedContents) { }
            if (res.userSubscriptions) { }
            if (res.topicMembers) { }
        });
        // Responses for Formulas
        chan.on("formula_response", (res) => {
            console.log(res);
            if (res && res.persona_id == this.currentPersonaId && res.data) {
                switch (res.payload.operation) {
                    case "get_all_formulas":
                        this.store.dispatch(new appAction.SetAllFormulas(res.data));
                        break;
                    default:
                        console.log("Unknown operation");
                        break;
                }
            }
        });
        // Responses for Manual Trigger
        chan.on("manual_trigger_response", (res) => {
            console.log(res);
            if (res && res.persona_id == this.currentPersonaId && res.data) {
                switch (res.payload.operation) {
                    case "get_all_manual_triggers":
                        this.appService.manualTriggersList = res.data;
                        // this.store.dispatch(new appAction.SetAllManualTriggers(res.data));
                        break;
                    default:
                        console.log("Unknown operation");
                        break;
                }
            }
        })
    };
    // To User Channel
    postPromptToAIChat(aiChat: string) {
        if (!this.userChannel) {
            console.error("no user channel");
            return;
        }
        this.userChannel.push("bot_message", { body: aiChat }, 10000)
            .receive("ok", (msg) => {
                console.log(msg);
            })
            .receive("error", (reasons) => {
                console.error(reasons);
                this.store.dispatch(new appAction.AppendAIChat({
                    user: "AI",
                    message: "AI Chat is not available at the moment. Please try again later.",
                    ts: new Date().getTime(),
                    error: true
                }));
            })
            .receive("timeout", () => {
                console.error("Networking issue...")
                this.store.dispatch(new appAction.AppendAIChat({
                    user: "AI",
                    message: "Unable to connect to AI Chat. Please try again later.",
                    ts: new Date().getTime(),
                    error: true
                }));
            })
    }

    notification_read() {
        let payload = { "title": "notification_read", "payload": { "id": "<notif-id>" } };
        this.userChannel.push("notification", { body: payload }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("unable to mark notification as read", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }

    /**
     * Trigger to get current user notifications
     */
    get_notifications() {
        const body = { title: "get_notifications", payload: { skip: 0, top: 10 } };
        this.postMessageToUserChannel("notification_event", body)
    }

    get_meetings(date) {
        let payload = { "title": "get_meetings", "token": "", "payload": { "date": date } };
        this.userChannel.push("calendar_job", { body: payload }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("unable to get meetings", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }

    join_meeting(link, eventId) {
        let msg = { "title": "join_meeting", "token": "", "payload": { "event_id": eventId, "meeting_link": link } };
        this.userChannel.push("calendar_job", { body: msg }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("unable to get meetings", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }

    sync_notes(eventId, meetingLink) {
        let payload = { "title": "sync_notes", "token": this.token, "payload": { "event_id": eventId, "meeting_link": meetingLink } };
        this.userChannel.push("calendar_job", { body: payload }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("unable to sync notes", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }

    sampleTT() {
        let payload = { "title": "sample_tt", "token": this.token, "payload": {} };
        this.userChannel.push("tt_job", { body: payload }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("create failed", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }

    instSync() {
        let payload = { "title": "inst_sync", "token": this.token, "payload": { "inst_id": this.clientId } };
        this.userChannel.push("do_job", { body: payload }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("create failed", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }

    studentSync() {
        let payload = { "title": "student_sync", "token": this.token, "payload": { "inst_id": this.clientId } };
        this.userChannel.push("do_job", { body: payload }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("create failed", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    doWf(wf) {
        this.userChannel.push("bot_message", { body: wf }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => { console.log("create failed", reasons); })
            .receive("timeout", () => {
                console.log("Networking issue...")
            })
    }
    getSampleData(widget) {
        let payload = { "title": widget.title, "token": this.token, "payload": {} };
        this.userChannel.push("widget_sample_data", { body: payload }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("unable to get sample data", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }

    get_published_content() {
        let payload = { "title": "get_contents", "payload": {} };
        this.userChannel.push("publish_job", { body: payload }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("unable to get contents", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }

    get_members(topic_id) {
        let payload = { "title": "get_topic_members", "payload": { "id": { topic_id } } };
        this.userChannel.push("publish_job", { body: payload }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("unable to get members", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }

    publish_to_topic(content_title, artefact_id, topic_id, current_date_time) {
        let payload = {

            "title": "publish_to_topic",

            "payload": {
                "title": { content_title },
                "id": { artefact_id },
                "publish_to": { topic_id },
                "type": "file",
                "schedule": { current_date_time }
            }

        };

        this.userChannel.push("publish_job", { body: payload }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("unable to publish", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }

    get_fee_transaction_rpt(institutes) {
        let payload = { "title": "feetx_report", "payload": { "inst_id": institutes } };
        this.userChannel.push("ct_q_fee", { body: payload }, 10000)
            .receive("ok", (msg) => {
            })
            .receive("error", (reasons) => console.log("unable to get data", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    schedulerReminder(schedulerPayload: { [a: string]: any }, callback: (res: string) => void) {
        let body = { id: "sr_" + (+ Date.now()), title: "schedule_reminder", token: this.token, payload: schedulerPayload, tags: [], status: 1 };
        console.log({ body });

        const eventName = "scheduler_event"
        this.userChannel.push(eventName, { body: body }, 10000)
            .receive("ok", (msg) => {
                callback(msg.status || "ok");
            })
            .receive("error", (reasons) => {
                callback("error");
                console.log("unable to push data", reasons);
            })
            .receive("timeout", () => {
                callback("timeout");
                console.log("Timeout...");
            });
    }

    /**
     * Trigger to get notifications for widget
     * 
     * example: `get_widgetNotificationsByTopic("accounting/notifications")`
     */
    get_widgetNotificationsByTopic(topic: string) {
        const body = { title: "get_notifications_for_widget", payload: { topic } };
        this.userChannel.push("notification_event", { body }, 10000)
            .receive("ok", (msg) => { })
            .receive("error", (reasons) => console.log("unable to get notifications", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    syncWidgetData(widget_name: WIDGET_NAME) {
        if (!this.userChannel) {
            return;
        }
        const body = { title: "sync_widget_data", payload: { widget_name, token: this.token, filter_by: {} } };
        if (WIDGET_FILTERS.hasOwnProperty(widget_name)) {
            body.payload.filter_by = WIDGET_FILTERS[widget_name];
        }
        this.userChannel.push("widget_event", { body }, 10000)
            .receive("ok", (msg) => {
                this.appService.dLog("res:", widget_name, msg);
            })
            .receive("error", (reasons) => console.log("unable to sync widget", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    getWidgetDataByNames(widget_names: Array<string>) {
        if (!this.userChannel) {
            return;
        }
        const body = { title: "get_widget_data", payload: { widget_names, token: this.token } };
        this.userChannel.push("widget_event", { body }, 10000)
            .receive("ok", (msg) => {
                this.appService.dLog("res:", widget_names, msg);
            })
            .receive("error", (reasons) => console.log("unable to get Widget Data", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    getCardsData() {
        if (!this.userChannel) {
            console.error("no user channel");
            return;
        }
        const body = { title: "get_cards_data", token: this.token };
        this.userChannel.push("cards_event", { body }, 10000)
            .receive("ok", (msg) => {
                this.appService.dLog("res: cards_event", msg);
            })
            .receive("error", (reasons) => console.log("unable to get Widget Data", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    getUpcomingEventsData(timePeriod: TIME_PERIOD, group_by: "date" | "" = "date") {
        if (!this.userChannel) {
            console.error("No user channel");
            return;
        }
        const payload = { for: timePeriod, group_by: group_by };
        const body = { title: "get_upcoming_events_data", token: this.token, payload };
        this.userChannel.push("upcoming_events_event", { body }, 10000)
            .receive("ok", (msg) => {
                this.appService.dLog("res: upcoming_events_event", msg);
            })
            .receive("error", (reasons) => console.log("unable to get upcoming_events Data", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    getQuickLinksData() {
        if (!this.userChannel) {
            console.error("No user channel");
            return;
        }
        const body = { title: "get_quick_links_data", token: this.token };
        this.userChannel.push("quick_links_event", { body }, 10000)
            .receive("ok", (msg) => {
                this.appService.dLog(`ack: ${body.title}`, msg);
            })
            .receive("error", (reasons) => console.log("unable to get upcoming_events Data", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    requestDexData(query: any, reqInfo: JsonObject | null = null) {
        if (!this.userChannel) {
            console.error("No user channel");
            return;
        }
        const body = { title: "get_dex_data", payload: { query } };
        if (reqInfo) {
            body.payload['reqInfo'] = reqInfo;
            body.payload = Object.assign(body.payload, reqInfo);
        }
        this.userChannel.push("dex_event", { body }, 10000)
            .receive("ok", (msg) => {
                this.appService.dLog(`ack: ${body.title}`, msg);
            })
            .receive("error", (reasons) => console.log("unable to get_dex_data", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    saveQuery(queryData: any) {
        if (!this.userChannel) {
            console.error("No user channel");
            return;
        }
        const body = { title: "dex", payload: { func: "create_query", ...queryData } };
        this.userChannel.push("dex_event", { body }, 10000)
            .receive("ok", (msg) => {
                this.appService.dLog(`ack: ${body.title}`, msg);
            })
            .receive("error", (reasons) => console.log("unable to save_query", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    getQueries() {
        if (!this.userChannel) {
            console.error("No user channel");
            return;
        }
        const body = { title: "dex", payload: { func: "get_queries" } };
        this.userChannel.push("dex_event", { body }, 10000)
            .receive("ok", (msg) => {
                this.appService.dLog(`ack: ${body.title}`, msg);
            })
            .receive("error", (reasons) => console.log("unable to get_queries", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    updateQuery(queryData: any) {
        if (!this.userChannel) {
            console.error("No user channel");
            return;
        }
        const body = { title: "dex", payload: { func: "update_query", ...queryData } };
        this.userChannel.push("dex_event", { body }, 10000)
            .receive("ok", (msg) => {
                this.appService.dLog(`ack: ${body.title}`, msg);
            })
            .receive("error", (reasons) => console.log("unable to update_query", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    deleteQuery(id: string) {
        if (!this.userChannel) {
            console.error("No user channel");
            return;
        }
        const body = { title: "dex", payload: { func: "change_status", id, status: "deleted" } };
        this.userChannel.push("dex_event", { body }, 10000)
            .receive("ok", (msg) => {
                this.appService.dLog(`ack: ${body.title}`, msg);
            })
            .receive("error", (reasons) => console.log("unable to delete_query", reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }
    createTopic(data: any) {
        const body = { title: "topics", payload: { func: "create_query", ...data } };
        this.postMessageToUserChannel("topics_event", body);
    }
    getTopics() {
        const body = { title: "topics", payload: { func: "get_topics" } };
        this.postMessageToUserChannel("topics_event", body);
    }
    deleteTopics(id: string) {
        const body = { title: "topics", payload: { func: "change_status", id, status: "deleted" } };
        this.postMessageToUserChannel("topics_event", body);
    }
    updateTopicACL(id: string, acl: Array<{ [k: string]: any }>) {
        const body = { title: "topics", payload: { func: "update_acl", id, acl } };
        this.postMessageToUserChannel("topics_event", body);
    }
    getTopicsAvailableForUser() {
        const body = { title: "topics", payload: { func: "get_topics_available_for_user" } };
        this.postMessageToUserChannel("topics_event", body);
    }
    // calendar_events_data
    getCalendarEvents() {
        const body = { title: "get_calendar_events", payload: {} };
        this.postMessageToUserChannel("calendar_event", body);
    }
    createCalendarEvent(mainTopicName: string, mainTopicId: string, topic: string, topicId: string, payload: any, metadata: { [k: string]: any } = {}) {
        topic = topic.replace(/ /g, "-").replace(/\//g, "-");
        metadata.worker = "calendar";
        metadata.action = "create_calendar_event";
        const body = { mainTopicName, mainTopicId, topic, topicId, payload, metadata };
        this.postMessageToUserChannel("pubsub_event", body);
    }
    pushToKafka(kafkaMessage: KafkaMessage) {
        this.postMessageToUserChannel("pubsub_event", kafkaMessage);
    }
    getTopicsStats() {
        const body = { title: "pubsub", payload: { action: "get_topics_stats" } };
        this.postMessageToUserChannel("pubsub_event", body);
    }
    getScheduledPublishes() {
        const body = { title: "pubsub", payload: { action: "get_scheduled_publishes" } };
        this.postMessageToUserChannel("pubsub_event", body);
    }
    updateScheduledPublish(payload: JsonObject) {
        const body = { title: "pubsub", payload: { action: "update_scheduled_publish", ...payload } };
        this.postMessageToUserChannel("pubsub_event", body);
    }
    /** Formulas
     * 
     * operation: "insert_formula" | "get_all_formulas" | "update_formula" | "delete_formula" | "get_formula"
     * 
     */
    formulaJob(payload: { operation: string; data: JsonObject }) {
        const body = { title: "formulas", payload };
        this.postMessageToUserChannel("formulas_event", body);
    }
    /**
     * Trigger a job manually from the UI
     * 
     * @param payload { operation: string; data: JsonObject }
     */
    manualTriggerJob(payload: { operation: string; data: JsonObject }) {
        const body = { title: "manual_trigger", payload };
        this.postMessageToUserChannel("manual_trigger_event", body);
    }

    private postMessageToUserChannel(event_name: string, body: any) {
        if (!this.userChannel) {
            console.error("User channel not yet created"); return;
        }
        this.userChannel.push(event_name, { body }, 10000)
            .receive("ok", (msg) => {
                this.appService.dLog(`ack: ${body.title}`, msg);
            })
            .receive("error", (reasons) => console.log(`unable to ${body.title}`, reasons))
            .receive("timeout", () => console.log("Timeout..."))
    }

    // getWf(wf){
    //     console.log("wf:",wf);
    //     this.userChannel.push("wf", { body: wf }, 10000)
    //     .receive("ok", (msg) => {
    //     } )
    //     .receive("error", (reasons) => {console.log("get wf failed", reasons); })
    //     .receive("timeout", () => {
    //       console.log("Networking issue...")
    //     } )
    // }
    // runWf(wf_id){
    //     this.userChannel.push("wf_run", { body: wf_id }, 10000)
    //     .receive("ok", (msg) => {
    //     } )
    //     .receive("error", (reasons) => {console.log("get wf failed", reasons); })
    //     .receive("timeout", () => {
    //       console.log("Networking issue...")
    //     } )
    // }
}