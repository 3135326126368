import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lms-dashboard',
  templateUrl: './lms-dashboard.component.html',
  styleUrls: ['./lms-dashboard.component.css']
})
export class LmsDashboardComponent implements OnInit {
  upcomings = [
    "About to complete 80% of the course",
    "Parent-Teacher meeting on 10th Jan @ 10:00 AM",
    "Assignment due on 15th Jan",
    "Holiday on 26th Jan - Republic Day"
  ]
  smallCards = [
    { label: 'Student Performance Evaluation', icon: 'assets/student-performance-evaluation.svg' },
    { label: 'Maintain Records', icon: 'assets/maintain-records.svg' },
    { label: 'Track Reporting', icon: 'assets/track-reporting.svg' }
  ]

  constructor() { }

  ngOnInit() {
  }

}
