import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener, OnChanges } from '@angular/core';
import { NavTreeService } from './service/service';
import * as _ from 'underscore';
import 'brace/index';
import 'brace/theme/eclipse';
import 'brace/mode/markdown';
import 'brace/ext/language_tools';
import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

class Model {
  selectedItem: any = {};
  ctContext: any;
}

@Component({
  selector: 'nav-tree',
  templateUrl: './component.html',
  styleUrls: ['./component.css']
})
export class NavTreeComponent implements OnInit, OnChanges, OnDestroy {
  @HostListener('document:click', ['$event']) onDocumentClick() {
    this.closeDropdown();
  }
  @Input('data') items: Array<Object> = [];
  @Output() selectedItem = new EventEmitter<any>();
  public m: Model;
  private readonly subscription = new Subscription();
  private endpath: string = '';

  constructor(private store: Store<rootReducer.State>, public service: NavTreeService, public router: Router) { }

  ngOnInit() {
    this.setTab();
    this.init_model();
    this.sub_store();
  }
  ngOnChanges() {
    this.reinitNavItems();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init_model() {
    this.m = new Model();
  }
  sub_store() {
    this.subscription.add(this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const paths = e.url.split('/');
        this.endpath = paths[paths.length - 1];
      }
    }));
    this.store.select(rootReducer.get_state_app).subscribe(context => {
      this.m.ctContext = context;
    });
  }
  private reinitNavItems() {
    this.items.forEach((item: any) => {
      item.selected = item.route === this.endpath;
    });
  }

  setTab() {
    let url = this.router.url;
    var navArr = url.split('/');
    if (this.items) {
      this.items.forEach(item => {
        item['selected'] = false;
        if (item['route'] == navArr[1]) { item['selected'] = true; }
      });
    }
  }

  toggleExpand(item) {
    if (!item.isLeaf) {
      item.expand = !item.expand;
    }
    var _items = this.items.filter((i: any) => i.name != item.name && i['expand'] == true);
    _items.forEach((i: any) => i.expand = false);
    if (item.name == 'Fees' && !this.service.isFeesHovered) {
      this.service.isFeesHovered = true;
      setTimeout(() => {
        this.service.getAllStudents(true);
      }, 300);
    }
  }
  setSelectedItem(item) {
    item.expand = false;
    this.m.selectedItem = item;
    this.unsetSelected(this.items);
    this.selectedItem.emit(this.m.selectedItem);
    this.m.selectedItem.selected = true;
    if (item.route) {
      let path = "/" + item.route;
      this.router.navigate([path]);
    }
  }

  unsetSelected(nodes) {
    nodes.forEach(node => {
      node.selected = false;
      if (node.children && node.children.length > 0) { this.unsetSelected(node.children); }
    });
  }

  closeDropdown() {
    this.items.forEach((it: any) => it.expand = false);
  }
}