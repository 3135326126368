import * as app from './app-action';
import { environment } from '../../environments/environment';
import * as _ from 'underscore';
import { AIChat, AppStatus, JsonObject } from '../service/app.interfaces';

export interface RecentActivity {
  Component: string;
  Title: string;
  Context: any;
}

function set_otp(payload, state) {
  let _otp = state.otp;
  _otp.timer_status = payload.timer_status;
  _otp.time = payload.time;
  return _otp;
}

export class TopicAction {
  constructor(public id: number, public displayText: string, public methodName: string) {
  }
}

export class Topic {
  id: string;
  name: string;
  status: number;
  statusAsString: string;
  description: string = "";
  addDescription: false;
  addFriendlyName: false;
  friendlyName: string = "";
  category: string = "";
  role: string = "";
  roleAsString = "";
  categoryAsString = "";
  accessAsString = "";
  access: string = "";
  link: string;
  canUnsubscribe: false;
  actions: TopicAction[] = [];
  isSubscriptionAllowed: boolean = false;
  subscriptions: { [k: string]: any };
  mainTopicId: string = "";
  mainTopicName: string = "";

  get allowedActions(): TopicAction[] {
    let result = [];
    switch (this.status) {
      case 1: //active
        result = [];
        result.push(this.actions[1]);
        break;
      case 99: //disabled
        result = [];
        result.push(this.actions[0]);
        break;
      default:
        result = [];
    }
    return result;
  }
  constructor() {
    this.actions.push(new TopicAction(1, "Enable", "ut_enableTopic"));
    this.actions.push(new TopicAction(2, "Disable", "ut_disableTopic"));
  }
}

export interface State {
  title: string;
  accessCode: string;
  accessToken: string;
  userLoggedIn: boolean;
  ctContext: any;
  userInfo: string;
  personas: any[];
  userDetails: any;
  recentActivities: RecentActivity[];
  notifications: any[];
  userLinks: any;
  reqDate: any;
  tab: any;
  wfResponse: any;
  userData: any;
  userChannelJoined: boolean;
  statesData: States;
  otp: {
    timer_status: boolean;
    time: any;
  }
  showTimer: boolean;
  topicList: any[];
  mainTopicList: any[];
  resetTab: any;
  clipboard: any;
  copyItem: any;
  selectedNBDisplay: any;
  selectedTopic: any;
  aiChat: Array<AIChat>;
  commands: any[];
  chatOptions: any;
  indx: any;
  signInPage: boolean;
  resetPasswordEmailSent: boolean;
  appStatus: AppStatus;
  topicsStats: Array<any>;
  topicsStatsMaped: { [k: string]: any };
  scheduledPublishes: Array<any>;
  isSidebarOpen: boolean;
  sidebarComponentInfo: JsonObject;
  all_formulas: Array<JsonObject>;
}

const initialState: State = {
  title: '',
  accessCode: '',
  accessToken: '',
  ctContext: null,
  userInfo: '',
  userLoggedIn: false,
  personas: null,
  userDetails: null,
  recentActivities: [],
  notifications: [],
  userLinks: null,
  reqDate: null,
  tab: {},
  wfResponse: null,
  userData: null,
  userChannelJoined: false,
  statesData: {
    "states": []
  },
  otp: {
    "timer_status": false,
    "time": null
  },
  showTimer: null,
  topicList: null,
  mainTopicList: null,
  resetTab: null,
  clipboard: {},
  copyItem: {},
  selectedNBDisplay: { name: "Standard", type: "standard", hideNavBar: false },
  selectedTopic: null,
  aiChat: [],
  commands: [],
  chatOptions: {},
  indx: null,
  signInPage: false,
  resetPasswordEmailSent: false,
  appStatus: { isInitilized: false, isLoggedIn: false },
  topicsStats: [],
  topicsStatsMaped: {},
  scheduledPublishes: [],
  isSidebarOpen: false,
  sidebarComponentInfo: {},
  all_formulas: []
};
export class States {
  states: JSON[];
}

function disableTopic(topics, id) {
  var topicList: Topic[] = topics.map((item) => {
    if (item.id == id) item.status = 99; //99
    return item;
  });
  return topicList;
}
function enableTopic(topics, id) {
  var topicList: Topic[] = topics.map((item) => {
    if (item.id == id) item.status = 1; //1
    return item;
  });
  return topicList;
}
function copyToClipboard(clipboard, item) {
  let clipObj = {};
  if (clipboard && clipboard.items) {
    clipboard.items.unshift(item);
    clipObj = { items: clipboard.items };
  }
  else {
    clipObj = { items: [] };
    clipObj['items'].unshift(item);
  }

  return clipObj;
}
function removeFromClipboard(clipboard, itemId) {
  clipboard.items = _.reject(clipboard.items, function (item) {
    return item.id == itemId;
  });
  let clipObj = { items: clipboard.items };
  return clipObj;
}
function addCopyItem(copyItem, item) {
  let copyObj = {};
  if (copyItem && copyItem.items) {
    copyItem.items.unshift(item);
    copyObj = { items: copyItem.items };
  }
  else {
    copyObj = { items: [] };
    copyObj['items'].unshift(item);
  }
  return copyObj;
}
function removeCopyItem(copyItem, itemId) {
  copyItem.items = _.reject(copyItem.items, function (item) {
    return item.id == itemId;
  });
  let copyObj = { items: copyItem.items };
  return copyObj;
}
function setSelectedItem(clipboard, item) {
  let clipObj = {};
  if (clipboard && clipboard.items) {
    clipboard.items.unshift(item);
    clipObj = { items: clipboard.items };
  }
  else {
    clipObj = { items: [] };
    clipObj['items'].unshift(item);
  }

  return clipObj;
}

function setTopicList(dataFromServer) {
  let result = [];
  if (dataFromServer) {
    dataFromServer.forEach(o => {
      let topic = new Topic();
      topic.id = o.id;
      topic.status = o.status;
      topic.statusAsString = o.statusAsString;
      topic.name = o.name;
      topic.description = o.description;
      topic.friendlyName = o.friendlyName;
      topic.category = o.category;
      topic.role = o.role;
      topic.roleAsString = o.roleAsString;
      topic.categoryAsString = o.categoryAsString;
      topic.accessAsString = o.accessAsString;
      topic.access = o.access;
      topic.link = o.link;
      topic.canUnsubscribe = o.canUnsubscribe;
      topic.isSubscriptionAllowed = o.isSubscriptionAllowed;
      topic.subscriptions = o.subscriptions;
      topic.mainTopicId = o.mainTopicId;
      topic.mainTopicName = o.mainTopicName;
      result.push(topic);
    });
  }
  return result;
}

function setStates(dataFromServer): States {
  let states = new States();
  states.states = dataFromServer

  return states
}
function get_user_info(ctContext: any): any {
  const inst_short_name = ctContext.currentPersona.ClientShortName;
  const acad_year_from = ctContext.currentPersona.acadYearFrom;
  const acad_year_from_yy = "";
  const acad_year_to_yy = "";
  if (acad_year_from) { const acad_year_from_yy = acad_year_from.substr(2, 2); }
  const acad_year_to = ctContext.currentPersona.acadYearTo;
  if (acad_year_to) { const acad_year_to_yy = acad_year_to.substr(2, 2); }
  const user_persona = ctContext.currentPersona.title;
  const user_info = inst_short_name + ' ' + acad_year_from_yy + '-' + acad_year_to_yy + ' ' + '(' + user_persona + ')';
  return user_info;
}

function extend_ctContext(ctContext: any): any {
  var navPerms = [
    {
      "Route": "cards",
      "DisplayText": "Home",
      "TabIcon": "assets/img/tab-icons/home.svg",
      "Sidebar": [
        // {
        //   "Route": "home",
        //   "DisplayText": "Dashboard",
        //   "Endpoint": ["get/auth/user"]
        // },
        // {
        //   "Route": "home/userProfile",
        //   "DisplayText": "User Profile",
        //   "Endpoint": ["get/auth/user"]
        // }
      ],
      "Endpoints": ["get/auth/cards"],
      "selected": false
    },
    {
      "Route": "home",
      "DisplayText": "Dashboard",
      "TabIcon": "assets/img/tab-icons/dashboard.svg",
      "Sidebar": [
        // {
        //   "Route": "home",
        //   "DisplayText": "Dashboard",
        //   "Endpoint": ["get/auth/user"]
        // },
        // {
        //   "Route": "home/userProfile",
        //   "DisplayText": "User Profile",
        //   "Endpoint": ["get/auth/user"]
        // }
      ],
      "Endpoints": ["get/auth/user"],
      "selected": false
    },
    {
      "Route": "admin",
      "DisplayText": "Admin",
      "TabIcon": "assets/img/tab-icons/admin.svg",
      "Sidebar": [
        // {
        //   "Route": "admin",
        //   "DisplayText": "Dashboard",
        //   "Endpoint": ["get/auth/org", "get/auth/institute"]
        // },
        {
          "Route": "admin/org",
          "DisplayText": "Org",
          "Icon": "fa fa-th-large",
          "Endpoint": ["get/auth/org"]
        },
        {
          "Route": "admin/inst",
          "DisplayText": "Setup",
          "Icon": "fa fa-university",
          "Endpoint": ["get/auth/institute"]
        },
        {
          "Route": "admin/inst/setup",
          "DisplayText": "Widgets",
          "Icon": "fa fa-th-large",
          "Endpoint": ["get/auth/institute"]
        }
      ],
      "Endpoints": ["get/auth/org", "get/auth/institute", "get/stu/student"],
      "selected": false
    },
    {
      "Route": "applications",
      "DisplayText": "Applications",
      "TabIcon": "assets/img/tab-icons/application.svg",
      "Sidebar": [
      ],
      "Endpoints": ["get/admission"],
      "selected": false
    },
    {
      "Route": "admissions",
      "DisplayText": "Admissions (WIP)",
      "TabIcon": "assets/img/tab-icons/admission.svg",
      "Sidebar": [
      ],
      "Endpoints": ["get/admission"],
      "selected": false
    },
    {
      "Route": "hr",
      "DisplayText": "HR",
      "TabIcon": "assets/img/tab-icons/hr.svg",
      "Sidebar": [],
      "Endpoints": ["get/staff"],
      "selected": false
    },
    {
      "Route": "academics",
      "DisplayText": "Academics",
      "TabIcon": "assets/img/tab-icons/academics.svg",
      "Sidebar": [
        {
          "Route": "admin/inst/setup",
          "DisplayText": "Courses",
          "Icon": "fa fa-university",
          "Endpoint": ["get/acad/course"]
        },
        {
          "Route": "",
          "DisplayText": "Widgets",
          "Icon": "fa fa-th-large",
          "Endpoint": []
        }
      ],
      "Endpoints": ["get/academics/calendar/getCalendarEvents"],
      "selected": false
    },
    {
      "Route": "lms",
      "DisplayText": "LMS",
      "TabIcon": "assets/img/tab-icons/lms.svg",
      "Sidebar": [],
      "Endpoints": ["get/learning"],
      "selected": false
    },
    {
      "Route": "assets",
      "DisplayText": "Assets",
      "TabIcon": "assets/img/tab-icons/asset.svg",
      "Sidebar": [],
      "Endpoints": ["get/asset"],
      "selected": false
    },
    {
      "Route": "fees",
      "DisplayText": "Fees",
      "TabIcon": "assets/img/tab-icons/fees.svg",
      "Sidebar": [],
      "Endpoints": ["get/fee", "get/fee/transact"],
      "selected": false
    },
    {
      "Route": "library",
      "DisplayText": "Library",
      "TabIcon": "assets/img/tab-icons/library.svg",
      "Sidebar": [],
      "Endpoints": ["get/library/material"],
      "selected": false
    },
    {
      "Route": "connect",
      "DisplayText": "Connect",
      "TabIcon": "assets/img/tab-icons/connect.svg",
      "Sidebar": [
        {
          "Route": "connect/setup",
          "DisplayText": "Setup",
          "Icon": "fa fa-cog",
          "Endpoint": ["get/connect/connect"]
        },
        {
          "Route": "",
          "DisplayText": "Widgets",
          "Icon": "fa fa-th-large",
          "Endpoint": ["get/connect/connect"]
        }
      ],
      "Endpoints": ["get/connect/connect"],
      "selected": false
    },
    {
      "Route": "accounting",
      "DisplayText": "Accounting",
      "TabIcon": "assets/img/tab-icons/accounting.svg",
      "Sidebar": [],
      "Endpoints": ["get/accounting/journal", "get/accounting/ledger"],
      "selected": false
    },
    {
      "Route": "receipts",
      "DisplayText": "Receipts",
      "TabIcon": "assets/img/tab-icons/receipts.svg",
      "Sidebar": [],
      "Endpoints": ["get/gr/gr"],
      "selected": false
    },
    {
      "Route": "regulatoryCompliance",
      "DisplayText": "RC",
      "TabIcon": "assets/img/tab-icons/rc.svg",
      "Sidebar": [],
      "Endpoints": ["get/rc/rc"],
      "selected": false
    },
    {
      "Route": "ttPlanner",
      "DisplayText": "TT",
      "TabIcon": "assets/img/tab-icons/timetable.svg",
      "Sidebar": [],
      "Endpoints": ["get/ttPlanner"],
      "selected": false
    },
    {
      "Route": "vault",
      "DisplayText": "Vault",
      "TabIcon": "assets/img/tab-icons/vault-02.svg",
      "Sidebar": [],
      "Endpoints": ["get/vault"],
      "selected": false
    },
    // {
    //   "Route": "student-page",
    //   "DisplayText": "Student",
    //   "TabIcon": "assets/img/tab-icons/vault-02.svg",
    //   "Sidebar": [],
    //   "Endpoints": ["get/auth/user"],
    //   "selected": false
    // },
    // {
    //   "Route": "teacher-page",
    //   "DisplayText": "Teacher",
    //   "TabIcon": "assets/img/tab-icons/vault-02.svg",
    //   "Sidebar": [],
    //   "Endpoints": ["get/auth/user"],
    //   "selected": false
    // }
  ];
  ctContext.currentPersona.navPerms = navPerms;
  if (ctContext.currentPersona.clientShortName == 'NPSGMC') {
    ctContext.currentPersona.navPerms = ctContext.currentPersona.navPerms.filter(nav => nav.DisplayText != "Staff" && nav.DisplayText != "Student" && nav.DisplayText != "Academics" && nav.DisplayText != "Fees" && nav.DisplayText != "Library" && nav.DisplayText != "Connect" && nav.DisplayText != "Assets" && nav.DisplayText != "Receipts" && nav.DisplayText != "Regulatory Compliance" && nav.DisplayText != "Admin");
    if (ctContext.currentPersona.title == 'Teacher') {
      var admObj = {
        "Sidebar": [],
        "Icon": null,
        "State": "admin",
        "Route": "admin",
        "DisplayText": "Admin",
        "selected": false
      };
      ctContext.currentPersona.navPerms.push(admObj);
      var stuObj = {
        "Sidebar": [],
        "Icon": null,
        "State": "student.manage",
        "Route": "student/manage",
        "DisplayText": "Student",
        "selected": false
      };
      ctContext.currentPersona.navPerms.push(stuObj);
    }
  }
  else if (ctContext.currentPersona.clientName == 'NPS') {
    ctContext.currentPersona.navPerms = ctContext.currentPersona.navPerms.filter(nav => nav.DisplayText != "Staff" && nav.DisplayText != "Student" && nav.DisplayText != "Academics" && nav.DisplayText != "Fees" && nav.DisplayText != "Library" && nav.DisplayText != "Connect" && nav.DisplayText != "Assets" && nav.DisplayText != "Receipts" && nav.DisplayText != "Regulatory Compliance");
  }
  else if (ctContext.currentPersona.title == 'Default User') { ctContext.currentPersona.navPerms = ctContext.currentPersona.navPerms.filter(nav => nav.DisplayText != "Admissions (WIP)" && nav.DisplayText != "Dashboard"); }
  else if (ctContext.currentPersona.title != 'Default User') { ctContext.currentPersona.navPerms = ctContext.currentPersona.navPerms.filter(nav => nav.DisplayText != "Applications"); }
  else {
    if ((ctContext.currentPersona.isRoot) || (ctContext.currentPersona.title == "Admin" && ctContext.currentPersona.isOrg) || (ctContext.currentPersona.title == "Account Manager" && ctContext.currentPersona.isOrg)) { ctContext.currentPersona.navPerms = ctContext.currentPersona.navPerms.filter(nav => nav.DisplayText != "Staff" && nav.DisplayText != "Student" && nav.DisplayText != "Academics" && nav.DisplayText != "Fees" && nav.DisplayText != "Library" && nav.DisplayText != "Connect" && nav.DisplayText != "Assets" && nav.DisplayText != "Receipts" && nav.DisplayText != "Regulatory Compliance"); }
    else { ctContext.currentPersona.navPerms = ctContext.currentPersona.navPerms.filter(nav => nav.DisplayText != "Staff" && nav.DisplayText != "Student"); }
  }
  return ctContext;
}

function get_notifs(notification: any): any {
  var notifications = notification.notifications;
  if (notifications != null) {
    delete notification["notifications"];
    notifications.unshift(notification);

    if (notifications.length > 5) { notifications.splice(5); }
  }
  return notifications;
}
function get_new_ra_list(ra: RecentActivity, state: State) {
  let ras = state.recentActivities.slice(0);
  const resObj = ras.find(x => x.Title == ra.Title);
  if (resObj) {
    var index = ras.findIndex(x => x.Title == resObj.Title);
    resObj.Context.SelectedFeeId = ra.Context.SelectedFeeId;
    resObj.Context.SelectedFeeName = ra.Context.SelectedFeeName;
    ras.splice(index, 1);
    ras.splice(0, 0, resObj);
  }
  else {
    ras.unshift(ra);
    if (ras.length > 10) {
      ras.splice(-1, 1);
    }
  }
  return ras;
}

function appendAIChat(chatList, newChat) {
  chatList.push(newChat);
  return chatList;
}
function getSidebarComponentInfo(sidebarComponentInfo, payload) {
  if (payload.component) {
    sidebarComponentInfo.component = payload.component;
  }
  if (payload.context) {
    sidebarComponentInfo.context = payload.context;
  }
  return { ...sidebarComponentInfo };
}

export function reducer(state = initialState, action: app.Actions): State {
  switch (action.type) {
    case app.ActionTypes.LOAD_STATES:
      return state;
    case app.ActionTypes.LOAD_STATES_SUCCESS:
      return Object.assign({}, state, { statesData: setStates(action.payload) });
    case app.ActionTypes.SET_TITLE:
      return Object.assign({}, state, { title: 'CampusTrack' });
    case app.ActionTypes.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        userLoggedIn: action.payload.userLoggedIn, ctContext: extend_ctContext(action.payload.ctContext), userInfo: get_user_info(action.payload.ctContext)
      });
    case app.ActionTypes.SET_ACCESS_CODE:
      return Object.assign({}, state, { accessCode: environment.ACCESS_CODE });
    case app.ActionTypes.SET_ACCESS_TOKEN:
      return Object.assign({}, state, { accessToken: action.payload.accessToken });
    case app.ActionTypes.INIT:
      return Object.assign({}, initialState, { title: 'CampusTrack', accessCode: environment.ACCESS_CODE });
    case app.ActionTypes.REC_ACT:
      const new_ra_list = get_new_ra_list(action.payload, state);
      return Object.assign({}, state, { recentActivities: new_ra_list });
    case app.ActionTypes.SET_NOTIFICATION:
      return Object.assign({}, state, { notifications: get_notifs(action.payload) });
    case app.ActionTypes.START_REQ:
      return Object.assign({}, state, { reqDate: action.payload });
    case app.ActionTypes.LOGOUT:
      return Object.assign({}, state, { userLoggedIn: false, ctContext: null, userInfo: null, accessToken: null });
    case app.ActionTypes.SET_USER_LINKS:
      return Object.assign({}, state, { userLinks: action.payload.userLinks });
    case app.ActionTypes.SET_TAB:
      return Object.assign({}, state, { tab: action.payload.tab });
    case app.ActionTypes.PULSE:
      return Object.assign({}, state, { wfResponse: action.payload.wfResponse });
    case app.ActionTypes.SET_USER_UPDATED_DATA:
      return Object.assign({}, state, { userData: action.payload.userData });
    case app.ActionTypes.USER_CHANNEL_JOINED:
      return Object.assign({}, state, { userChannelJoined: action.payload.userChannelJoined });
    case app.ActionTypes.SET_OTP_TIMER:
      return Object.assign({}, state, { showTimer: action.payload.showTimer });
    case app.ActionTypes.SET_TOPICS:
      return Object.assign({}, state, { topicList: setTopicList(action.payload.topicList) });
    case app.ActionTypes.SET_MAIN_TOPICS:
      return Object.assign({}, state, { mainTopicList: setTopicList(action.payload.mainTopicList) });
    case app.ActionTypes.DISABLE_TOPIC:
      return Object.assign({}, state, { topicList: disableTopic(state.topicList, action.payload.id) });
    case app.ActionTypes.ENABLE_TOPIC:
      return Object.assign({}, state, { topicList: enableTopic(state.topicList, action.payload.id) });
    case app.ActionTypes.RESET_TAB:
      return Object.assign({}, state, { resetTab: action.payload });
    case app.ActionTypes.COPY_ITEM:
      return Object.assign({}, state, { clipboard: copyToClipboard(state.clipboard, action.payload) });
    case app.ActionTypes.REMOVE:
      return Object.assign({}, state, { clipboard: removeFromClipboard(state.clipboard, action.payload) });
    case app.ActionTypes.SELECT:
      return Object.assign({}, state, { copyItem: addCopyItem(state.copyItem, action.payload) });
    case app.ActionTypes.UNSELECT:
      return Object.assign({}, state, { copyItem: removeCopyItem(state.copyItem, action.payload) });
    case app.ActionTypes.SET_NB_DISPLAY:
      return Object.assign({}, state, { selectedNBDisplay: action.payload });
    case app.ActionTypes.SET_SELECTED_TOPIC:
      return Object.assign({}, state, { selectedTopic: action.payload.selectedTopic });
    case app.ActionTypes.APPEND_AI_CHAT:
      return Object.assign({}, state, { aiChat: appendAIChat(state.aiChat, action.payload) });
    case app.ActionTypes.RESET_AI_CHAT:
      return Object.assign({}, state, { aiChat: [] });
    case app.ActionTypes.SET_COMMANDS:
      return Object.assign({}, state, { commands: action.payload.commands });
    case app.ActionTypes.SET_CHAT_OPTIONS:
      return Object.assign({}, state, { chatOptions: action.payload.chatOptions });
    case app.ActionTypes.SET_ACK:
      return Object.assign({}, state, { indx: action.payload.indx });
    case app.ActionTypes.SET_SIGNIN_PAGE:
      return Object.assign({}, state, { signInPage: action.payload.signInPage });
    case app.ActionTypes.RESET_PASSWORD_EMAIL_SENT:
      return Object.assign({}, state, { resetPasswordEmailSent: action.payload.resetPasswordEmailSent });
    case app.ActionTypes.UPDATE_APP_STATUS:
      return Object.assign({}, state, { appStatus: action.payload });
    case app.ActionTypes.SET_TOPICS_STATS:
      return Object.assign({}, state, { topicsStats: action.payload });
    case app.ActionTypes.SET_TOPICS_STATS_MAPED:
      return Object.assign({}, state, { topicsStatsMaped: action.payload });
    case app.ActionTypes.SET_SCHEDULED_PUBLISHES:
      return Object.assign({}, state, { scheduledPublishes: action.payload });
    case app.ActionTypes.SET_IS_SIDEBAR_OPEN:
      return Object.assign({}, state, { isSidebarOpen: action.payload });
    case app.ActionTypes.SET_SIDEBAR_COMPONENT_INFO:
      return Object.assign({}, state, { sidebarComponentInfo: getSidebarComponentInfo(state.sidebarComponentInfo, action.payload) });
    case app.ActionTypes.SET_ALL_FORMULAS:
      return Object.assign({}, state, { all_formulas: action.payload });
    default:
      return state;
  }
}

export const get_state_app = (state: State) => state.ctContext;
export const getUserStatus = (state: State) => state.userLoggedIn;
export const get_rec_act = (state: State) => state.recentActivities;
export const get_notifications = (state: State) => state.notifications;
export const get_accessToken = (state: State) => state.accessToken;
export const get_user_links = (state: State) => state;
export const get_reqDate = (state: State) => state.reqDate;
export const getTab = (state: State) => state.tab;
export const pulse = (state: State) => state.wfResponse;
export const get_user_updated_data = (state: State) => state.userData;
export const user_channel_joined = (state: State) => state.userChannelJoined;
export const get_states_data = (state: State) => state.statesData;
export const get_otp_timer = (state: State) => state.showTimer;
export const get_topic_list = (state: State) => state.topicList;
export const get_main_topic_list = (state: State) => state.mainTopicList;
export const get_reset_tab = (state: State) => state.resetTab;
export const get_clipboard = (state: State) => state.clipboard;
export const get_copy_items = (state: State) => state.copyItem;
export const get_selected_nb_display = (state: State) => state.selectedNBDisplay;
export const get_selected_topic = (state: State) => state.selectedTopic;
export const get_ai_chat = (state: State) => state.aiChat;
export const get_commands = (state: State) => state.commands;
export const get_chat_options = (state: State) => state.chatOptions;
export const get_ack_indx = (state: State) => state.indx;
export const set_sign_in_page = (state: State) => state.signInPage;
export const reset_password_email_sent = (state: State) => state.resetPasswordEmailSent;
export const get_app_status = (state: State) => state.appStatus;
export const get_topics_stats = (state: State) => state.topicsStats;
export const get_topics_stats_maped = (state: State) => state.topicsStatsMaped;
export const get_scheduled_publishes = (state: State) => state.scheduledPublishes;
export const get_is_sidebar_open = (state: State) => state.isSidebarOpen;
export const get_sidebar_component_info = (state: State) => state.sidebarComponentInfo;
export const get_all_formulas = (state: State) => state.all_formulas;