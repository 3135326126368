import { Component, HostListener, OnInit } from '@angular/core';
import { AppService } from '../../service/app.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, get_ai_chat } from '../../rootReducer';
import { AIChat } from '../../service/app.interfaces';
import { AppendAIChat, ResetAIChat } from '../../store/app-action';
import { PulseService } from '../../service/pulse';

@Component({
  selector: 'app-ai-chat',
  templateUrl: './ai-chat.component.html',
  styleUrls: ['./ai-chat.component.css']
})
export class AiChatComponent implements OnInit {
  aiChat: Array<AIChat> = [
    {
      user: "AI",
      message: "Hello, how can I help you?",
      ts: new Date().getTime(),
      error: false
    },
    {
      user: "User",
      message: "I need help with my account",
      ts: new Date().getTime(),
      error: false
    },
    {
      user: "AI",
      message: "Sure, what do you need help with?",
      ts: new Date().getTime(),
      error: false
    }
  ]
  userPrompt: string = "";
  private readonly subscription = new Subscription();
  readonly shortcutsInfo: string = `Shortcuts:\n` +
    `&nbsp; Ctrl + I: To toggle AI chat\n` +
    `&nbsp; Ctrl + Enter: To post prompt\n` +
    `&nbsp; Esc: To clear the text in the input field\n` +
    `&nbsp; Ctrl + Q: To reset the conversation, clear prompts\n`;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // Ctrl + I: To toggle AI chat
    if (event.ctrlKey) {
      if (event.code === 'Enter') {
        this.ut_post();
      } else if (event.code === 'KeyQ') {
        this.ut_reset();
      }
    } else {
      if (event.code === 'Escape') {
        this.userPrompt = "";
      }
    }
  }

  constructor(public appService: AppService, private store: Store<State>, private pulseService: PulseService) { }

  ngOnInit() {
    this.sub_store();
  }
  private sub_store() {
    this.subscription.add(this.store.select(get_ai_chat).subscribe(res => {
      if (res) {
        this.aiChat = res;
      }
    }));
  }

  ut_post() {
    if (this.userPrompt) {
      this.userPrompt = this.userPrompt.trim();
      const aiChat: AIChat = {
        user: "User", message: this.userPrompt, ts: new Date().getTime(), error: false
      }
      this.store.dispatch(new AppendAIChat(aiChat));
      this.pulseService.postPromptToAIChat(this.userPrompt);
      // this.aiChat.push({
      //   user: "AI", message: "Please wait while I process your request."
      // });
      this.userPrompt = "";
    }
  }
  ut_reset() {
    this.aiChat = [];
    this.store.dispatch(new ResetAIChat({}));
  }
}
