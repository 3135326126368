import { Component, TemplateRef, OnInit } from '@angular/core';
import { AppService } from '../../../app/service/app.service';
import { DexService } from '../services/dex.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Location } from '@angular/common';
import { DEX_CONTEXTS } from '../services/constant';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, get_sidebar_component_info } from '../../rootReducer';

class Model {
  queryTitle: string = "";
  queryDescription: string = ""
}
@Component({
  selector: 'app-dex-sidebar-header',
  templateUrl: './dex-sidebar-header.component.html',
  styleUrls: ['./dex-sidebar-header.component.css']
})
export class DexSidebarHeaderComponent implements OnInit {
  modalRef: BsModalRef;
  readonly m: Model = new Model();
  readonly dexPages = Object.values(DEX_CONTEXTS);
  currentDexPageContext = this.dexPages[0].context;
  readonly subs = new Subscription();

  constructor(private appService: AppService, public dexService: DexService,
    private modalService: BsModalService, private location: Location, private store: Store<State>) { }

  ngOnInit() {
    this.subs.add(this.store.select(get_sidebar_component_info).subscribe(res => {
      if (this.appService.sidebarComponentInfo.context) {
        this.currentDexPageContext = this.appService.sidebarComponentInfo.context + "";
      }
    }));
  }
  ut_goBack() {
    this.location.back();
  }
  ut_openSaveModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  ut_saveQuery() {
    this.dexService.saveQuery(this.m.queryTitle, this.m.queryDescription);
  }
  ut_updateQuery() {
    this.dexService.updateQuery();
  }
  ut_deleteQuery() {
    this.dexService.deleteQuery();
  }
  ut_changeContext() {
    this.appService.ut_openDexTool(this.currentDexPageContext);
  }
  ut_downloadCSV() {
    this.dexService.downloadCSV();
  }
}
