import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy, HostListener, TemplateRef, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { AcademicsService as service, WindowRef } from '../../academics/service/service';
import { ClipboardService as clipboardService } from '../../service/clipboard-service';
import * as _ from 'underscore';
import * as LC from 'literallycanvas';
import { FileUploader } from 'ng2-file-upload';
import 'brace/index';
import 'brace/theme/eclipse';
import 'brace/mode/markdown';
import 'brace/ext/language_tools';
import * as marked from 'marked';
import * as stopWords from '../../../../assets/dummy/stop-words.json';
import { KatexOptions, MarkdownService } from 'ngx-markdown';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, Subject, BehaviorSubject } from 'rxjs';
import * as rootReducer from '../../rootReducer';
import * as actions from '../../academics/store/action';
import * as appAction from '../../store/app-action';
import { Router, NavigationStart } from '@angular/router';
import { _fixedSizeVirtualScrollStrategyFactory } from '@angular/cdk/scrolling';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Location } from '@angular/common';
import { AppService } from '../../service/app.service';
import { VxService } from './vx.service';
import * as R from 'ramda';
import { debounceTime } from 'rxjs/operators';
import { CTApi } from '../../../app/service/ct-api';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';

declare var $: any;

class Model {
  expand: boolean = true;
  lxStatusUpdated: boolean = false;
  studentVisibilityUpdated: boolean = false;
  isUrlUpdated: boolean = false;
  copyMessage: string = "Copied";
  isDirty: boolean = false;
  treeCopy: any = {};
  nodes: any[] = [];
  title: string = "";
  lxTitleCopy: string = "";
  key: string = "children";
  selectedNode: any = {};
  ytUrlObj: any = {};
  ytUrlEventObj: any = {};
  ytUrlMsg: any = {};
  nextNode: any = null;
  prevNode: any = null;
  navItems: any = null;
  showTab: boolean = false;
  topics: any[] = [];
  currentPersona: any;
  copyItem: any;
  nodeSummaryList: any[] = [];
  treeSummaryList: any[] = [];
  showTreeSummary: boolean = false;
  checkExit: boolean = false;
  thStyle: string = `<!DOCTYPE html>
  <html lang="en">
  <head>
  <base target="_blank">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  
  <link rel="stylesheet" href="../../assets/styles/lx-view.css">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/css/bootstrap.min.css"
    integrity="sha512-dTfge/zgoMYpP7QbHy4gWMEGsbsdZeCXz7irItjcC3sPUFtf0kuFbDz/ixG7ArTxmDjLXDmezHubeNikyKGVyQ=="
    crossorigin="anonymous">
    <script src="https://code.jquery.com/jquery-3.1.1.min.js"
    integrity="sha256-hVVnYaiADRTO2PzUGmuLJr8BLUSjGIZsDYGmIJLv2b8=" crossorigin="anonymous"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/js/bootstrap.min.js"
    integrity="sha512-K1qjQ+NcF2TYO/eI3M6v8EiNYZfA95pQumfvcVrTHtwQVDG+aHRqLi/ETn2uB+1JqwYqVG3LIvdm9lj6imS/pQ=="
    crossorigin="anonymous"></script>
  </head>
  <body>`;
  closingTags: string = `</body>
   </html>`;
  getColor(visibility) {
    switch (visibility) {
      case true:
        return '#5cb85c';
      case false:
        return 'white';
      default:
        return 'white';
    }
  };
  getTitle(visibility) {
    switch (visibility) {
      case true:
        return 'Section visible to student';
      case false:
        return 'Section not visible to student';
      default:
        return 'Section not visible to student';
    }
  };
  getTitleStatus(status) {
    switch (status) {
      case true:
        return 'Completed';
      case false:
        return 'WIP';
      default:
        return 'WIP';
    }
  };
}

const URL = '';

@Component({
  selector: 'vx',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
  providers: [service, WindowRef, clipboardService],
})
export class VxComponent implements OnInit, OnDestroy {
  @HostListener('keyup.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.target instanceof Element && ((event.target as Element).tagName).toLowerCase() == "input") return;

    this.vxService.eventsSubject.next();
    this.closeAllEditors();
    this.discardTitleEdit();
    this.debouncePdfLoad();
  }
  @HostListener('window:beforeunload', ["$event"]) unload(event) {
    //calling api
    //this.canExit();

    event.preventDefault();
    event.returnValue = false;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.ctrlKey) {
      if (event.code === 'BracketRight') {
        this.expandAllSections();
      } else if (event.code === 'BracketLeft') {
        this.collapseAllSections();
      }
    }
  }
  ts: string = "1";
  nativeWindow: any;
  public options: KatexOptions = {
    displayMode: false,
    throwOnError: false,
    errorColor: '#cc0000',
  };
  modalRef: BsModalRef;
  constructor(private store: Store<rootReducer.State>,
    private el: ElementRef,
    public service: service,
    public clipboardService: clipboardService,
    private markdownService: MarkdownService,
    private router: Router,
    private winRef: WindowRef,
    private appService: AppService,
    public vxService: VxService,
    private modalService: BsModalService, private location: Location,
    private ctApi: CTApi, private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.nativeWindow = winRef.getNativeWindow();
  }
  @Input() topic: any = { title: "", nodes: [], itemId: +new Date(), status: "active", htmlText: "", sha1: "" };
  @Input() title: string;
  @Input() description: string;
  @Input() fileId: string = "";
  @Input() fileName: string;
  @Input() attributeName: string;
  @Input() backNavPath: string;
  @Input() selNode: any = null;
  @Input() path: string = "";
  @Input() canEdit: boolean = true;
  @Input() canPrint: boolean = true;
  public m: Model;
  dropLxId: any;
  public popoverTitle: string = 'Confirm Delete';
  public popoverMessage: string = '';
  public confirmClicked: boolean = false;
  public cancelClicked: boolean = false;
  public confirmText: string = 'Yes';
  public cancelText: string = 'No';
  public sub1: Subscription;
  lxStatusList: any[] = [];
  @ViewChild('pdfArea', { read: ViewContainerRef }) pdfContainerRef: ViewContainerRef;


  ngOnInit() {
    this.init_model();
    // initialize vx service
    this.vxService.canEdit = this.canEdit;
    this.vxService.setTreeData(this.topic);
    // this.vxService.tree = this.topic;
    this.m.treeCopy = JSON.parse(JSON.stringify(this.vxService.tree));
    if (this.vxService.tree.title) { this.vxService.showSave = false; }
    this.sub_store();
    if (this.selNode && this.selNode.itemId) {
      this.selectedNode(this.selNode);
    } else {
      this.showTreeSummary();
    }
    this.ut_openLMSTools();
    this.debouncePdfLoad();
  }
  private async checkAndLoadPdfs() {
    const pdfArea = document.getElementById('pdfArea');
    if (pdfArea) {
      const iTags = pdfArea.getElementsByTagName('i');
      if (iTags && iTags.length > 0) {
        for (let i = 0; i < iTags.length; i++) {
          // check if the i tag has a title with .pdf in it
          if (iTags[i].title && iTags[i].title.includes('.pdf')) {
            const pdfFileName = iTags[i].title;
            const id = iTags[i].className;
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PdfViewerComponent);
            this.pdfContainerRef.clear();
            const componentRef = this.pdfContainerRef.createComponent(componentFactory);
            componentRef.instance.pdfId = id;
            iTags[i].innerHTML = '';
            iTags[i].appendChild(componentRef.location.nativeElement);
            /*
            New Approach above
            this.ctApi.getS3FileLink(pdfFileName).subscribe((res) => {
              const pdfUrl = res.data;
              // insert the pdf object into the i tag
              let pdfHtml = `<a href="${pdfUrl}" target="blank">Full Screen</a><br>`;
              pdfHtml += `<object data="${pdfUrl}" type="application/pdf" width="50%" height="50%"><p>It appears you don't have a PDF plugin for this browser. No biggie... you can <a href="${pdfUrl}">click here to download the PDF file.</a></p></object>`;
              // pdfHtml += `<iframe src="${url}" type="application/pdf" width="20vw" height="50vh"><p>It appears you don't have a PDF plugin for this browser. No biggie... you can <a href="${url}">click here to download the PDF file.</a></p></iframe>`;
              // pdfHtml += `<embed src="${url}" type="application/pdf" width="20vw" height="50vh"><p>It appears you don't have a PDF plugin for this browser. No biggie... you can <a href="${url}">click here to download the PDF file.</a></p></embed>`;
              iTags[i].innerHTML = pdfHtml;
            });
            */
          }
        }
      }
    }
  }
  pdfDebouncerId: any;
  private debouncePdfLoad() {
    if (this.pdfDebouncerId) {
      clearTimeout(this.pdfDebouncerId);
    }
    this.pdfDebouncerId = setTimeout(() => {
      this.checkAndLoadPdfs();
    }, 3500);
  }
  ngOnDestroy() {
    this.sub1.unsubscribe();
    this.appService.changeSidebarComponent(false);
  }
  init_model() {
    this.m = new Model();
  }
  sub_store() {
    this.sub1 = this.store.select(rootReducer.get_topics).subscribe(curriculum => {
      if (curriculum && curriculum[this.attributeName] && curriculum[this.attributeName].length > 0) {
        this.m.topics = curriculum[this.attributeName];
      }
    });
    this.store.select(rootReducer.get_state_app).subscribe(state => {
      if (state)
        this.m.currentPersona = state.currentPersona;
    });
    this.store.select(rootReducer.get_copy_items).subscribe(res => {
      if (res && res.items) {
        this.m.copyItem = res.items;
      }
    });
    this.vxService.vxActionsEventEmitter
      .pipe(debounceTime(1000)) // Debounce for 1 second (1000 milliseconds)
      .subscribe((v: any) => {
        switch (v.action) {
          case 'ut_editTitle':
            this.editTitle();
            break;
          case 'ut_showTreeSummary':
            this.showTreeSummary();
            break;
          case 'ut_updateTitle':
            this.updateTitle();
            break;
          case 'ut_discardTitleEdit':
            this.discardTitleEdit();
            break;
          case 'ut_selectedNode':
            this.selectedNode(v.e);
            break;
          case 'ut_deletedNode':
            this.deletedNode(v.e);
            break;
          case 'ut_nodeEdit':
            this.nodeEdit(v.e);
            break;
          case 'ut_addNode':
            this.addNode();
            break;
          case 'ut_update':
            this.update(v.e);
            break;
          case 'ut_addTopic':
            this.addTopic(v.e);
            break;
          default:
            break;
        }
      })
  }
  selectedNode(ev) {
    this.m.showTreeSummary = false;
    this.discardTitleEdit();
    this.m.selectedNode = ev;
    this.vxService.selectedNode = this.m.selectedNode;
    // this.setNavItems(this.vxService.tree.nodes, this.m.selectedNode);
    this.m.navItems = { prevNode: this.m.prevNode, currentNode: this.m.selectedNode, nextNode: this.m.nextNode };

    this.vxService.navItems = this.m.navItems;

    this.m.nodeSummaryList = this.getNodeSummary(this.m.selectedNode);
    this.m.showTab = true;
    this.closeAllEditors();
    if (this.m.selectedNode.lxList) {
      this.m.selectedNode.lxList.forEach(lx => {
        lx.status == 'complete' ? lx.toggle = true : lx.toggle = false;
      });
    }
    this.debouncePdfLoad()
  }
  searchNode(nodeList, selectedNode) {
    for (let [index, node] of nodeList.entries()) {
      if (node.children && node.children.length) {
        let n = node.children.find(c => c.itemId == selectedNode.itemId);
        if (n) {
          return node;
        }
      }
      if (node.children && node.children.length > 0) { this.setNavItems(node.children, selectedNode); }
    }
  }
  searchNextNode(nodeList, selectedNode) {
    for (let [index, node] of nodeList.entries()) {
      if (node.itemId == selectedNode.itemId) {
        if (index < nodeList.length - 1) {
          return nodeList[index + 1]
        }
      }
      if (node.children && node.children.length > 0) { this.searchNextNode(node.children, selectedNode); }
    }
  }
  setNavItems(nodes, selectedNode) {
    for (let [index, node] of nodes.entries()) {
      if (node.itemId == selectedNode.itemId) {
        if ((index > 0) && (index < nodes.length - 1)) {
          let pNode = nodes[index - 1];
          if (pNode && pNode.expand && pNode.children && pNode.children.length) {
            this.m.prevNode = _.last(pNode.children);
          }
          else {
            this.m.prevNode = pNode;
          }
          if (selectedNode.expand && selectedNode.children && selectedNode.children.length) {
            this.m.nextNode = selectedNode.children[0];
          }
          else {
            this.m.nextNode = nodes[index + 1];
          }
        }
        else if (index == 0) {
          if (selectedNode.expand && selectedNode.children && selectedNode.children.length) {
            this.m.nextNode = selectedNode.children[0];
          }
          else {
            if (index < nodes.length - 1) {
              this.m.nextNode = nodes[index + 1];
            }
            else {
              let pNode = this.searchNode(this.vxService.tree.nodes, node);
              if (pNode) {
                let nNode = this.searchNextNode(this.vxService.tree.nodes, pNode);
                if (nNode) {
                  this.m.nextNode = nNode;
                }
                else {
                  this.m.nextNode = this.vxService.tree.nodes[0];
                }
              }
              else { this.m.nextNode = nodes[index + 1]; }
            }
          }
          let parentNode = this.searchNode(this.vxService.tree.nodes, node);
          if (parentNode) {
            if (parentNode && parentNode.expand && parentNode.children && parentNode.children.length && !selectedNode.isLeaf) {
              this.m.prevNode = _.last(parentNode.children);
            } else { this.m.prevNode = parentNode; }
          } else {
            let pNode = this.vxService.tree.nodes[this.vxService.tree.nodes.length - 1];
            if (pNode && pNode.expand && pNode.children && pNode.children.length && !selectedNode.isLeaf) {
              this.m.prevNode = _.last(pNode.children);
            } else {
              this.m.prevNode = pNode;
            }
          }
        }
        else if (index == nodes.length - 1) {
          if (selectedNode.expand && selectedNode.children && selectedNode.children.length) {
            this.m.nextNode = selectedNode.children[0];
          }
          else {
            let parentNode = this.searchNode(this.vxService.tree.nodes, node);
            if (parentNode) {
              let nNode = this.searchNextNode(this.vxService.tree.nodes, parentNode);
              if (nNode) { this.m.nextNode = nNode; }
              else { this.m.nextNode = this.vxService.tree.nodes[0]; }
            }
            else { this.m.nextNode = this.vxService.tree.nodes[0]; }
          }
          // this.m.prevNode = nodes[index - 1];
          let pNode = nodes[index - 1];
          if (pNode && pNode.expand && pNode.children && pNode.children.length) {
            this.m.prevNode = _.last(pNode.children);
          }
          else {
            this.m.prevNode = pNode;
          }
        }
        break;
      }
      if (node.children && node.children.length > 0) { this.setNavItems(node.children, selectedNode); }
    }
  }
  deletedNode(ev) {
    if (this.m.selectedNode && ev && ev.isLeaf && (this.m.selectedNode.itemId == ev.itemId)) {
      this.m.selectedNode = {};
      this.m.showTab = false;
    }
    else if (this.m.selectedNode && ev && !ev.isLeaf && ev.children && ev.children.length > 0) {
      if (JSON.stringify(ev).includes(this.m.selectedNode.itemId)) {
        this.m.selectedNode = {};
        this.m.showTab = false;
      }
    }
  }
  updateQ(event) {
    if (event) {
      this.m.selectedNode.summary.text = event.q;
    }
  }
  updateContent(event) {
    if (event) {
      this.m.selectedNode.lxList[event.index].content.text = event.q;
    }
  }
  ut_setVisibility(index) {
    let data = this.m.selectedNode.lxList[index];
    if (data.studentVisibility == true) data.studentVisibility = false;
    else {
      let obj = { studentVisibility: true };
      this.m.selectedNode.lxList[index] = Object.assign({}, data, obj);
    }
    this.m.studentVisibilityUpdated = true;
  }

  ut_toggle(index, toggle) {
    let data = this.m.selectedNode.lxList[index];
    if (toggle == true) data.status = 'complete';
    else {
      let obj = { status: 'wip' };
      this.m.selectedNode.lxList[index] = Object.assign({}, data, obj);
    }
    this.m.lxStatusUpdated = true;
    let status = this.m.selectedNode.lxList[index].status;
    let itemId = this.m.selectedNode.lxList[index].itemId;
    let payload = { Key: 'status', Value: status, ItemId: itemId };
    this.lxStatusList.push(payload);
    //this.service.updateDataAttributes(payload).subscribe();
    this.debouncePdfLoad();
  }

  ut_removeLx(index) {
    delete this.m.ytUrlObj[index];
    this.m.selectedNode.lxList.splice(index, 1);
    let lxOb = { id: "", title: "", content: { text: "", htmlText: "", plainText: "" }, preview: true, itemId: +new Date() };
    if (this.m.selectedNode.lxList.length == 0) {
      this.m.selectedNode.lxList.push(lxOb);
    }
  }
  ut_addLx(index, lx, e) {
    if (e.ctrlKey) {
      this.m.selectedNode.lxList.forEach(q => {
        q.preview = true;
      });
      let ytOb = { id: "", title: "", content: { text: "", htmlText: "", plainText: "", ytURL: "", isYtURL: true }, preview: false, itemId: +new Date() };
      this.m.ytUrlObj[index + 1] = "";
      this.m.selectedNode.lxList.splice(index + 1, 0, ytOb);
    }
    else {
      this.m.selectedNode.lxList.forEach(q => {
        q.preview = true;
      });
      let lxOb = { id: "", title: "", content: { text: "", htmlText: "", plainText: "" }, preview: false, itemId: +new Date() };
      this.m.selectedNode.lxList.splice(index + 1, 0, lxOb);
    }
    // else {
    //   this.editLx(lx); }
  }
  ut_getUserURL(index, e) {
    if (e.target.value || e.target.value == "") {
      this.m.ytUrlObj[index] = e.target.value;
      this.m.ytUrlEventObj[index] = e || {};
    }
  }
  ut_userURL(index, e) {
    if (this.m.ytUrlObj[index]) {
      if (this.m.ytUrlObj[index].search(" ") != -1) {
        this.m.ytUrlObj[index] = this.m.ytUrlObj[index].trim();
      }
      if (this.m.ytUrlObj[index].search("http") == -1) {
        this.m.ytUrlObj[index] = "http://" + this.m.ytUrlObj[index].trim();
      }
      try {
        let uri = this.m.ytUrlObj[index] || "";
        if (uri === decodeURIComponent(uri)) {
          this.m.ytUrlObj[index] = encodeURI(this.m.ytUrlObj[index]);
        }
        this.m.ytUrlMsg[index] = "";
      } catch (e) {
        this.m.ytUrlMsg[index] = "Invalid url: " + this.m.ytUrlObj[index];
        this.m.ytUrlObj[index] = "";
      }
      if (this.m.ytUrlObj[index]) {
        this.m.selectedNode.lxList[index].content.ytURL = this.m.ytUrlObj[index].trim();
      } else {
        this.m.ytUrlEventObj[index].target.value = "";
        this.m.selectedNode.lxList[index].content.ytURL = "";
      }
    } else if (this.m.ytUrlObj[index] == "") {
      this.m.selectedNode.lxList[index].content.ytURL = "";
    }
    this.m.isUrlUpdated = true;
  }
  focus() {
  }
  focusOut(ev, lx) {
    this.debouncePdfLoad();
    ev.preventDefault();
    ev.stopPropagation();
    if (lx.text) {
      lx.text = lx.text.trim();
    }
    if (lx.content && lx.content['isYtURL']) {
      ev.target.blur();
    }
    lx.preview = true;
  }
  focusOutLx(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (this.m.selectedNode.lxList && this.m.selectedNode.lxList.length > 0) {
      this.m.selectedNode.lxList.forEach(lx => {
        lx.preview = true;
      });
    }
  }
  addNode() {
    let node = { name: "New Node", expand: false, children: [], isLeaf: false, itemId: +new Date() };
    this.vxService.tree.nodes.push(node);
  }
  allowDrop(ev) {
    ev.preventDefault();
  }
  dragLx(evt, data) {
    if (this.canEdit) {
      evt.dataTransfer.setData("text", JSON.stringify(data));
    }
  }
  dropLx(ev) {
    if (this.canEdit) {
      ev.preventDefault();
      var data = ev.dataTransfer.getData("text");
      var lxData = JSON.parse(data);
      let dragLxIndex = this.m.selectedNode.lxList.findIndex(
        q => q.itemId == lxData.itemId
      );
      let dropLocationIndex = this.m.selectedNode.lxList.findIndex(
        q => q.itemId == this.dropLxId
      );
      if (this.m.selectedNode.lxList.length >= 1) {
        var temp = this.m.selectedNode.lxList[dragLxIndex];
        this.m.selectedNode.lxList[dragLxIndex] = this.m.selectedNode.lxList[dropLocationIndex];
        this.m.selectedNode.lxList[dropLocationIndex] = temp;
      }
    }
  }
  dragEnter(ev, lx) {
    if (this.canEdit) {
      if (lx) {
        this.dropLxId = lx.itemId;
      }
    }
  }
  editTitle() {
    if (this.canEdit) {
      this.vxService.editTitle = true;
      this.vxService.titleCopy = this.vxService.tree.title;
    }
  }
  updateTitle() {
    if (this.vxService.titleCopy) {
      this.vxService.tree.title = this.vxService.titleCopy;
      this.vxService.titleCopy = "";
      this.vxService.editTitle = false;
      this.vxService.showTitleErr = false;
      if (this.vxService.tree.nodes.length < 1) { this.addNode(); }
    } else { this.vxService.showTitleErr = true; }
  }
  discardTitleEdit() {
    this.vxService.editTitle = false;
    this.vxService.showTitleErr = false;
  }
  editNodeInfo() {
    if (this.canEdit) {
      this.vxService.eventsSubject.next();
      this.m.selectedNode.summary.preview = false;
      if (this.m.selectedNode.lxList && this.m.selectedNode.lxList.length > 0) {
        this.m.selectedNode.lxList.forEach(lx => {
          lx.preview = true;
        });
      }
    }
  }
  editLx(lx, e = null) {
    if (this.canEdit) {
      this.vxService.eventsSubject.next();
      this.m.selectedNode.summary.preview = true;
      if (this.m.selectedNode.lxList && this.m.selectedNode.lxList.length > 0) {
        this.m.selectedNode.lxList.forEach(lx => {
          lx.preview = true;
        });
      }
      lx.preview = false;
    }
    if (e != null) {
      if (e.target.querySelector('input')) {
        e.target.querySelector('input').focus();
      }
    }
  }
  editLxTitle(lx) {
    if (this.canEdit) {
      lx.editTitle = true;
      this.m.lxTitleCopy = lx.title;
    }
  }
  updateLxTitle(lx) {
    if (this.canEdit) {
      lx.title = this.m.lxTitleCopy;
      this.m.lxTitleCopy = "";
      lx.editTitle = false;
    }
  }
  discardLxTitleUpdate(lx) {
    lx.editTitle = false;
    this.m.lxTitleCopy = "";
  }
  addTopic(template: TemplateRef<any>) {
    try {
      if (this.vxService.tree.title) {
        this.vxService.tree.modifiedOn = new Date();
        if (this.vxService.tree.nodes && this.vxService.tree.nodes.length > 0) {
          this.formatData(this.vxService.tree.nodes);
          this.setTreeHtml(this.vxService.tree);
          this.isDirty().then((isDirty) => {
            if (isDirty) {
              var jsonStr = JSON.stringify(this.vxService.tree);
              var jsonBlob = new Blob([jsonStr], { type: "application/json" });
              var jsonFile = new File([jsonBlob], this.fileName, { type: "application/json" });
              const json_meta = {
                'Title': this.vxService.tree.title,
                'Description': this.description,
                'MetaData': {}
              };
              let pathName = this.path + "/" + this.fileName;
              this.service.saveVx(jsonFile, json_meta, this.fileId, pathName).subscribe(res => {
                if (res && res['id']) {
                  this.m.treeCopy = JSON.parse(JSON.stringify(this.vxService.tree));
                  this.fileId = res['id'];
                }
              })
            }
          });
        }
      }
    }
    catch (e) {
      if (e && e.name == "ParseError") {
        this.errorModal(template);
      }
    }
  }
  formatData(nodes) {
    nodes.forEach(node => {
      node.htmlText = "";
      node.expand = false;
      node.selected = false;
      if (node.isLeaf && node.lxList && node.lxList.length > 0) {
        if (node.summary.text && node.summary.text.length > 0) {
          node.summary.htmlText = this.getHtmlText(node.summary.text);
          node.htmlText += node.summary.htmlText;
        }
        node.lxList.forEach(lx => {
          lx.content.htmlText = this.getHtmlText(lx.content.text);
          lx.htmlText = "<section><h3>" + lx.title + "</h3>" + lx.content.htmlText + '</section>';
          node.htmlText += lx.htmlText;
        });

      }
      if (node.children && node.children.length > 0) { this.formatData(node.children); }
    });
  }
  setTreeHtml(tree) {
    if (tree.nodes && tree.nodes.length > 0) {
      tree.htmlText = "";
      tree.nodes.forEach(node => {
        node.htmlText = this.getNodeHtml(node);
        if (node.htmlText && node.htmlText.length > 0) { tree.htmlText += node.htmlText; }
      });
      tree.htmlText = '<h1>' + tree.title + '</h1>' + '<main>' + tree.htmlText + '</main>';
    }
  }
  update(template: TemplateRef<any>) {
    try {
      if (this.vxService.tree.title) {
        this.vxService.tree.modifiedOn = new Date();
        if (this.vxService.tree.nodes && this.vxService.tree.nodes.length > 0) {
          this.formatData(this.vxService.tree.nodes);
          this.setTreeHtml(this.vxService.tree);
          this.isDirty().then((isDirty) => {
            if (isDirty) {
              const cleanedObjects = R.clone(this.vxService.tree);
              cleanedObjects.parent = null;
              // to avoid circular reference
              this.removeTreeParent(cleanedObjects.nodes);
              // just for internal testing
              // const isCircular: boolean = this.detectCircular(cleanedObjects.nodes);
              // console.log({ isCircular });
              const jsonStr = JSON.stringify(cleanedObjects)
              // var jsonStr = JSON.stringify(this.vxService.tree);
              var jsonBlob = new Blob([jsonStr], { type: "application/json" });
              var jsonFile = new File([jsonBlob], this.fileName, { type: "application/json" });
              const json_meta = {
                'Title': this.vxService.tree.title,
                'Description': this.description,
                'MetaData': {}
              };
              let pathName = this.path + "/" + this.fileName;
              this.service.saveVx(jsonFile, json_meta, this.fileId, pathName).subscribe(res => {
                if (res && res['id']) {
                  this.m.treeCopy = R.clone(cleanedObjects);
                  // this.m.treeCopy = JSON.parse(JSON.stringify(this.vxService.tree));
                  this.fileId = res['id'];
                }
              })
            }
          });
          if (this.m.studentVisibilityUpdated || this.m.lxStatusUpdated || this.m.isUrlUpdated) {
            var jsonStr = JSON.stringify(this.vxService.tree);
            var jsonBlob = new Blob([jsonStr], { type: "application/json" });
            var jsonFile = new File([jsonBlob], this.fileName, { type: "application/json" });
            const json_meta = {
              'Title': this.vxService.tree.title,
              'Description': this.description,
              'MetaData': {}
            };
            let pathName = this.path + "/" + this.fileName;
            this.service.saveVx(jsonFile, json_meta, this.fileId, pathName).subscribe(res => {
              if (res && res['id']) {
                this.m.treeCopy = JSON.parse(JSON.stringify(this.vxService.tree));
                this.fileId = res['id'];
                this.m.studentVisibilityUpdated = false;
                this.m.lxStatusUpdated = false;
                this.m.isUrlUpdated = false;
              }
            })
            if (this.m.lxStatusUpdated) {
              let payload = { Id: this.fileId, Attributes: this.lxStatusList };
              this.service.updateDataAttributes(payload).subscribe();
            }
          }
        }
      }
    } catch (e) {
      if (e && e.name == "ParseError") {
        this.errorModal(template);
      }
    }

  }
  private detectCircular(obj: any, seen = new Set()) {
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }

    if (seen.has(obj)) {
      console.log({ obj });
      return true;
    }

    seen.add(obj);

    for (let key in obj) {
      if (obj.hasOwnProperty(key) && this.detectCircular(obj[key], seen)) {
        console.log({ key, value: obj[key] });
        return true;
      }
    }

    return false;
  }
  private removeTreeParent(obj: any) {
    obj.forEach((item: any, index: number) => {
      item.parent = null;
      try {
        this.removeTreeParent(item.children);
      } catch {
      }
    })
  }
  nodeEdit(ev) {
    this.discardTitleEdit();
  }
  emitTitleEdit() {
    this.vxService.eventsSubject.next();
  }
  getNodeSummary(node) {
    let leafSummaryList = [];
    if (node && node.children && node.children.length > 0) {
      node.children.forEach(child => {
        if (child.isLeaf) {
          if (child.summary && child.summary) {
            let summaryObj = { itemId: child.itemId, name: child.name, summary: child.summary };
            leafSummaryList.push(summaryObj);
          }
        }
        else {
          if (child.children && child.children.length > 0) {
            let summList = this.getNodeSummary(child);
            if (summList && summList.length > 0) { leafSummaryList = leafSummaryList.concat(summList); }
          }
        }
      });
    }
    return leafSummaryList;
  }

  getNodeHtml(node) {
    let htmlText = '<h3>' + node.name + '</h3>';
    if (node && node.children && node.children.length > 0) {
      node.children.forEach(child => {
        if (child.isLeaf) {
          if (child.htmlText && child.htmlText.length > 0) {
            htmlText += '<h3>' + child.name + '</h3>' + '<article>' + child.htmlText + '</article>';
          }
        }
        else {
          if (child.children && child.children.length > 0) {
            let childHtmlText = this.getNodeHtml(child);
            if (childHtmlText && childHtmlText.length > 0) { htmlText += childHtmlText; }
          }
        }
      });
    }
    return htmlText;
  }
  setTreeSummary() {
    this.m.treeSummaryList = [];
    this.vxService.tree.nodes.forEach(node => {
      let nodeSummary = { title: node.name, summaryList: [] };
      nodeSummary.summaryList = this.getNodeSummary(node);
      this.m.treeSummaryList.push(nodeSummary);
    });
  }
  showTreeSummary() {
    this.m.selectedNode = this.vxService.tree;
    this.m.selectedNode.name = this.m.selectedNode.title;
    this.setTreeSummary();
    this.m.showTreeSummary = true;
    this.m.showTab = true;
    this.emitTitleEdit();
  }
  back(template) {
    //this.router.navigate([this.backNavPath]);
    this.location.back()
  }
  async canExit() {
    try {
      if (this.canEdit) {
        this.updateHtmlText();
        let isDirty = await this.isDirty();
        if (isDirty) {
          if (confirm("Leave document? \n\n Changes that you made may not be saved. ")) {
            return true;
          } else {
            this.store.dispatch(new appAction.ResetTab({ "reset": true, "ts": +new Date() }));
            return false;
          }
        } else if (this.m.studentVisibilityUpdated || this.m.lxStatusUpdated || this.m.isUrlUpdated) {
          if (confirm("Leave document? \n\n Changes that you made may not be saved.")) {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (e) {
      if (e && e.name == "ParseError") {
        if (confirm("Leave document? \n\n Changes that you made may not be saved.")) {
          return true;
        } else {
          this.store.dispatch(new appAction.ResetTab({ "reset": true, "ts": +new Date() }));
          return false;
        }
      }
    }
  }

  continueAdd(template) {
    this.modalRef.hide();
    this.addTopic(template);
  }
  continueUpdate(template) {
    this.modalRef.hide();
    this.update(template);
  }
  getHtmlText(content) {
    var c = "";
    if (content) {
      c = this.markdownService.compile(content);
      c = this.markdownService.renderKatex(c);
    }
    return c;
  }
  print() {
    if (this.m.selectedNode.name && this.m.selectedNode.htmlText) {
      let html = "";
      var newWindow = this.nativeWindow.open('');
      if (this.m.selectedNode.isLeaf) {
        html = this.m.thStyle + '<div style="padding:1em 2em"><h3>' + this.m.selectedNode.name + '</h3>' + this.m.selectedNode.htmlText + '</div>' + this.m.closingTags;
      }
      else {
        html = this.m.thStyle + '<div style="padding:1em 2em">' + this.m.selectedNode.htmlText + '</div>' + this.m.closingTags;
      }
      newWindow.document.write(html);
    }
  }
  printNode() {
    if (this.m.selectedNode.name && this.m.selectedNode.htmlText) {
      var newWindow = this.nativeWindow.open('');
      let html = this.m.thStyle + '<div style="padding:1em 2em">' + this.m.selectedNode.htmlText + '</div>' + this.m.closingTags;
      newWindow.document.write(html);
    }
  }
  closeAllEditors() {
    if (this.m.selectedNode && this.m.selectedNode.summary) {
      if (this.m.selectedNode.summary.text) { this.m.selectedNode.summary.text = this.m.selectedNode.summary.text.trim(); }
      this.m.selectedNode.summary.preview = true;
    }
    if (this.m.selectedNode && this.m.selectedNode.lxList && this.m.selectedNode.lxList.length > 0) {
      this.m.selectedNode.lxList.forEach(lx => {
        if (lx.content.text) { lx.content.text = lx.content.text.trim(); }
        lx.preview = true;
      });
    }
  }
  updateHtmlText() {
    if (this.vxService.tree.nodes && this.vxService.tree.nodes.length > 0) {
      this.formatData(this.vxService.tree.nodes);
      this.setTreeHtml(this.vxService.tree);
    }
  }
  async sha1(text) {
    const msgUint8 = new TextEncoder().encode(text);
    const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  isDirty = async () => Promise.all([this.sha1(this.vxService.tree.htmlText), this.sha1(this.m.treeCopy.htmlText)]).then((values) => {
    return values[0] != values[1];
  });
  errorModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  copy(item) {
    let cbObj = { title: item.title || item.name, id: +new Date(), type: "data", data: JSON.parse(JSON.stringify(item)) };
    this.clipboardService.copy(cbObj);
  }
  paste(lx) {
    if (this.m.copyItem) {
      let copyItem = JSON.parse(JSON.stringify(this.m.copyItem));
      if (copyItem[0].data.content) {
        lx.title = copyItem[0].data.title;
        lx.content = copyItem[0].data.content;
      }
      else {
        lx.summary = copyItem[0].data.summary;
        lx.summary.preview = false;
      }
    }
  }

  ut_insertAudioVideo(index) {
    this.m.selectedNode.lxList.forEach(q => {
      q.preview = true;
    });
    let ytOb = { id: "", title: "", content: { text: "", htmlText: "", plainText: "", ytURL: "", isYtURL: true }, preview: false, itemId: +new Date() };
    this.m.ytUrlObj[index + 1] = "";
    this.m.selectedNode.lxList.splice(index + 1, 0, ytOb);
  }

  expandCollapse() {
    if (this.m.expand) this.m.expand = false;
    else this.m.expand = true;
  }
  ut_openLMSTools() {
    this.appService.changeSidebarComponent(true, 'lms-tool');
  }
  ut_moveSectionUp(lx, index) {
    if (this.m.selectedNode.lxList.length > 1) {
      if (index > 0) {
        const temp = this.m.selectedNode.lxList[index - 1];
        this.m.selectedNode.lxList[index - 1] = this.m.selectedNode.lxList[index];
        this.m.selectedNode.lxList[index] = temp;
      }
    }
  }
  ut_moveSectionDown(lx, index) {
    if (this.m.selectedNode.lxList.length > 1) {
      if (index < this.m.selectedNode.lxList.length - 1) {
        const temp = this.m.selectedNode.lxList[index + 1];
        this.m.selectedNode.lxList[index + 1] = this.m.selectedNode.lxList[index];
        this.m.selectedNode.lxList[index] = temp;
      }
    }
  }
  ut_expandSection(lx, index) {
    lx.isCollapsed = false;
  }
  ut_collapseSection(lx, index) {
    lx.isCollapsed = true;
  }
  private expandAllSections() {
    this.m.selectedNode.lxList.forEach(lx => {
      lx.isCollapsed = false;
    });
  }
  private collapseAllSections() {
    this.m.selectedNode.lxList.forEach(lx => {
      lx.isCollapsed = true;
    });
  }
  ut_showFile(nodeIndex: number, fileIndex: number) {
    if (this.topic) {
      const node = this.topic.nodes[nodeIndex];
      const file = node.children[fileIndex];
      this.selectedNode(file);
    }
  }
  ut_showFileFor(leaf) {
    const itemId = leaf.itemId;
    if (itemId) {
      const node = this.findNodeById([this.m.selectedNode], itemId);
      if (node) {
        this.selectedNode(node);
      }
    }
  }
  findNodeById(nodes, itemId) {
    for (let node of nodes) {
      if (node.itemId == itemId) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const found = this.findNodeById(node.children, itemId);
        if (found) {
          return found;
        }
      }
    }
  }
}