import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
    SET_FEES_DEFAULTERS: type('[Cards] Set Fee Defaulters'),
    RESET_CARDS_DATA: type('[Cards] Reset Cards Data'),
    SET_UPCOMING_EVENTS: type('[Cards] Set Upcoming Events'),
    UPSERT_UPCOMING_EVENTS: type('[Cards] Upsert Upcoming Events'),
    SET_QUICK_LINKS: type('[Cards] Set Quick Links'),
    SET_FEES_PAYABLE: type('[Cards] Set Fees Payable')
};

export class SetFeeDefaulters implements Action {
    type = ActionTypes.SET_FEES_DEFAULTERS;
    constructor(public payload: any) { }
}

export class SetUpcomingEvents implements Action {
    type = ActionTypes.SET_UPCOMING_EVENTS;
    constructor(public payload: any) { }
}

export class ResetCardsData implements Action {
    type = ActionTypes.RESET_CARDS_DATA;
    constructor(public payload: any) { }
}

export class UpsertUpcomingEvents implements Action {
    type = ActionTypes.UPSERT_UPCOMING_EVENTS;
    constructor(public payload: any) { }
}

export class SetQuickLinks implements Action {
    type = ActionTypes.SET_QUICK_LINKS;
    constructor(public payload: any) { }
}
export class SetFeesPayable implements Action {
    type = ActionTypes.SET_FEES_PAYABLE;
    constructor(public payload: any) { }
}

export type Actions
    = SetFeeDefaulters | ResetCardsData | SetUpcomingEvents | UpsertUpcomingEvents | SetQuickLinks | SetFeesPayable;
