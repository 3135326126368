import { Injectable, Output, EventEmitter } from '@angular/core';
import { PulseService } from '../../../app/service/pulse';
import { DEX_FILTERING_INFO, DEX_FILTER_OPTIONS, EMPTY_DEX_QUERY } from './constant';
import { AppService } from '../../../app/service/app.service';

@Injectable({
  providedIn: 'root'
})
export class DexService {
  readonly dexFilteringInfo: string = DEX_FILTERING_INFO;
  jsonQuery = EMPTY_DEX_QUERY;
  isEditMode: boolean = false;
  selectedQueryData: any = {};
  tableHeaders: Array<string> = [];
  tableRows: Array<any> = [];
  showRowNumber = true;
  dexErrorMessage: string = "";

  constructor(private pulseService: PulseService, private appService: AppService) { }

  getDexData() {
    this.pulseService.requestDexData(this.jsonQuery);
  }
  saveQuery(queryTitle, queryDescription) {
    this.selectedQueryData.title = queryTitle;
    this.selectedQueryData.description = queryDescription;
    this.selectedQueryData.query = this.jsonQuery;
    this.pulseService.saveQuery(this.selectedQueryData);
  }
  getQueries() {
    this.pulseService.getQueries();
  }
  updateQuery() {
    this.pulseService.updateQuery(this.selectedQueryData);
  }
  deleteQuery() {
    this.pulseService.deleteQuery(this.selectedQueryData.id);
  }
  resetQuery() {
    this.isEditMode = false;
    this.jsonQuery = EMPTY_DEX_QUERY;
    this.appService.showDexResults = false;
    this.getQueries();
  }
  downloadCSV() {
    if (this.tableRows.length > 0) {
      const headerString = this.tableHeaders.join(",");
      const rowString = this.tableRows.map((row, index) => (index + 1) + "," + row.join(",")).join("\n");
      const csv = headerString + "\n" + rowString;
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const date = new Date();
      const dateStr = date.toISOString().slice(0, 10).replace(/-/g, '');
      a.download = this.appService.sidebarComponentInfo.context + '-' + dateStr + '.csv';
      a.click();
    }
  }

  // Helper function
  /**
   * This function is used to generate input fields for query expression
   * @param qes Query Expression Array
   * @returns Array of input fields
   */
  queryExpressionUiBuilder(qes: Array<any>) {
    qes = qes || [];
    const inputFields = [];
    qes.forEach((qe: any) => {
      if (qe && qe instanceof Object) {
        for (const key in qe) {
          if (Object.prototype.hasOwnProperty.call(qe, key)) {
            const props = qe[key];
            const field = this.inputFieldJsonBuilder(key, props);
            if (field) {
              inputFields.push(field);
            }
          }
        }
      }
    });
    return inputFields;
  }
  private inputFieldJsonBuilder(queryType: string, props: any) {
    switch (queryType) {
      case "where":
        const filterValue = props.conditions.map((item: any) => item.column + item.rel + item.value).join(props.operator);
        if (filterValue) {
          const filter = { name: "Filter", value: filterValue, options: DEX_FILTER_OPTIONS }
          return filter;
        } else {
          return null;
        }
        break;

      case "group_by":
        const groupValue = props.map((item: any) => item.column).join(", ");
        if (groupValue) {
          const group = { name: "GroupBy", value: groupValue, options: DEX_FILTER_OPTIONS }
          return group;
        } else {
          return null;
        }
        break;
      case "group":
        const by = props.by;
        const aggregate = props.aggregate;
        const groups = by.concat(aggregate)
        const groupsStr = groups.join(", ");
        if (groups) {
          const group = { name: "Group", value: groupsStr, options: DEX_FILTER_OPTIONS }
          return group;
        } else {
          return null;
        }
        break;

      case "order_by":
        const sortValue = props.map((item: any) => item.column + ':' + item.order).join(", ");
        if (sortValue) {
          const sort = { name: "Sort", value: sortValue, options: DEX_FILTER_OPTIONS }
          return sort;
        } else {
          return null;
        }
        break;
      case "select":
        const SelectValue = props.join(", ");
        if (SelectValue) {
          const Select = { name: "Select", value: SelectValue, options: DEX_FILTER_OPTIONS }
          return Select;
        } else {
          return null;
        }
        break;
      case "limit":
        return { name: "Limit", value: (props || 20) + "", options: DEX_FILTER_OPTIONS };
        break;
      case "skip":
        return { name: "Skip", value: (props || 0) + "", options: DEX_FILTER_OPTIONS };
        break;
      default:
        break;
    }
  }
  /**
   * This function is used to generate query expression from input fields
   * @param inputFields Input fields array
   * @returns Query Expression Array
   */
  queryExpressionBuilder(inputFields: Array<any>) {
    const queryExpressions = [];
    inputFields.forEach((item) => {
      item.value = item.value.replace(/\s+/g, ' ').trim();
      const queryExpression = this.getQueryExpression(item);
      if (queryExpression) {
        queryExpressions.push(queryExpression);
      }
    });
    return queryExpressions;
  }
  private getQueryExpression(item: any) {
    switch (item.name) {
      case "Filter":
        item.value = item.value.replace(/\band\b/gi, 'AND').replace(/\bor\b/gi, 'OR');
        const operators = [" AND ", " OR "];
        const operator = operators.find(op => item.value.includes(op)) || " AND ";
        const where = {
          operator: operator.trim(),
          conditions: item.value.split(operator).map((s: string) => {
            // :: is CONTAINS operator, e.g. X::Y means is X string CONTAINS Y string
            let [column, rel, value]: any = s.split(/(==|=|!=|>|<|>=|<=|::)/).map((s: string) => s.trim());
            if (rel === "=") {
              rel = "=="
            }
            value = this.validateValue(value);
            return { column, rel, value }
          }).filter((o: any) => o.column && o.rel)
        }
        return { where };
        break;
      case "GroupBy":
        const group_by = item.value.split(",").map((s: string) => s.trim()).filter((s: string) => s);
        return { group_by };
        break;
      case "Group":
        const all = item.value.split(",").map((s: string) => s.trim()).filter((s: string) => s);
        const by = all.filter((s: string) => !s.includes("("));
        const aggregate = all.filter((s: string) => s.includes("("));
        return { group: { by, aggregate } };
        break;
      case "Sort":
        const order_by = item.value.split(",").map((s: string) => {
          const [column, order] = s.trim().split(":");
          return { column, order: order || "asc" }
        }).filter((o: any) => o.column && o.order);
        return { order_by };
        break;
      case "Select":
        item.value = item.value.replace(/\bas\b/gi, 'AS');
        const select = item.value.split(",").map((s: string) => s.trim()).filter((s: string) => s);
        if (select.length === 0) {
          select.push("*");
        }
        return { select };
        break;
      case "Limit":
        return { limit: Number(item.value) || 20 };
        break;
      case "Skip":
        return { skip: Number(item.value) || 0 };
        break;
      default:
        return null;
        break;
    }
  }
  private validateValue(value: any) {
    if (value === "") {
      return "";
    }
    let num = Number(value);
    value = Number.isNaN(num) ? value : num;
    return value !== "" && value !== undefined && value !== null ? value : ""
  }
}
