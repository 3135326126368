import { JsonObject } from '../../service/app.interfaces';
import * as app from './action';

export interface State {
    feeDefaultersData: any;
    upcomingEventsData: any;
    upcomingEvents: JsonObject;
    quickLinksData: any;
    feesPayable: { card_name: 'Fees Payable', list: Array<any> };
}

const initialState: State = {
    feeDefaultersData: null,
    upcomingEventsData: null,
    upcomingEvents: {},
    quickLinksData: null,
    feesPayable: { card_name: 'Fees Payable', list: [] }
};

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_FEES_DEFAULTERS:
            return Object.assign({}, state, { feeDefaultersData: action.payload.feeDefaulters });
        case app.ActionTypes.SET_UPCOMING_EVENTS:
            return Object.assign({}, state, { upcomingEventsData: action.payload.upcomingEvents });
        case app.ActionTypes.RESET_CARDS_DATA:
            return Object.assign({}, initialState);
        case app.ActionTypes.UPSERT_UPCOMING_EVENTS:
            return Object.assign({}, state, { upcomingEvents: upsertUpcomingEvents(state.upcomingEvents, action.payload) });
        case app.ActionTypes.SET_QUICK_LINKS:
            return Object.assign({}, state, { quickLinksData: action.payload.quickLinks });
        case app.ActionTypes.SET_FEES_PAYABLE:
            return Object.assign({}, state, { feesPayable: { card_name: 'Fees Payable', list: action.payload } });
        default:
            return state;
    }
}

function upsertUpcomingEvents(ueState: JsonObject, ueNew: JsonObject) {
    return Object.assign({}, ueState, ueNew);
}

export const get_fee_defaulters_data = (state: State) => state.feeDefaultersData;
export const get_upcoming_events_data = (state: State) => state.upcomingEventsData;
export const get_upcoming_events = (state: State) => state.upcomingEvents;
export const get_quick_links_data = (state: State) => state.quickLinksData;
export const get_fees_payable = (state: State) => state.feesPayable;