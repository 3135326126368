import { Component, OnInit } from '@angular/core';
import { AppService } from '../../service/app.service';
import { VxService } from '../vx/vx.service';

@Component({
  selector: 'app-lms-tool',
  templateUrl: './lms-tool.component.html',
  styleUrls: ['./lms-tool.component.css']
})
export class LmsToolComponent implements OnInit {

  constructor(public appService: AppService, public vxService: VxService) { }

  ngOnInit() {
  }

}
