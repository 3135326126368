import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    DISABLE_STUDENT: type('[Student] Disable Student'),
    ENABLE_STUDENT: type('[Student] Enable Student'),
    TC_STUDENT: type('[Student] Tc Student'),
    ENROL_STUDENT: type('[Student] Add Student'),
    SET_SELECTED_STUDENT: type('[Student] Set Selected Student'),
    SET_STUDENTS: type('[Student] Set Students'),
    UPDATE_HOME_PHONE: type('[Student] Update Home Phone '),
    UPDATE_STUDENT_ADDRESS: type('[Student] Update Student Address'),
    UPDATE_STUDENT_DETAILS: type('[Student] Update Student Details'),
    SET_COURSE_ID: type('[Student] Set Course Id'),
    SET_COURSES: type('[Student] Set Courses'),
    SET_FILTERS: type('[Student] Set Filters'),
    SET_SEARCH_TERM: type('[Student] Set Search Term'),
    SET_QUERY_STRING: type('[Student] Set Qurey String'),
    UPDATE_DOMICILE: type('[Student] Update Domicile'),
    UPDATE_DOMICILE_SUCCESS: type('[Student] Update Domicile Success'),
    SET_STUDENT_ESSENTIALS: type('[Student] Set Student Essentials'),
    SET_DOCUMENT_URL: type('[Student] Set Document Url')
};

//Domicile
export class UpdateDomicile implements Action{
    type = ActionTypes.UPDATE_DOMICILE;
    constructor(public payload: any){}
}
export class UpdateDomicileSuccess implements Action{
    type = ActionTypes.UPDATE_DOMICILE_SUCCESS;
    constructor(public payload: any){}
}
export class DisableStudent implements Action {
    type = ActionTypes.DISABLE_STUDENT;
    /**"Id": ""*/
    constructor(public payload: any) { }
}

export class EnableStudent implements Action {
    type = ActionTypes.ENABLE_STUDENT;
    /**"Id": ""*/
    constructor(public payload: any) { }
}

export class TCStudent implements Action {
    type = ActionTypes.TC_STUDENT;
    /**"Id": ""*/
    constructor(public payload: any) { }
}

export class EnrolStudent implements Action {
    type = ActionTypes.ENROL_STUDENT;
    /**"Name": "", "Gender":"", "DOA":"", "Course":"", "StudentId":"", "AdmissionNo":"", "ApplicationNo": "", "DOA": "", "Email": "", "Mobile": "" */
    constructor(public payload: any) { }
}

export class SetSelectedStudent implements Action {
    type = ActionTypes.SET_SELECTED_STUDENT;
    constructor(public payload: any) { }
}

export class SetStudents implements Action {
    type = ActionTypes.SET_STUDENTS;
    constructor(public payload: any) { }
}

export class UpdateHomePhone implements Action {
    type = ActionTypes.UPDATE_HOME_PHONE;
    /**"Id": "", "HomePhone": "" */
    constructor(public payload: any) { }
}

export class UpdateStudentAddress implements Action {
    type = ActionTypes.UPDATE_STUDENT_ADDRESS;
    /**"Id": "", "AddressLine1": "", "AddressLine2": "", "City": "", "State": "", "PinCode": "", "Country": "" */
    constructor(public payload: any) { }
}

export class UpdateStudentDetails implements Action {
    type = ActionTypes.UPDATE_STUDENT_DETAILS;
    /**"Id": "", "Name": "", "DOB": "", "Gender": "", "StudentId": "", "DOA": "", "AdmissionNo": "", "ApplicationNo": "" */
    constructor(public payload: any) { }
}

export class SetCourseId implements Action {
    type = ActionTypes.SET_COURSE_ID;
    constructor(public payload: any) { }
}

export class SetCourses implements Action {
    type = ActionTypes.SET_COURSES;
    constructor(public payload: any) {
    }
}

export class SetFilters implements Action {
    type = ActionTypes.SET_FILTERS;
    constructor(public payload: any) {
    }
}

export class SetSearchTerm implements Action {
    type = ActionTypes.SET_SEARCH_TERM;
    constructor(public payload: any) {
    }
}

export class SetQueryString implements Action {
    type = ActionTypes.SET_QUERY_STRING;
    constructor(public payload: any) {
    }
}

export class SetEssentials implements Action {
    type = ActionTypes.SET_STUDENT_ESSENTIALS;
    constructor(public payload: any) {
    }
}

export class SetDocumentUrl implements Action {
    type = ActionTypes.SET_DOCUMENT_URL;
    constructor(public payload: any) {}
}

export type Actions
    = UpdateDomicile | UpdateDomicileSuccess |  DisableStudent | EnableStudent | EnrolStudent | SetSelectedStudent | SetStudents | UpdateHomePhone | UpdateStudentAddress | UpdateStudentDetails | SetCourseId | SetCourses | SetFilters | TCStudent | SetSearchTerm | SetQueryString | SetEssentials | SetDocumentUrl;