import { Component, HostListener, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { VxService } from '../vx/vx.service';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-table-of-content',
  templateUrl: './table-of-content.component.html',
  styleUrls: ['./table-of-content.component.css']
})
export class TableOfContentComponent implements OnInit, OnChanges, OnDestroy {
  /*
  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.target instanceof Element && ((event.target as Element).tagName).toLowerCase() == "textarea") {
      return;
    }
    if (event.key == "ArrowUp") {
      event.stopPropagation();
      this.vxService.selectPreviousNode();
    } else if (event.key == "ArrowDown") {
      event.stopPropagation();
      this.vxService.selectNextNode();
    } else if (event.key == "ArrowLeft") {
      this.vxService.collapseCurrentNode();
    } else if (event.key == "ArrowRight") {
      this.vxService.expandCurrentNode();
    }
  }
  */
  tree: TreeNode[] = [
    {
      "label": "Documents",
      "data": "Documents Folder",
      "expandedIcon": "pi pi-folder-open",
      "collapsedIcon": "pi pi-folder",
      "children": [{
        "label": "Work",
        "data": "Work Folder",
        "expandedIcon": "pi pi-folder-open",
        "collapsedIcon": "pi pi-folder",
        "children": [{ "label": "Expenses.doc", "icon": "pi pi-file", "data": "Expenses Document" }, { "label": "Resume.doc", "icon": "pi pi-file", "data": "Resume Document" }]
      },
      {
        "label": "Home",
        "data": "Home Folder",
        "expandedIcon": "pi pi-folder-open",
        "collapsedIcon": "pi pi-folder",
        "children": [{ "label": "Invoices.txt", "icon": "pi pi-file", "data": "Invoices for this month" }]
      }]
    },
    {
      "label": "Pictures",
      "data": "Pictures Folder",
      "expandedIcon": "pi pi-folder-open",
      "collapsedIcon": "pi pi-folder",
      "children": [
        { "label": "barcelona.jpg", "icon": "pi pi-image", "data": "Barcelona Photo" },
        { "label": "logo.jpg", "icon": "pi pi-file", "data": "PrimeFaces Logo" },
        { "label": "primeui.png", "icon": "pi pi-image", "data": "PrimeUI Logo" }]
    },
    {
      "label": "Movies",
      "data": "Movies Folder",
      "expandedIcon": "pi pi-folder-open",
      "collapsedIcon": "pi pi-folder",
      "children": [{
        "label": "Al Pacino",
        "data": "Pacino Movies",
        "children": [{ "label": "Scarface", "icon": "pi pi-video", "data": "Scarface Movie" }, { "label": "Serpico", "icon": "pi pi-file-video", "data": "Serpico Movie" }]
      },
      {
        "label": "Robert De Niro",
        "data": "De Niro Movies",
        "children": [{ "label": "Goodfellas", "icon": "pi pi-video", "data": "Goodfellas Movie" }, { "label": "Untouchables", "icon": "pi pi-video", "data": "Untouchables Movie" }]
      }]
    }
  ]
  selectedFile: TreeNode[] = [];
  itemNameCopy: string = "";

  constructor(public vxService: VxService) { }

  ngOnInit() {
    this.vxService.closeAllItemNameEditors();
  }
  ngOnChanges(changes: SimpleChanges): void {
  }
  ngOnDestroy(): void {
  }
  onNodeSelect(event) {
    if (this.selectedFile['type'] != 'rename') {
      this.vxService.ut_selectedNode(this.selectedFile);
    }
  }
  ut_editItemName(node, e) {
    // prevent the click event from bubbling up to the parent
    if (e) {
      e.stopPropagation();
    }
    if (this.vxService.canEdit) {
      this.itemNameCopy = node.label;
      this.vxService.closeAllItemNameEditors();
      if (node) {
        node.type = "rename"
      }
      // this.itemEdit.emit(item);
    }
  }
  ut_finishEditItemName(node) {
    if (this.itemNameCopy) {
      node.name = this.itemNameCopy;
      node.label = this.itemNameCopy;
      this.vxService.renameNodeById(node.itemId, this.itemNameCopy);
      this.itemNameCopy = "";
      node.edit = false;
      node.type = "";
    }
  }
  ut_undoEditItemName(node) {
    if (node) {
      this.itemNameCopy = "";
      node.edit = false;
      node.type = "";
    }
  }
  ut_addLeaf(node, e) {
    if (e) {
      e.stopPropagation();
    }
    const leaf = { itemId: +new Date(), ngxDraggableName: "['node']", name: "New Leaf", expand: false, children: [], isLeaf: true, summary: { text: "", preview: false, htmlText: "", plainText: "" }, lxList: [{ id: "", title: "", content: { text: "", htmlText: "", plainText: "" }, preview: true, itemId: +new Date() }] };
    if (node.children) {
      node.children.push(leaf);
    } else {
      node.children = [leaf];
    }
    node.expanded = true;
    this.vxService.reInitTreeData();
    setTimeout(() => {
      this.ut_editItemName(leaf, null);
    }, 500);
  }

  ut_addNode(node, e) {
    if (e) {
      e.stopPropagation();
    }
    const childNode = { name: "New Node", expand: false, children: [], isLeaf: false, itemId: +new Date(), ngxDraggableName: "['node','tree']" };
    if (node.children) {
      node.children.push(childNode);
    } else {
      node.children = [childNode];
    }
    node.expanded = true;
    this.vxService.reInitTreeData();
    setTimeout(() => {
      this.ut_editItemName(childNode, null);
    }, 500);
  }
  ut_addPeerNode(node, e) {
    if (e) {
      e.stopPropagation();
    }
    const childNode = { name: "New Node", expand: false, children: [], isLeaf: false, itemId: +new Date(), ngxDraggableName: "['node','tree']" };
    if (this.vxService.tree.nodes) {
      this.vxService.tree.nodes.push(childNode);
    }
    this.vxService.reInitTreeData();
    setTimeout(() => {
      this.ut_editItemName(childNode, null);
    }, 500);
  }
  ut_removeItem(node) {
    const itemId = node.itemId;
    this.deleteNodeByitemId(this.vxService.tree.nodes, itemId)
  }
  private deleteNodeByitemId(nodes, itemId) {
    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index];
      if (node.itemId == itemId) {
        nodes.splice(index, 1);
        return;
      } else if (node.children && node.children.length > 0) {
        this.deleteNodeByitemId(node.children, itemId);
      }
    }
  }
}
