import { Component, OnInit } from '@angular/core';
import { AppService } from '../../service/app.service';
import { DexService } from '../services/dex.service';

@Component({
  selector: 'app-dex-page',
  templateUrl: './dex-page.component.html',
  styleUrls: ['./dex-page.component.css']
})
export class DexPageComponent implements OnInit {

  constructor(public appService: AppService, public dexService: DexService) { }

  ngOnInit() {
  }

}
