import { Action } from '@ngrx/store';
import { type } from '../util';
import { AIChat, AppStatus, JsonObject } from '../service/app.interfaces';

export const ActionTypes = {
  SET_TITLE: type('[App] Set Title'),
  LOGIN_SUCCESS: type('[App] Login Success'),
  SET_ACCESS_CODE: type('[App] Set Access Code'),
  SET_ACCESS_TOKEN: type('[App] Set Access Token'),
  INIT: type('[App] Init'),
  REC_ACT: type('[App] Recent Activity'),
  SET_NOTIFICATION: type('[App] Set Notification'),
  LOGOUT: type('[App] Logout'),
  SET_USER_LINKS: type('[App] Set User Links'),
  START_REQ: type('[App] Start Req'),
  SET_TAB: type('[App] Set Tab'),
  PULSE: type('[App] Set Response from Pulse'),
  SET_USER_UPDATED_DATA: type('[App] Set User Updated data'),
  USER_CHANNEL_JOINED: type('[App] Set User Channel Join'),
  LOAD_STATES: type('[App] Load States'),
  LOAD_STATES_SUCCESS: type('[App] Load States Success'),
  SET_OTP_TIMER: type('[App] Set OTP Timer'),
  SET_TOPICS: type('[App] Set Topics List'),
  SET_MAIN_TOPICS: type('[App] Set Main Topics List'),
  DISABLE_TOPIC: type('[App] Disable Topic'),
  ENABLE_TOPIC: type('[App] Enable Topic'),
  RESET_TAB: type('[App] Reset Tab'),
  COPY_ITEM: type('[App] Copy Item'),
  REMOVE: type('[App] Remove Item'),
  SELECT: type('[App] Select Item'),
  UNSELECT: type('[App] Unselect Item'),
  SET_NB_DISPLAY: type('[App] Set NB Display'),
  SET_SELECTED_TOPIC: type('[App] Set Selected Topic'),
  APPEND_AI_CHAT: type('[App] Append AI Chat'),
  RESET_AI_CHAT: type('[App] Reset AI Chat'),
  SET_COMMANDS: type('[App] Set Commands'),
  SET_CHAT_OPTIONS: type('[App] Set Chat Options'),
  SET_ACK: type('[App] Set Ack'),
  SET_SIGNIN_PAGE: type('[App] Set SignIn Page'),
  RESET_PASSWORD_EMAIL_SENT: type('[App] Reset Password Email Sent'),
  UPDATE_APP_STATUS: type('[App] Update App Status'),
  SET_TOPICS_STATS: type('[App] Set Topics Stats'),
  SET_TOPICS_STATS_MAPED: type('[App] Set Topics Stats Maped'),
  SET_SCHEDULED_PUBLISHES: type('[App] Set Scheduled Publishes'),
  SET_IS_SIDEBAR_OPEN: type('[App] Set Is Sidebar Open'),
  SET_SIDEBAR_COMPONENT_INFO: type('[App] Set Sidebar Component Info'),
  SET_ALL_FORMULAS: type('[App] Set All Formulas')
};

export class AppendAIChat implements Action {
  type = ActionTypes.APPEND_AI_CHAT;
  constructor(public payload: AIChat) { }
}
export class ResetAIChat implements Action {
  type = ActionTypes.RESET_AI_CHAT;
  constructor(public payload: any) { }
}
export class SetCommands implements Action {
  type = ActionTypes.SET_COMMANDS;
  constructor(public payload: any) { }
}

export class SetChatOptions implements Action {
  type = ActionTypes.SET_CHAT_OPTIONS;
  constructor(public payload: any) { }
}

export class SetAck implements Action {
  type = ActionTypes.SET_ACK;
  constructor(public payload: any) { }
}

export class LoadStates implements Action {
  type = ActionTypes.LOAD_STATES;
  constructor(public payload: any) { }
}
//Domicile
export class LoadStatesSuccess implements Action {
  type = ActionTypes.LOAD_STATES_SUCCESS;
  constructor(public payload: any) { }
}
export class SetTitle implements Action {
  type = ActionTypes.SET_TITLE;
  constructor(public payload: any = null) { }
}

export class LoginSuccess implements Action {
  type = ActionTypes.LOGIN_SUCCESS;
  constructor(public payload: any) { }
}

export class SetAccessCode implements Action {
  type = ActionTypes.SET_ACCESS_CODE;
  constructor(public payload: any = null) { }
}

export class SetAccessToken implements Action {
  type = ActionTypes.SET_ACCESS_TOKEN;
  constructor(public payload: any) { }
}

export class Init implements Action {
  type = ActionTypes.INIT;
  constructor(public payload: any = null) { }
}

export class RecentActivity implements Action {
  type = ActionTypes.REC_ACT;
  constructor(public payload: any) { }
}

export class SetNotification implements Action {
  type = ActionTypes.SET_NOTIFICATION;
  constructor(public payload: any) { }
}

export class Logout implements Action {
  type = ActionTypes.LOGOUT;
  constructor(public payload: any) { }
}

export class SetUserLinks implements Action {
  type = ActionTypes.SET_USER_LINKS;
  constructor(public payload: any) { }
}

export class StartReq implements Action {
  type = ActionTypes.START_REQ;
  constructor(public payload: any) { }
}

export class SetTab implements Action {
  type = ActionTypes.SET_TAB;
  constructor(public payload: any = null) { }
}

export class Pulse implements Action {
  type = ActionTypes.PULSE;
  constructor(public payload: any = null) { }
}

export class SetUserUpdatedData implements Action {
  type = ActionTypes.SET_USER_UPDATED_DATA;
  constructor(public payload: any = null) { }
}

export class UserChannelJoined implements Action {
  type = ActionTypes.USER_CHANNEL_JOINED;
  constructor(public payload: { userChannelJoined: boolean }) { }
}

export class SetOTPTimer implements Action {
  type = ActionTypes.SET_OTP_TIMER;
  constructor(public payload: any = null) { }
}

export class SetTopics implements Action {
  type = ActionTypes.SET_TOPICS;
  constructor(public payload: any = null) { }
}

export class SetMainTopics implements Action {
  type = ActionTypes.SET_MAIN_TOPICS;
  constructor(public payload: any = null) { }
}

export class DisableTopic implements Action {
  type = ActionTypes.DISABLE_TOPIC;
  constructor(public payload: any) { }
}

export class EnableTopic implements Action {
  type = ActionTypes.ENABLE_TOPIC;
  constructor(public payload: any) { }
}

export class ResetTab implements Action {
  type = ActionTypes.RESET_TAB;
  constructor(public payload: any = null) { }
}

export class CopyItem implements Action {
  type = ActionTypes.COPY_ITEM;
  constructor(public payload: any) { }
}

export class Remove implements Action {
  type = ActionTypes.REMOVE;
  constructor(public payload: any) { }
}

export class Select implements Action {
  type = ActionTypes.SELECT;
  constructor(public payload: any) { }
}

export class Unselect implements Action {
  type = ActionTypes.UNSELECT;
  constructor(public payload: any) { }
}

export class SetNBDisplay implements Action {
  type = ActionTypes.SET_NB_DISPLAY;
  constructor(public payload: any) { }
}

export class SetSelectedTopic implements Action {
  type = ActionTypes.SET_SELECTED_TOPIC;
  constructor(public payload: any = null) { }
}

export class SetSignInPage implements Action {
  type = ActionTypes.SET_SIGNIN_PAGE;
  constructor(public payload: any = null) { }
}

export class ResetPasswordEmailSent implements Action {
  type = ActionTypes.RESET_PASSWORD_EMAIL_SENT;
  constructor(public payload: any = null) { }
}

export class UpdateAppStatus implements Action {
  type = ActionTypes.UPDATE_APP_STATUS;
  constructor(public payload: AppStatus) { }
}
export class SetTopicsStats implements Action {
  type = ActionTypes.SET_TOPICS_STATS;
  constructor(public payload: any) { }
}
export class SetTopicsStatsMaped implements Action {
  type = ActionTypes.SET_TOPICS_STATS_MAPED;
  constructor(public payload: any) { }
}
export class SetScheduledPublishes implements Action {
  type = ActionTypes.SET_SCHEDULED_PUBLISHES;
  constructor(public payload: unknown) { }
}
export class SetIsSidebarOpen implements Action {
  type = ActionTypes.SET_IS_SIDEBAR_OPEN;
  constructor(public payload: boolean) { }
}
export class SetSidebarComponentInfo implements Action {
  type = ActionTypes.SET_SIDEBAR_COMPONENT_INFO;
  constructor(public payload: JsonObject) { }
}
export class SetAllFormulas implements Action {
  type = ActionTypes.SET_ALL_FORMULAS;
  constructor(public payload: any) { }
}

export type Actions
  = LoadStates | LoadStatesSuccess | SetTitle | LoginSuccess | SetAccessCode | SetAccessToken | Init | RecentActivity
  | SetNotification | Logout | SetUserLinks | StartReq | SetTab | Pulse | SetUserUpdatedData | UserChannelJoined
  | SetOTPTimer | SetTopics | DisableTopic | EnableTopic | ResetTab | CopyItem | Remove | Select | Unselect
  | SetNBDisplay | SetSelectedTopic | AppendAIChat | ResetAIChat | SetCommands | SetChatOptions | SetAck | SetSignInPage
  | ResetPasswordEmailSent | UpdateAppStatus | SetTopicsStats | SetTopicsStatsMaped | SetMainTopics | SetScheduledPublishes
  | SetIsSidebarOpen | SetSidebarComponentInfo | SetAllFormulas;
