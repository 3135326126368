import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';
import { IntroJsService } from '../../service/intro-js.service';
import { INTRO_IDS } from "../../service/constants";
import { KatexOptions } from 'ngx-markdown';
import { Router } from "@angular/router";

class Model { }

@Component({
    selector: 'card',
    templateUrl: './component.html',
    styleUrls: ['./component.css']
})
export class CardComponent {
    public options: KatexOptions = {
        displayMode: false,
        throwOnError: false,
        errorColor: '#cc0000',
    };
    public m: Model;
    @Input() data: any;
    @Output() dropdownValue = new EventEmitter();
    

    constructor(private store: Store<rootReducer.State>, private router: Router, private introJsService: IntroJsService) { }

    ngOnInit() {
        this.init_model();
        //this.init_store();
    }
    init_model() {
        this.m = new Model();
    }

    init_store() {
    }
    callFn(name) {
        switch (name) {
            case 'help':
                return this.ut_startGuidedTour();
            case 'fee_defaulters':
                return this.ut_routerNavigate();
            case 'institute_setup':
                return this.ut_startInstituteSetup();

            default:
                return;
        }
    }
    ut_startGuidedTour() {
        this.introJsService.startIntroById(INTRO_IDS.HEADER);
    }

    ut_routerNavigate() {
        this.router.navigate([this.data.links.explore]);
    }

    ut_setValue(name, value) {
        this.dropdownValue.emit({ name: name, value: value });
    }

    ut_startInstituteSetup() {
        this.router.navigate(['/admin/institute/setup'])
    }
}