import * as home from './action';

export interface State {
    dashboardWigets: string[];
    insts: any[];
    dashboard: [];
    // dashboard: { [s: string]: { [s: string]: any } };
    dexData: {
        data: any[];
        total: number;
        skip: number;
        limit: number;
        reason?: { message?: string; }
    };
    dexQueries: any[];
}

const initialState: State = {
    dashboardWigets: [],
    insts: [],
    dashboard: [],
    dexData: {
        data: [],
        total: 0,
        skip: 0,
        limit: 20
    },
    dexQueries: []
};

export function reducer(state = initialState, action: home.Actions): State {
    switch (action.type) {
        case home.ActionTypes.SET_INSTITUTES:
            return Object.assign({}, state, { insts: action.payload.insts });
        case home.ActionTypes.SET_DASHBOARD_WIDGETS:
            return Object.assign({}, state, { dashboardWigets: action.payload.dashboardWigets });
        case home.ActionTypes.APPEND_WIDGET_TO_DASHBOARD:
            return Object.assign({}, state, { dashboard: updateDashboard(state.dashboard, action.payload.dashboard) });
        case home.ActionTypes.UPDATE_DASHBOARD:
            return Object.assign({}, state, { dashboard: action.payload });
        case home.ActionTypes.UPDATE_DEX_DATA:
            return Object.assign({}, state, { dexData: action.payload });
        case home.ActionTypes.UPDATE_DEX_QUERIES:
            return Object.assign({}, state, { dexQueries: action.payload });
        default:
            return state;
    }
}
function updateDashboard(dashboard, data) {
    dashboard[data.widget_name] = data;
    return dashboard;
}
export const get_institutes = (state: State) => state;
export const get_dashboard_widgets = (state: State) => state.dashboardWigets;
export const get_dashboard = (state: State) => state.dashboard;
export const get_dex_data = (state: State) => state.dexData;
export const get_dex_queries = (state: State) => state.dexQueries;